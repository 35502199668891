import { Theme } from '@emotion/react';
import defaultTheme from './default';

export interface Themes {
  [key: string]: Theme;
}

const themes: Themes = {
  default: defaultTheme,
};

export default themes;
