import { useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { BookingResource } from '@common/types/apiResource';
import React from 'react';
import Page from '@admin/components/layout/Page';
import Card from '@admin/components/Card';
import { useServiceContext } from '@admin/context/ServiceContext';
import ViewBooking from './components/ViewBooking';

const BookingPage = () => {
  const { bookingId } = useParams<{ bookingId: string }>();

  const { bookingService } = useServiceContext();

  const {
    value: booking,
    isLoading,
    error,
  } = useAsyncRetry<BookingResource>(async () => {
    const result = await bookingService.get(bookingId, {
      query: {
        $eager: '[bookedSlots,account,bookedSlots.hub,bookedSlots.hub.site]',
      },
    });
    return result as BookingResource;
  });

  return (
    <Page title="Booking">
      <Card>
        {booking && (
          <>
            <ViewBooking booking={booking} />
          </>
        )}
      </Card>
    </Page>
  );
};

export default BookingPage;
