import Page from '@admin/components/layout/Page';
import AccountList from '@admin/pages/admin/accounts/components/AccountList';
import React from 'react';
import Card from '@admin/components/Card';

const AccountsPage = () => (
  <Page title="Account List">
    <Card>
      <AccountList />
    </Card>
  </Page>
);

export default AccountsPage;
