import Page from '@admin/components/layout/Page';
import React from 'react';
import Hero from '@admin/components/Hero';
import Container from '@admin/components/layout/Container';
import ArticleList from './components/articleList';

const HelpPage = () => (
  <Page title="FAQs 🙋" fullWidth>
    <Hero title="FAQs 🙋" subtitle="Help and Support" />
    <Container>
      <ArticleList />
    </Container>
  </Page>
);
export default HelpPage;
