import React from 'react';
import Page from '@admin/components/layout/Page';
import { useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { AccountResource, BookingResource } from '@common/types/apiResource';
import { Paginated } from '@feathersjs/feathers';
import usePaginationQuery from '@admin/hooks/usePaginationQuery';
import EditableBookingsList from '@admin/components/bookings/EditableBookingList';
import Card from '@admin/components/Card';
import { useServiceContext } from '@admin/context/ServiceContext';

interface Params {
  accountId: string;
}

const BookingsPage = () => {
  const params = useParams<Params>();
  const { accountId } = params;
  const { $limit, $skip } = usePaginationQuery({ limit: 5 });

  const { accountService, bookingService } = useServiceContext();

  const accountResource = useAsyncRetry<AccountResource | undefined>(async () => {
    const account = await accountService.get(accountId);

    return account;
  });

  const bookingsResource = useAsyncRetry<Paginated<BookingResource> | undefined>(async () => {
    const result = await bookingService.find({
      query: {
        accountId,
        $eager: 'bookedSlots',
        $limit,
        $skip,
      },
    });

    return result as Paginated<BookingResource>;
  }, [$limit, $skip, accountId]);

  return (
    <Page title="Bookings">
      <Card>
        <EditableBookingsList accountResource={accountResource} bookingsResource={bookingsResource} allowMassDelete />
      </Card>
    </Page>
  );
};

export default BookingsPage;
