import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { SiteResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import { Link } from 'react-router-dom';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import { css, cx } from '@emotion/css';
import { breakpoints, spacings } from '@admin/styles/variables';
import spacingStyles from '@admin/styles/spacings';
import { widthStyles } from '@admin/styles/utils';
import React, { useEffect } from 'react';
import { useServiceContext } from '@admin/context/ServiceContext';
import Map from '@admin/components/Map';
import Container from '../layout/Container';
import Card from '../Card';
import { Marker, Popup } from 'react-leaflet';
import { RecommendAHub } from '@admin/components/public/recommendAHub';

export const hubGrid = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1rem;
`;

export const hubDetails = css`
  @media (max-width: ${breakpoints.small}) {
    grid-column: span 2;
  })
`;

export const hubRecommend = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const hubTitle = css`
  @media (max-width: ${breakpoints.small}) {
    font-size: calc(${spacings.sm} * 0.8);
  })
`;

export const hubText = css`
  @media (max-width: ${breakpoints.small}) {
    font-size: calc(${spacings.sm} * 0.8);
  })
`;

export const cardLink = css`
  color: black;

  &:hover,
  &:focus {
    color: black;
  }
`;

export const cardImg = css`

  margin-bottom: ${spacings.sm};
  width: 100%;

  @media (max-width: ${breakpoints.small}) {
    margin: -${spacings.sm} -${spacings.sm} ${spacings.sm} -${spacings.sm};
    width: calc(100% + 2 * (${spacings.sm}));
    border-radius: 10px 10px 0 0;
  })
`;

export const locationsTitle = css`
  margin: ${spacings.sm} 0;

  @media (max-width: ${breakpoints.small}) {
    font-size: ${spacings.sm};
    margin-left: ${spacings.md};
  }
`;

const PublicNearbyHubsWithMap = () => {
  const { siteService } = useServiceContext();
  const [currentLocation, setCurrentLocation] = React.useState<GeolocationPosition>();

  const { value: sites, isLoading } = useAsyncRetry<SiteResource[]>(async () => {
    const result = await siteService.find({
      query: {
        $eager: '[address]',
      },
    });

    return result as SiteResource[];
  });

  // useEffect(() => {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //         setCurrentLocation(position);
  //     });
  // })

  return (
    <>
      <Map center={[53.3808694, -1.4638102]} zoomLevel={13} height={300}>
        {currentLocation?.coords !== undefined && (
          <Marker position={[currentLocation.coords.latitude, currentLocation.coords.longitude]}>
            <Popup>
              <p>
                <b>Your Location</b>
              </p>
            </Popup>
          </Marker>
        )}
        {sites !== undefined &&
          sites?.map(site => (
            <div key={`${site.id}`}>
              {site.address?.latitude && site.address?.longitude && (
                <Marker position={[site.address.latitude, site.address.longitude]}>
                  <Popup>
                    <p>
                      <b>{site.name}</b>
                    </p>
                    <p>
                      {site.address?.lines} {site.address?.postcode}
                    </p>
                    <Link to={{ pathname: `/public/find-a-hub/${site.id}` }}>View Hub</Link>
                  </Popup>
                </Marker>
              )}
            </div>
          ))}
      </Map>

      <Container>
        <LoadingSpinner loading={isLoading}>
          <h3 className={locationsTitle}>Our Locations</h3>
          <div className={hubGrid}>
            {sites !== undefined &&
              sites?.map(site => (
                <Card className={hubDetails} key={`${site.id}`}>
                  <Link className={cardLink} to={{ pathname: `/public/find-a-hub/${site.id}` }}>
                    <img className={cardImg} src={`/media/sites/${site.id}/image_0.jpg`} alt={site.name} />
                    <h4 className={hubTitle}>{site.name}</h4>
                    <p className={hubText}>{site.description}</p>
                    <p className={hubText}>
                      {site.address?.lines} {site.address?.postcode}
                    </p>
                    {/* <p><span role="img" aria-label="status">🟢</span> <b>Free</b></p> */}
                  </Link>
                </Card>
              ))}
            <RecommendAHub className={cx(hubDetails, hubRecommend)} />
          </div>
        </LoadingSpinner>
      </Container>
    </>
  );
};

export default PublicNearbyHubsWithMap;
