import { formatDate } from '@admin/styles/formats';
import { parseDateString } from '@common/utils/dateFunctions';
import React from 'react';

export const DateTime = ({
  children,
  format = formatDate,
}: {
  children: Date | string | undefined;
  format?: Intl.DateTimeFormat;
}) => (
  <>
    {children && typeof children === 'string'
      ? format.format(parseDateString(children))
      : format.format(children as Date)}
  </>
);
