/* eslint-disable prefer-template */
import React from 'react';
import { useHistory } from 'react-router';
import {css, cx} from '@emotion/css';
import flexStyles from '@admin/styles/flex';
import {baseColours, breakpoints, headingSizes, spacings} from '@admin/styles/variables';
import {BookedSlotResource, BookingResource, HubResource, SlotAvailabilityResource} from '@common/types/apiResource';
import {useUserContext} from '@admin/context/UserContext';
import {useServiceContext} from '@admin/context/ServiceContext';
import {useDialogState} from 'reakit';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import NewHubAvailabilityField from "@admin/pages/bookings/components/NewHubAvailabilityField";
import {useTheme} from '@emotion/react';
import {useMaxWidthMedia} from "@admin/hooks/useMedia";
import {CalendarSelector} from "@admin/pages/bookings/components/CalendarSelector";
import {SelectedSlotsTable} from "@admin/pages/bookings/components/SelectedSlotsTable";

// region CSS
export const bookingList = css`
  border: 1px solid ${baseColours.grey};
  border-radius: 5px;
  max-height: 25vh;
  overflow: auto;
  padding: 0.6rem 0.6rem 0.6rem 1.8rem;
`;

export const buttonContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
`;

export const buttonSecondary = css`
  flex-grow: 0;
  flex-basis: 40%;
  max-width: 200px;
`;

export const buttonPrimary = css`
  flex-grow: 0;
  flex-basis: 40%;
  max-width: 200px;
`;



const yourBooking = css`
  border: 2px solid black;
  border-radius: 1rem;
  flex-grow: 1;
  padding: ${spacings.md};
  margin: 0 1rem 0 2rem;

  @media (max-width: ${breakpoints.small}) {
    padding: 0.5rem;
    margin: 1rem -0.3rem;
    text-align: center;
  }
`;

// endregion


interface Props {
  accountId?: string;
  hub: HubResource;
  onCreate?: (booking: Partial<BookingResource>) => void;
  onCancel?: () => void;
}

export type BookedSlot = {
  date: Date;

  start: number;
  end: number;
  cost: number;

  type?: 'unavailable' | 'new';
};

const FROM_DATE = new Date("2022-1-12");
const TO_DATE = new Date("2022-3-31");

const BookingForm = ({accountId: possibleAccountId = undefined, hub, onCreate, onCancel}: Props) => {
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const history = useHistory();
  const user = useUserContext();

  const {accountService, bookingService} = useServiceContext();

  const theme = useTheme();


  const {slotAvailabilityService} = useServiceContext(true);

  const useMobileView = useMaxWidthMedia('desktop');
  const numberOfDaysToShow = React.useMemo(() => useMobileView ? 1 : 7, [useMobileView]);

  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();


  const {value: timeSlots, isLoading: timeSlotsIsLoading} = useAsyncRetry(async () => {
    return await slotAvailabilityService?.find() as SlotAvailabilityResource[];
  }, [slotAvailabilityService])

  const existingBookings = React.useMemo(() => {
    const result = hub.bookedSlots?.map(slot => {
      return {
        date: new Date(new Date(slot.start).setHours(0, 0, 0, 0)),
        start: slot.start.getHours() + slot.start.getMinutes() / 60,
        end: slot.end.getHours() + slot.end.getMinutes() / 60,
        type: "unavailable",
        cost: 0
      } as BookedSlot
    }) || [];
    return result

  }, [hub]);

  const accountId = React.useMemo(
    () => possibleAccountId || user.user?.accounts?.[0]?.id,
    [possibleAccountId, user.user?.accounts],
  );

  const {value: account, isLoading: accountIsLoading} = useAsyncRetry(async () => {
    return accountService.get(accountId as string);
  }, [accountId]);

  const isLoading = React.useMemo(() => accountIsLoading || timeSlotsIsLoading, [accountIsLoading, timeSlotsIsLoading]);

  const [selectedSlots, setSelectedSlots] = React.useState<BookedSlot[]>([]);

  const confirmDialog = useDialogState();

  const addBookingSlot = React.useCallback(async (slot: BookedSlot) => {
    slot.type = 'new';
    setSelectedSlots([...selectedSlots, slot]);
  }, [selectedSlots]);

  const createDateFromDateAndTime = (date: Date, time: number) => {
    const newDate = new Date(date);
    newDate.setHours(time, (time * 60) % 60);
    return newDate;
  }

  const onSubmitOrder = React.useCallback(async () => {
    try {
      setError(undefined);

      const populatedSlots = selectedSlots.map(
        slot =>
          ({
            start: createDateFromDateAndTime(slot.date, slot.start),
            end: createDateFromDateAndTime(slot.date, slot.end),
            hubId: hub.id,
            status: 'booked',
          } as Partial<BookedSlotResource>),
      );

      const newBookingResource: Partial<BookingResource> = {
        accountId: accountId as string,
        bookedSlots: populatedSlots,
      };

      if (onCreate) await onCreate(newBookingResource);

      history.push('/public/bookings');
    } catch (e) {
      console.error(e.message, e);
      setError(e);
    }
  }, [accountId, hub, onCreate, selectedSlots, history])

  // noinspection PointlessBooleanExpressionJS
  return (
    <>

      {true && (
        <div>

          <h4 className={cx(css`
            font-size: ${headingSizes.xxs}
          `)}>
            Select { numberOfDaysToShow === 1 ? "the day on " :  "a week in "} which you'd like to book
          </h4>

          <div
            className={cx(
              flexStyles.flex,
              css`
                flex-wrap: wrap;
                justify-content: space-between;
              `
            )}>
            <div
              className={cx(
                css`
                  flex-grow: 0;
                    // padding: ${spacings.sm};

                  @media (max-width: ${breakpoints.desktop}) {
                    flex-grow: 0;

                    // background-color: red;
                  };
                `
              )}
            >

              <CalendarSelector
                selectedSlots={selectedSlots}
                selectedDate={selectedDate}
                onSelectedDateChange={date => {

                  setSelectedDate(date)
                }}
                numberOfDaysToShow={numberOfDaysToShow}
                fromDate={FROM_DATE}
                toDate={TO_DATE}
              />

              <h4 className={cx(css`
                font-size: ${headingSizes.xxs};
                margin-top: ${spacings.lg}
              `)}>
                To select your slot just click and drag from your start time to your end time
              </h4>

              <NewHubAvailabilityField
                startDate={selectedDate}
                numberOfDays={numberOfDaysToShow}
                bookedSlots={[...existingBookings, ...selectedSlots]}
                addBookingSlot={addBookingSlot}
                timeSlots={timeSlots !== undefined ? timeSlots : []}
                fromDate={FROM_DATE}
                toDate={TO_DATE}
              />
            </div>
            <div
              className={yourBooking}
            >
              <SelectedSlotsTable
                selectedSlots={selectedSlots}
                removeSlot={idx => {
                  const newSelectedSlots = [...selectedSlots];
                  newSelectedSlots.splice(idx, 1);
                  setSelectedSlots(newSelectedSlots);
                }}
                removeAllSlots={() => setSelectedSlots([])}
                creditsRemaining={account?.creditsRemaining || 0}
                onSubmit={onSubmitOrder}
              />


            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default BookingForm;
