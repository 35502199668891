import Page from '@admin/components/layout/Page';
import HubList from '@admin/pages/admin/hubs/components/hubList';
import ButtonLink from '@admin/components/ButtonLink';
import React from 'react';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import Card from '@admin/components/Card';
import FormGroup from '@admin/components/form/FormGroup';

const HubsPage = () => (
  <Page title="Hub List">
    {/* <Row>
      <Column size={12}>
        <FormGroup>
          <ButtonLink to={{ pathname: '/admin/hubs/create' }}>Add Hub</ButtonLink>
        </FormGroup>
      </Column>
  </Row> */}
    <Row>
      <Column size={12}>
        <Card>
          <HubList />
        </Card>
      </Column>
    </Row>
  </Page>
);

export default HubsPage;
