import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { HubResource } from "@common/types/apiResource";
import LoadingSpinner from "@admin/components/LoadingSpinner";
import StyledTable from "@admin/components/StyledTable";
import { Link } from "react-router-dom";
import React from 'react';
import { useServiceContext } from "@admin/context/ServiceContext";
import ButtonLink from "@admin/components/ButtonLink";

const HubList = () => {

  const { hubService } = useServiceContext();

  const { value: hubs, isLoading } = useAsyncRetry<HubResource[]>(async () => {
    const result = await hubService.find({ query: { $eager: "site", } });

    return result as HubResource[];
  })

  return <LoadingSpinner loading={isLoading}>


    {hubs !== undefined &&
      <StyledTable<HubResource>
        titles={
          {
            "name": "Name",
            "site": "Site",
            "status": "Status",
            "actions": "Actions",

          }
        }

        mapper={
          {
            name: (hub) => (<Link to={{ pathname: `/admin/hubs/${hub.id}` }}>
              {hub.name}
            </Link>),
            site: (hub) => (<Link to={{ pathname: `/admin/sites/${hub.siteId}` }}>
              {hub.site?.name}
            </Link>),
            status: (site) => (site.active ? 'Active' : 'Disabled'),
            actions: (hub) =>
              <ButtonLink colour="primary" to={{ pathname: `/admin/hubs/${hub.id}/` }}>
                View hub
              </ButtonLink>
          }
        }

        rows={hubs}
        idKey="id"
      />
    }

  </LoadingSpinner>
    ;
};

export default HubList;
