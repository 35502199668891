import { useUserContext } from '@admin/context/UserContext';
import { FrontendCrudService } from '@admin/types/service';
import {
  AccountResource,
  AddressResource,
  AuditResource,
  BookedSlotResource,
  BookingResource,
  ClientResource,
  HubResource,
  SiteResource, SlotAvailabilityResource,
  UploadUrlResource,
  UserResource,
} from '@common/types/apiResource';
import React, { createContext, ReactNode, useContext } from 'react';
import { privateServices } from '@admin/services';
import { publicServices } from '@admin/services/public';
import { MediaReferenceService } from '@admin/services/mediaReferenceService';
import { S3Service } from '@admin/services/s3Service';
import auditService from '@admin/services/auditService';
import authorisationService from "@admin/services/authorisationService";
import slotAvailabilityService from "@admin/services/slotAvailabilityService";

export interface ServiceList {
  accountService: FrontendCrudService<AccountResource>;
  addressService: FrontendCrudService<AddressResource>;
  bookedSlotService: FrontendCrudService<BookedSlotResource>;
  bookingService: FrontendCrudService<BookingResource>;
  clientService: FrontendCrudService<ClientResource>;
  hubService: FrontendCrudService<HubResource>;
  siteService: FrontendCrudService<SiteResource>;
  userService: FrontendCrudService<UserResource>;
  mediaService: S3Service<UploadUrlResource>;
  mediaReferenceService: MediaReferenceService;
  auditService: FrontendCrudService<AuditResource> | undefined;
  authorisationService: FrontendCrudService<{ }> | undefined;
  slotAvailabilityService: FrontendCrudService<SlotAvailabilityResource> | undefined;
}

export const ServiceContext = createContext<ServiceList | undefined>(undefined);

export const ServiceContextProvider = ({ children }: { children: ReactNode }) => {
  const user = useUserContext();

  const services = user.user?.type === 'admin' ? privateServices : publicServices;

  return <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>;
};

export function useServiceContext(forcePublic = false) {
  const context = useContext(ServiceContext);

  if (forcePublic) return publicServices;

  if (!context) {
    throw new Error('Need to have a ServiceContextProvider as a parent component');
  }

  return context;
}
