import React from 'react';
import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { AccountResource } from "@common/types/apiResource";
import LoadingSpinner from "@admin/components/LoadingSpinner";
import StyledTable from "@admin/components/StyledTable";
import { useServiceContext } from "@admin/context/ServiceContext";
import ButtonLink from '../../../../components/ButtonLink';
import FormInput from "@admin/components/form/FormInput";
import Button from '../../../../components/Button';
import { css, cx } from '@emotion/css';
import flexStyles from "@admin/styles/flex";
import Link from '../../../../components/Link';
import Avatar from 'react-avatar';
import { spacings } from '@admin/styles/variables';

const tableAvatarStyle = css`
  margin-right: ${spacings.sm};
  float: left;
`;

const CreditsRemaining = ({ account }: { account: AccountResource }) => {

  const [creditsRemainingValue, setCreditsRemaining] = React.useState<number | string>(account.creditsRemaining);

  const [editing, setIsEditing] = React.useState(false);

  const {accountService} = useServiceContext();
  const updateAccountCredits = React.useCallback(async () => {
    await accountService.patch(account.id, {creditsRemaining: creditsRemainingValue as number});

    setIsEditing(false);

  }, [account, accountService, creditsRemainingValue]);

  return <>
    {editing ?
      <div className={cx(flexStyles.flex)}>
        <Button
          type="button" onClick={e => {
          setCreditsRemaining(account.creditsRemaining);
          setIsEditing(false)
        }}>Cancel</Button>

        <FormInput
          id={`credits_${account.id}`}
          name={`credits_${account.id}`}
          label="credits"
          value={creditsRemainingValue}
          showLabel={false}
          type="number"
          onChange={e => {
            let value: number | string = parseInt(e.target.value, 10);
            if (Number.isNaN(value)) value = "";
            setCreditsRemaining(value);
          }}
        />
        <Button
          disabled={Number.isNaN(parseInt(creditsRemainingValue as string, 10))}
          type="submit" onClick={e => {
          updateAccountCredits()
        }}>OK</Button>

      </div>
      :
      <div onClick={e => setIsEditing(true)}>
        {creditsRemainingValue}
      </div>
    }
  </>


};

const AccountList = () => {
  const {accountService} = useServiceContext();

  const {value: accounts, isLoading} = useAsyncRetry<AccountResource[]>(async () => {
    const result = await accountService.find({ query: { $eager: "[bookings,user]", } });
    return result as AccountResource[];
  })


  return <LoadingSpinner loading={isLoading}>


    <StyledTable<AccountResource>
      dataKeys={["name", "bookings", "creditsRemaining", "actions"]}
      titles={
        {
          "name": "Account Name",
          "creditsRemaining": "Credits",
          "bookings": "Number of bookings",
          "actions": "Actions"
        }
      }

      mapper={
        {
          name: (account) => <Link to={{ pathname: `/admin/accounts/${account.id}/` }}>
            <Avatar email={account.user?.emailAddress} round size="3rem" className={tableAvatarStyle}/>
            <b>{account.firstName} {account.surname}</b> <br />
            {account.user?.emailAddress}
          </Link>,
          bookings: (account => `${account.bookings?.length}`),
          creditsRemaining: (account) => <CreditsRemaining account={account} />,
          actions: (account) =>
            <ButtonLink colour="primary" to={{ pathname: `/admin/accounts/${account.id}/` }}>
              View account
            </ButtonLink>
        }
      }

      rows={accounts}
      idKey="id"
    />

  </LoadingSpinner>
    ;
};

export default AccountList;
