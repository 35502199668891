import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';

const rowStyle = css`
  display: flex;
  flex-wrap: wrap;
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const Row = ({ children, className }: Props) => <div className={cx(rowStyle, className)}>{children}</div>;

export default Row;
