import useQuery from '@admin/hooks/useQuery';

export interface PaginationOptions {
  key?: string;
  limit?: number;
}

export interface PaginationState {
  $limit: number;
  $skip: number;
}

export default function usePaginationQuery(options: PaginationOptions = {}): PaginationState {
  const { key = 'page', limit: $limit = 6 } = options;
  const query = useQuery();

  const page = Number(query[key]) >= 0 ? Number(query[key]) : 0;

  const $skip = page > 1 ? $limit * (page - 1) : 0;

  return {
    $skip,
    $limit,
  };
}
