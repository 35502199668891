import React, { ComponentType } from 'react';

export const Header = ({
  days,
  formatter,
  className,
  as: Component = 'th',
}: {
  days: Date[];
  formatter: Intl.DateTimeFormat;
  className?: string;
  as?: ComponentType<{ colSpan?: string | number }> | keyof JSX.IntrinsicElements;
}) => (
  <>
    {days.map(day => (
      <Component key={`${day.getMonth()}_${day.getDate()}`} className={className}>
        {formatter.format(day)}
      </Component>
    ))}
  </>
);
