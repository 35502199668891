import L, { LatLng, LatLngLiteral } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import React, { ReactNode } from 'react';

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { css } from '@emotion/css';

const DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconRetinaUrl,
  iconUrl: icon,
  shadowUrl: iconShadow,
});

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Marker.prototype.options.icon = DefaultIcon;

interface Props {
  center: LatLng | LatLngLiteral | [number, number];
  height?: number;
  zoomLevel?: number;
  children?: ReactNode;
}

const Map = ({ center, height, zoomLevel, children }: Props) => (
  <div
    className={css`
      width: 100%;
      height: ${height || 400}px;
      display: block;
    `}
  >
    <MapContainer
      center={center}
      zoom={zoomLevel || 13}
      scrollWheelZoom={false}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  </div>
);

export default Map;
