import React from 'react';
import { useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { BookedSlotResource } from '@common/types/apiResource';
import ButtonLink from '@admin/components/ButtonLink';
import { useServiceContext } from '@admin/context/ServiceContext';
import { useUserContext } from '@admin/context/UserContext';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Card from '@admin/components/Card';
import { css, cx } from '@emotion/css';
import { widthStyles } from '@admin/styles/utils';
import {formatDate, formatTime} from '@common/formats';
import {calculateLengthInHours, parseDateString} from '@common/utils/dateFunctions';
import spacingStyles from '@admin/styles/spacings';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';

export const noUpcomingContainer = css`
  color: ${baseColours.black};
  margin-top: ${spacings.md};
  margin-bottom: ${spacings.md};
  padding: ${spacings.xl};
  border-style: dashed;
  border-color: ${baseColours.greyLight};
  border-radius: ${spacings.md};
  min-width: 270px;
  text-align: center;

  @media (max-width: ${breakpoints.small}) {
    margin-left: ${spacings.md};
    margin-right: ${spacings.md};

`;

const hubContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const hubImage = css`
  flex-basis: 25%;
  flex-shrink: 1;
  flex-grow: 0;
  width: 100%;
  max-width: 350px;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

const hubDetails = css`
  flex-basis: 70%;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 1rem;
  min-width: 270px;
`;
interface Props {}

const MyUpcomingSlots = (_: Props) => {
  const user = useUserContext();
  const accountId = user.user?.accounts?.[0]?.id;

  const { bookedSlotService } = useServiceContext();

  const bookings = useAsyncRetry<BookedSlotResource[] | undefined>(async () => {
    const currentDate = new Date();
    // currentDate.setHours(0, 0, 0, 0);


    const result = await bookedSlotService.find({
      query: {
        start: { $gte: currentDate },
        $sort: { start: 1 },
        $eager: '[booking, hub, hub.site, hub.images]',
        // @ts-ignore
        'booking.accountId': accountId,
        $joinRelation: 'booking',
      },
    });

    return result as BookedSlotResource[];
  }, [accountId]);

  return (
    <>
      {bookings.value && bookings.value.length > 0 ? (
        <>
          <Card>
            <h3>Upcoming</h3>
            <p>
              SLOTS BOOKED: <strong>{bookings.value.length}</strong>
            </p>
          </Card>
          {bookings.value?.map(slot => (
            <Card key={slot.id}>
              <div className={hubContainer}>
                <img className={hubImage} src={slot.hub?.images?.[0]?.url} alt={slot.hub?.images?.[0]?.title} />

                <div className={hubDetails}>
                  <h4>
                    {formatDate.format(slot.start)}
                  </h4>
                  <p>
                    Time: <b>{formatTime.format(slot.start)}-{formatTime.format(slot.end)} ({calculateLengthInHours(slot.start, slot.end)} hrs)</b>
                    <br />
                    Hub: {slot.hub?.name}
                  </p>
                  <p>Booking reference: {slot.id}</p>
                  <ButtonLink to={`/public/view-booking/${slot.id}`}>View booking</ButtonLink>
                </div>
              </div>
            </Card>
          ))}
        </>
      ) : (
        <div className={noUpcomingContainer}>
          <h3>You have no upcoming bookings</h3>
          <br />
          <ButtonLink to="/public/find-a-hub">Find a Hub</ButtonLink>
        </div>
      )}
    </>
  );
};

export default MyUpcomingSlots;
