import React, { ReactNode } from 'react';
import { useUserContext } from '@admin/context/UserContext';

import AccountSettingsPage from '@admin/pages/public/account/account';

interface Props {
  children: ReactNode;
}

const AccountCreationRequired = ({ children }: Props) => {
  const user = useUserContext();

  return <>{user.user === undefined || user.user?.accounts?.length ? children : <AccountSettingsPage />}</>;
};

export default AccountCreationRequired;
