import StyledTable from '@admin/components/StyledTable';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { AccountResource, AuditResource } from '@common/types/apiResource';
import { useServiceContext } from '@admin/context/ServiceContext';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import React from 'react';
import { css, cx } from '@emotion/css';
import flexStyles from '@admin/styles/flex';
import spacings from '@admin/styles/spacings';

interface Props {}

const maximumToShow = 10;

interface AccountUsage extends AccountResource {
  deletedBookings: number;
}

const AccountUsageStats = ({}: Props) => {
  const { accountService, auditService } = useServiceContext();

  const [numberToShow, setNumberToShow] = React.useState(10);

  const { value, isLoading } = useAsyncRetry<AccountUsage[]>(async () => {
    if (auditService === undefined) throw new Error('Not logged in as Admin');

    const result = (await accountService.find({
      query: {
        $eager: '[bookings.bookedSlots, user]',
        $sort: { id: 1 },
      },
      // @ts-ignore
      paginate: false,
    })) as AccountResource[];

    const userIds = result.map(({ userId }) => userId);

    const audits = (await auditService.find({
      query: {
        userId: { $in: userIds.join(",") },
      },
      // @ts-ignore
      paginate: false,
    })) as AuditResource[];

    const resultWithAudit = result.map(
      account =>
        ({
          ...account,
          bookingsMade: audits.filter(
            ({ userId, type, subType }) => userId === account.userId && type === 'BookedSlot' && subType === 'Create',
          ).length,
          deletedBookings: audits.filter(
            ({ userId, type, subType }) => userId === account.userId && type === 'BookedSlot' && subType === 'Delete',
          ).length,
        } as AccountUsage),
    );

    if (result.length > maximumToShow) setNumberToShow(maximumToShow);
    else if (numberToShow > result.length) setNumberToShow(result.length);

    return resultWithAudit;
  }, []);

  return (
    <LoadingSpinner loading={isLoading}>
      {value && (
        <StyledTable<AccountResource>
          header={({ csvButton }) => (
            <div
              className={cx(
                flexStyles.flex,
                css`
                  align-items: baseline;
                `,
              )}
            >
              <h3>Accounts</h3>
              {value && (
                <>
                  <span className={spacings.margin.xs}>
                    1-{numberToShow} of {value.length}
                  </span>
                  <span className={spacings.margin.xs}>{csvButton}</span>
                </>
              )}
            </div>
          )}
          idKey="id"
          titles={{
            id: 'Id',
            email: 'Email',
            name: 'Name',
            bookingsMade: 'Total booked',
            deletedBookings: 'Total deleted',
            // currentBookings: "Current Bookings",
            slotsUsed: 'Active',
            creditsRemaining: 'Credits Left',
          }}
          types={{
            id: 'number',
            bookingsMade: 'number',
            deletedBookings: 'number',
            currentBookings: 'number',
            slotsUsed: 'number',
            creditsRemaining: 'number',
          }}
          mapper={{
            email: account => account.user?.emailAddress,
            name: account => `${account.firstName} ${account.surname}`,
            currentBookings: account => `${account.bookings?.length}`,
            slotsUsed: account =>
              `${account.bookings?.reduce((sum, { bookedSlots }) => sum + (bookedSlots?.length || 0), 0)}`,
          }}
          rows={value}
          allowColumnSorting
          numberToShow={numberToShow}
          allowCSVDownload
        />
      )}
    </LoadingSpinner>
  );
};

export default AccountUsageStats;
