import { ThemeBase } from '@common/types/emotion';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';

import headingStyles from '@admin/styles/headings';
import { srOnlyStyle } from '@admin/styles/utils';
import { fonts, headingSizes, spacings } from '@admin/styles/variables';
import React, { ReactNode } from 'react';

const labelStyleDefaults = css`
  font-family: ${fonts.heading};
  font-size: ${headingSizes.sm};
  margin-bottom: ${spacings.xs};
`;

export const labelStyle = css`
  display: block;
  ${labelStyleDefaults};
`;

const inlineLabelStyle = labelStyleDefaults;

interface Props {
  children: ReactNode;
  id: string;
  color?: keyof ThemeBase;
  size?: keyof typeof headingSizes;
  show?: boolean;
  inline?: boolean;
  className?: string;
}

const FormLabel = ({
  children,
  id,
  size = 'sm',
  show = true,
  color = 'tertiary',
  inline = false,
  className,
}: Props) => {
  const theme = useTheme();
  const themedLabelStyle = css`
    ${inline ? inlineLabelStyle : labelStyle};
    color: ${theme[color]};
  `;
  return (
    <label htmlFor={id} className={cx(themedLabelStyle, headingStyles[size], className, !show && srOnlyStyle)}>
      {children}
    </label>
  );
};

export default FormLabel;
