import { baseColours, breakpoints, fontWeights, spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React from 'react';
import UserHeader from '@admin/components/layout/UserHeader';
import Link from '../Link';
import Logo from '@admin/images/wfh-logo.png';
import Container from './Container';
import { Navigation } from '@admin/components/layout/Navigation';
import { useMaxWidthMedia } from '@admin/hooks/useMedia';
import Avatar from 'react-avatar';
import { useUserContext } from '@admin/context/UserContext';

const navbarStyle = css`
  background: ${baseColours.white};
  font-weight: ${fontWeights.bold};
  color: ${baseColours.black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  box-shadow: 2px 2px 10px ${baseColours.greyDark};
  width: 100%;
  z-index: 2000;
  @media (max-width: ${breakpoints.small}) {
    padding-top: 0;
    padding-bottom: 0;
  })
`;

const navContainer = css`
  display: flex;
  flex-wrap: wrap;
`;

const title = css`
  align-items: center;
  display: flex;

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
    width: 100%;
    justify-content: center;
  })
`;

const logo = css`

  width: 200px;
  padding: 0;

  @media (max-width: ${breakpoints.small}) {
    width: 55vw;
    padding: 0.5rem 0;
  })


`;

export const navSectionStyle = css`
  align-items: center;
  list-style: none;
  margin: 0;
  flex-grow: 1;
  display: flex;

  li {
    display: inline-block;
    margin-right: 0;
    text-transform: uppercase;

    &:hover,
    &:focus {
      transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    order: 2;
    padding-left: 0;
    justify-content: space-around;
  })
`;

export const navLoginDetails = css`
  display: flex;
  padding-right: 0;

  @media (max-width: ${breakpoints.small}) {
    order: 1;
    flex-basis: 100%;
    padding-right: 0;
  })
`;

export const linkStyle = css`
  border-bottom: 2px solid transparent;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: ${fontWeights.bold};
  outline: none;
  padding: 0.5rem;
  white-space: nowrap;
  margin-top: 0;
  width: 100%;
  color: ${baseColours.greyDark};

  &:hover {
    color: ${baseColours.white};
    background: ${baseColours.black};
    text-decoration: none;
  }

  &[aria-current='page'],
  &:focus {

    text-decoration: none;
    transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
  }

  @media (max-width: ${breakpoints.small}) {
    margin-top: 0;
  })
`;

const Header = () => {
  const showMenu = useMaxWidthMedia('small');
  const user = useUserContext();

  const [isNavVisible, setNavVisible] = React.useState(false);

  return (
    <div className={navbarStyle}>
      <Container className={navContainer}>
        <span className={title}>
          <Link to="/" className={title}>
            <img src={Logo} className={logo} alt="Work from Hubs" />
          </Link>
          {showMenu && (
            <Avatar
              email={user.user?.emailAddress}
              round
              size="10vw"
              onClick={e => {
                e.preventDefault();
                setNavVisible(!isNavVisible);
              }}
            />
          )}
        </span>

        <Navigation>
          {(!showMenu || isNavVisible) && (
            <div
              className={cx(
                navLoginDetails,
                showMenu &&
                  css`
                    position: relative;
                    width: 100%;
                  `,
              )}
            >
              <div
                className={cx(
                  showMenu &&
                    css`
                      position: static;
                      width: 100%;
                    `,
                )}
              >
                <UserHeader />
              </div>
            </div>
          )}
        </Navigation>
      </Container>
    </div>
  );
};

export default Header;
