import spacingStyles from '@admin/styles/spacings';
import { cx } from '@emotion/css';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const FormGroup = ({ children, className }: Props) => (
  <div className={cx(spacingStyles.marginBottom.md, className)}>{children}</div>
);

export default FormGroup;
