import ModalButton from '@admin/components/ModalButton';
import Button from '@admin/components/Button';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import flexStyles from '@admin/styles/flex';
import React, { ReactNode } from 'react';
import spacingStyles from '@admin/styles/spacings';

export const DeleteModalButton = ({
  buttonText,
  bodyText,
  onCancel,
  onConfirm,
  confirmText = 'Yes, Delete',
  cancelText = 'Cancel',
  headingText = 'Confirm Delete',
  disabled = false,
}: {
  buttonText: string;
  bodyText: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  headingText?: string;
  disabled?: boolean;
}) => (
  <ModalButton
    aria-label={buttonText}
    button={
      <Button disabled={disabled} colour="error">
        {buttonText}
      </Button>
    }
  >
    {modal => (
      <>
        <h3>{headingText}</h3>
        <Row className={spacingStyles.marginBottom.lg}>
          <Column size={12}>
            <strong>{bodyText}</strong>
          </Column>
        </Row>
        <Row>
          <Column size={6} className={flexStyles.flexCenterAll}>
            <Button
              type="button"
              onClick={() => {
                modal.hide();
                onCancel();
              }}
            >
              {cancelText}
            </Button>
          </Column>
          <Column size={6} className={flexStyles.flexCenterAll}>
            <Button
              type="button"
              onClick={() => {
                modal.hide();
                onConfirm();
              }}
            >
              {confirmText}
            </Button>
          </Column>
        </Row>
      </>
    )}
  </ModalButton>
);
