import Button from '@admin/components/Button';
import { css, cx } from '@emotion/css';
import { widthStyles } from '@admin/styles/utils';
import React from 'react';
import { useUserContext } from '@admin/context/UserContext';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { AccountResource, BookedSlotResource, BookingResource } from '@common/types/apiResource';
import { useServiceContext } from '@admin/context/ServiceContext';
import { parseDateString } from '@common/utils/dateFunctions';
import Column from '@admin/components/layout/Column';
import Row from '@admin/components/layout/Row';
import Card from '@admin/components/Card';
import Hero from '@admin/components/Hero';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import {formatDate, formatDateInd, formatTime} from '@admin/styles/formats';
import ButtonLink from '@admin/components/ButtonLink';
import TimeOfDay from '@admin/components/timeOfDay';

export const bookingsTitle = css`

font-size: ${spacings.md};

  @media (max-width: ${breakpoints.small}) {
    font-size: ${spacings.sm};
    margin-bottom: 0;
  })
`;

export const bookingsText = css`

 margin-bottom: ${spacings.sm};

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 0.8rem;
  })
`;

const getStatus = (bookedSlot?: BookedSlotResource) => {
  if (bookedSlot === undefined) return 'none';

  const time = new Date();

  if (bookedSlot.start > time) return 'upcoming';
  return 'current';
};

export const UpcomingBookings = () => {
  const user = useUserContext();

  const { bookingService, bookedSlotService } = useServiceContext();

  interface BookingResult {
    bookedSlot: BookedSlotResource | undefined;
    status: 'current' | 'upcoming' | 'none';
  }

  const { value, isLoading } = useAsyncRetry<BookingResult | undefined>(async () => {
    const accountId = user.user?.accounts?.[0]?.id;

    if (accountId === undefined) return undefined;

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const bookedSlots = (await bookedSlotService.find({
      query: {
        $limit: 1,
        start: { $gte: currentDate },
        $sort: { start: 1 },
        $eager: '[booking]',

        // @ts-ignore
        'booking.accountId': accountId,

        $joinRelation: 'booking',
      },
    })) as BookedSlotResource[];

    /*
    bookedSlots = [];

    bookedSlots=[{
      date: new Date().toISOString(),
      booking: {
        id: "1",
        accountId: accountId.toString(),
        createdAt: "",
        updatedAt: ""
      },
      status: "booked",
      timeOfDay: "pm", // new Date().getTime()>= 12 ? "am" : "pm",
      _date: new Date(),
      bookingId: "1",
      hubId: "1",
      id: "1",
    }];
     */

    const bookedSlot = bookedSlots.length > 0 ? bookedSlots[0] : undefined;

    const isMorning = new Date().getTime() < 12;

    return {
      bookedSlot,
      status: getStatus(bookedSlot),
    };
  }, [bookedSlotService, user]);

  return (
    <>
      {value && (
        <>
          {value.status === 'none' ? (
            <>
              <h3 className={bookingsTitle}>No Bookings</h3>
              <p className={bookingsText}>You have no bookings</p>
              <ButtonLink className={cx(widthStyles.full)} to="/public/find-a-hub">
                Find a hub
              </ButtonLink>
            </>
          ) : (
            <>
              <h3 className={bookingsTitle}>{value.status === 'upcoming' ? 'Next Booking' : 'Current Booking'}</h3>

              {value.bookedSlot && (
                <p className={bookingsText}>
                  {formatDateInd.format(value.bookedSlot?.start)},
                  {formatTime.format(value.bookedSlot?.start)}
                </p>
              )}

              <ButtonLink className={cx(widthStyles.full)} to={`/public/view-booking/${value.bookedSlot?.id}`}>
                View
              </ButtonLink>
            </>
          )}
        </>
      )}
    </>
  );
};
