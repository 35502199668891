import Hero from '@admin/components/Hero';
import Card from '@admin/components/Card';
import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import Link from '@admin/components/Link';
import spacingStyles from '@admin/styles/spacings';
import { spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React from 'react';
import { termsContent, termsHeroContent } from './terms';

const rowStyle = css`
  margin-top: ${spacings.sm};
  margin-bottom: calc(2 * ${spacings.xl});
`;

const PrivacyPage = () => {
  return (
    <Page title="Privacy Policy" fullWidth>
      <Hero title="Privacy Policy">
        <div className={termsHeroContent}>
          <Row>
            <Column size={8}>
              <p>Last updated on 3rd September 2021</p>
            </Column>
            <Column size={4}>
              <h3>Read also</h3>
              <p>
                <Link to="/terms">Terms &amp; Conditions</Link> <br />
                <Link to="/terms/cookies">Cookie Policy</Link>
              </p>
            </Column>
          </Row>
        </div>
      </Hero>
      <Container>
        <Card className={cx(spacingStyles.marginTop.lg)}>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>The type of personal information we collect</h3>
            </Column>
            <Column size={8}>
              <p>We currently collect and process the following information:</p>
              <ul>
                <li>
                  <p>
                    Identity Data includes first name, last name, preferred pronoun, username or similar identifier,
                    title.
                  </p>
                </li>
                <li>
                  <p>
                    Contact Data includes email address and telephone numbers, as well as details of any additional
                    persons for whom copy correspondence is required.
                  </p>
                </li>
              </ul>
            </Column>
          </Row>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>How we get the personal information and why we have it</h3>
            </Column>
            <Column size={8}>
              <p>
                Most of the personal information we process is provided to us directly by you for one of the following
                reasons:
              </p>
              <ul>
                <li>
                  <p>To communicate with you</p>
                </li>
                <li>
                  <p>To provide access to the WorkfromHub WebApp</p>
                </li>
              </ul>
              <p>
                We use the information that you have given us in order to communicate with you and as a record of you
                involvement in the WorkfromHub Pilot.&nbsp;
              </p>
              <p>
                Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this
                information are:
              </p>
              <p>
                (a) Your consent. You are able to remove your consent at any time. You can do this by contacting Neal
                Byers.
              </p>
              <p>(b) We have a legal obligation.</p>
            </Column>
          </Row>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>How we store your personal information</h3>
            </Column>
            <Column size={8}>
              <p>Your information is securely stored on the Nota Bene Consulting One Drive.&nbsp;</p>
              <p>
                We keep identity and contact information for five years. We will then dispose your information by
                deleting the data from our servers and any relevant information held in email.
              </p>
            </Column>
          </Row>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Your data protection rights</h3>
            </Column>
            <Column size={8}>
              <p>Under data protection law, you have rights including:</p>
              <p>
                Your right of access&nbsp;- You have the right to ask us for copies of your personal information.&nbsp;
              </p>
              <p>
                Your right to rectification&nbsp;- You have the right to ask us to rectify personal information you
                think is inaccurate. You also have the right to ask us to complete information you think is
                incomplete.&nbsp;
              </p>
              <p>
                Your right to erasure&nbsp;- You have the right to ask us to erase your personal information in certain
                circumstances.&nbsp;
              </p>
              <p>
                Your right to restriction of processing&nbsp;- You have the right to ask us to restrict the processing
                of your personal information in certain circumstances.&nbsp;
              </p>
              <p>
                Your right to object to processing&nbsp;- You have the the right to object to the processing of your
                personal information in certain circumstances.
              </p>
              <p>
                You are not required to pay any charge for exercising your rights. If you make a request, we have one
                month to respond to you.
              </p>
              <p>Please contact us at info@workfromhub.co.uk if you wish to make a request.</p>
            </Column>
          </Row>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Our contact details</h3>
            </Column>
            <Column size={8}>
              <p>Name: Neal Byers</p>
              <p>Address: 19 Charnock View Road, Sheffield, S12 3HH</p>
              <p>Phone Number: 07779 016059</p>
              <p>E-mail: info@workfromhub.co.uk</p>
            </Column>
          </Row>
        </Card>
      </Container>
    </Page>
  );
};

export default PrivacyPage;
