import { ThemeBase } from '@common/types/emotion';
import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@emotion/react';
import ErrorMessage from '@admin/components/ErrorMessage';
import FormHint from '@admin/components/form/FormHint';
import FormLabel from '@admin/components/form/FormLabel';
import { inputGradientStyle } from '@admin/styles/gradients';
import { smallBoxShadow } from '@admin/styles/shadows';
import { fonts, headingSizes, spacings } from '@admin/styles/variables';
import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, ReactNode } from 'react';

export const inputStyle = css`
  ${smallBoxShadow()};
  border-radius: 10px;
  font-family: ${fonts.heading};
  font-size: 1rem;
  line-height: 1.3;
  padding: 1rem 1.4rem 0.7rem 1rem;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const themedInputStyle = (theme: Theme) => css`
  ${inputStyle};
  background: ${theme.background1};
  border: 1px solid ${theme.border1};
  color: ${theme.inputColor};
  border: 3px solid blue;

  &:focus,
  &:not([value='']) {
    ${inputGradientStyle(theme)};
    border: 1px solid ${theme.border1};
  }

  &:focus {
    border: 1px solid ${theme.primary};
    background: none;
  }

  &:disabled {
    background: ${theme.disabledButtonBackground};
    border: ${theme.disabledButtonBorder};
  }

  &::placeholder {
    color: ${theme.text2};
  }
`;

export const inputErrorStyle = css`
  margin-bottom: ${spacings.sm};
`;

export interface FormInputProps {
  children?: (input: ReactNode) => void;
  className?: string;
  disabled?: boolean;
  error?: string;
  hint?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingSizes;
  labelColor?: keyof ThemeBase;
  labelClassName?: string;
  name: string;
  placeholder?: string;
  showLabel?: boolean;
  type?: 'text' | 'number' | 'password' | 'email' | 'checkbox';
  value: string | number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}

const FormInput = ({
  children,
  className,
  disabled = false,
  error,
  hint,
  id,
  label,
  labelSize,
  labelColor,
  labelClassName,
  name,
  placeholder,
  showLabel = true,
  type = 'text',
  value,
  onBlur,
  onChange,
  onKeyUp,
  ...props
}: FormInputProps) => {
  const theme = useTheme();

  const input = (
    <input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      aria-describedby={cx(error && `${id}-error`, hint && `${id}-hint`)}
      className={cx(
        themedInputStyle(theme),
        className,
        labelClassName,
        !!error &&
          css`
            ${inputErrorStyle};

            &:not(:focus) {
              border: 1px solid ${theme.error};
            }
          `,
      )}
      type={type}
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={onKeyUp}
    />
  );

  return (
    <>
      <FormLabel id={id} show={showLabel} size={labelSize} color={labelColor} className={labelClassName}>
        {label}
      </FormLabel>

      {hint && <FormHint id={`${id}-hint`}>{hint}</FormHint>}

      {typeof children === 'function' ? children(input) : input}

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormInput;
