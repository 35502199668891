/* eslint-disable */
import { parseDateString } from '@common/utils/dateFunctions';

// eslint-disable-next-line @typescript-eslint/ban-types,import/prefer-default-export
export const parseJson = (json: object | object[] | null | undefined): object | object[] | null | undefined => {
  if (json === null) return null;
  if (json === undefined) return undefined;

  if (Array.isArray(json)) {
    return json.map(obj => parseJson(obj));
  }

  if (typeof json === 'object') {
    return Object.entries(json).reduce((newObj, [key, value]) => {
      value = parseJson(value);

      const addParsed = {} as any;

      if (typeof(value) === "string" && (key === 'date' || key==='start' || key==='end')) {
        value = parseDateString(value);
      }

      return { ...newObj, [key]: value };
    }, {});
  }

  return json;
};
