import Page from '@admin/components/layout/Page';
import Column from '@admin/components/layout/Column';
import Row from '@admin/components/layout/Row';
import { useUserContext } from '@admin/context/UserContext';
import Card from '@admin/components/Card';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import AccountUsageStats from '@admin/pages/admin/stats/AccountUsageStats';
import { useServiceContext } from "@admin/context/ServiceContext";
import Button from '@admin/components/Button';
import headingStyles from '@admin/styles/headings';

const SettingsPage = () => {
    const user = useUserContext();

    const { authorisationService } = useServiceContext();

    return (
        <Page title="Settings">
            <Row>
                <Column size={12}>
                    <Card>
                        <h3 className={headingStyles.xs}>Authorisation</h3>
                        <Button
                            onClick={async () => {
                                await authorisationService?.create({});
                            }}
                            title="Rebuild Authorisation"
                        >
                            Rebuild Authorisation
                        </Button>
                    </Card>
                </Column>
            </Row>
        </Page>
    );
};

export default SettingsPage;
