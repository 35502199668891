/* eslint-disable no-bitwise */
import { HubResource } from '@common/types/apiResource';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import flexStyles from '@admin/styles/flex';
import React, { LegacyRef } from 'react';
import { MonthsHeader } from '@admin/pages/bookings/components/MonthsHeader';
import { Header } from '@admin/pages/bookings/components/Header';
import { Availability, GetHubAvailablility } from '@common/utils/bookings';
import { AvailabilityCell, AvailabilityCellProps } from '@admin/pages/bookings/components/AvailabilityCell';
import * as formats from '@admin/styles/formats';
import { dateWithoutTime, Slot } from '@common/utils/dateFunctions';
import { HubAvailabilityFieldProps } from '@admin/pages/bookings/components/HubAvailabilityField';
import FormInput from '@admin/components/form/FormInput';
import { css, cx } from '@emotion/css';
import { breakpoints, fontWeights } from '@admin/styles/variables';
import { useMaxWidthMedia } from '@admin/hooks/useMedia';
import Button from '@admin/components/Button';
import {formatWeekShort} from "@admin/styles/formats";

const generateWeeksFromOptions = (startDate: Date, endDateOrNumberOfWeeks: number | Date) => {
  let endDate: Date;

  startDate.setHours(0, 0, 0, 0);
  if (typeof endDateOrNumberOfWeeks === 'number') {
    endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + endDateOrNumberOfWeeks * 7);
  } else {
    endDate = endDateOrNumberOfWeeks as Date;
    endDate.setHours(0, 0, 0, 0);
  }

  const numberOfDays = ((endDate.getTime() - startDate.getTime()) / 86400000) | 0;

  const dates = [...new Array(numberOfDays + 1)].map((_, idx) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + idx);
    return date;
  });

  return dates;
};

export interface HubAvailabilityProps extends HubAvailabilityFieldProps {
  hub: HubResource;
  numberOfWeeks?: number;
  timesOfDay?: string[];
  fromDate?: Date;
  untilDate?: Date;
  initialSelection?: Slot[];
  cellProps?: Partial<AvailabilityCellProps> | ((day: Date, timeOfDay: string) => Partial<AvailabilityCellProps>);
}

const FROM_DATE = new Date("2021-11-15");
const TO_DATE = new Date("2021-12-23");


/**
 * This version of the HubAvailability component requires that you provide a hub object with bookedSlots and
 * availability objects filled in. If you don't have these you can used the HubAvailabilityAsync to fetch it
 * by id instead.
 * @param hub
 * @param numberOfWeeks
 * @param timesOfDay
 * @param fromDate
 * @param initialSelection
 * @param cellProps
 * @param currentBookingId
 * @constructor
 */
export const HubAvailability = ({
  hub,
  numberOfWeeks = 4,
  timesOfDay = ["7","8","9","10","11","12","13","14","15","16","17","18","19","20", "21"], // ['am', 'pm'],
  fromDate = FROM_DATE, // = new Date("2021-09-13"),
  untilDate = TO_DATE, // = new Date("2021-10-08"),
  initialSelection = [],
  cellProps = undefined,
  currentBookingId = undefined,
  formatMonth = formats.formatMonth,
  formatDay = formats.formatDay,
  formatWeek = formats.formatWeekShort,
}: HubAvailabilityProps) => {
  // @refresh reset

  const nowDate = React.useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const startDate = React.useMemo(() => {
    let date = new Date();
    if (fromDate) {
      if (date < fromDate) {
        date = fromDate;
      }
    }
    // const date = fromDate !== undefined ? new Date(fromDate) : new Date();

    date.setDate(date.getDate() - (date.getDay() % 7));
    return dateWithoutTime(date);
  }, [fromDate]);

  const days = React.useMemo(
    () => generateWeeksFromOptions(startDate, untilDate || numberOfWeeks),
    [untilDate, numberOfWeeks, startDate],
  );

  const getAvailablity = React.useCallback(
    (day: Date, timeOfDay: string): Availability => GetHubAvailablility(currentBookingId, hub, day, timeOfDay, nowDate),
    [currentBookingId, hub, nowDate],
  );

  const isRotated = useMaxWidthMedia('desktop');
  const table = css`
    display: grid;
    position: relative;

    grid-template: repeat(5, 2fr) / repeat(${days.length + 1}, 2fr);

    grid-auto-flow: row;
    grid-gap: 0;// .3rem;
    column-gap:1px;

    //justify-content: center;
    align-items: center;

    div {
      justify-self: center;
      display: inline-block;
    }

    @media (max-width: ${breakpoints.desktop}) {
      grid-template: repeat(${days.length + 1}, 2fr) / repeat(5, 2fr);
      grid-auto-flow: column;
    }

    @media (max-width: ${breakpoints.small}) {
      column-gap: 1rem;
      // row-gap: 1rem;
    }

    transition: left 1s, top 1s;
  `;

  const tableHeader = css`
    font-weight: ${fontWeights.bold};
  `;

  const scroll = css`
    overflow: hidden;
    flex-basis: 50%;
    margin: auto;
  `;

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const [scrollValue, setScrollValue] = React.useState(0);
  const [previousDisabled, setPreviousDisabled] = React.useState(true);
  const [nextDisabled, setNextDisabled] = React.useState(false);

  const calculateScrollValues = React.useCallback(() => {
    if (scrollRef.current === null) {
      return [400, 400];
    }

    const sizeInParent = isRotated ? scrollRef.current.clientHeight : scrollRef.current.clientWidth;
    const contentSize = isRotated ? scrollRef.current.scrollHeight : scrollRef.current.scrollWidth;

    // eslint-disable-next-line no-shadow
    const maxPositionToScrollTo = contentSize - sizeInParent;

    // eslint-disable-next-line no-shadow
    const amountToScroll = sizeInParent * 0.75;

    return [amountToScroll, maxPositionToScrollTo];
  }, [isRotated, scrollRef]);

  const next = React.useCallback(() => {
    const [amountToScroll, maxPositionToScrollTo] = calculateScrollValues();

    const position = Math.min(scrollValue + amountToScroll, maxPositionToScrollTo);

    setScrollValue(position);
    if (position >= maxPositionToScrollTo) setNextDisabled(true);
    setPreviousDisabled(false);
  }, [calculateScrollValues, scrollValue]);

  const prev = React.useCallback(() => {
    const [amountToScroll, maxPositionToScrollTo] = calculateScrollValues();

    const position = Math.max(scrollValue - amountToScroll, 0);
    if (position === 0) setPreviousDisabled(true);
    setNextDisabled(false);

    setScrollValue(position);
  }, [calculateScrollValues, scrollValue]);

  React.useEffect(() => {
    setNextDisabled(false);
    setPreviousDisabled(true);
    setScrollValue(0);
  }, [isRotated]);

  return (
    <>
      <div
        className={cx(
          flexStyles.flex,
          flexStyles.flexCenterAll,
          css`
            flex-direction: ${isRotated ? 'column' : 'row'};
          `,
        )}
      >
        <div
          className={css`
            margin: 1rem auto 2rem;
          `}
        >
          <Button disabled={previousDisabled} onClick={prev}>
            Previous Slots
          </Button>
        </div>
        <div className={cx(scroll)}>
          <div
            ref={scrollRef}
            className={cx(
              table,
              css`
                // max-height: 50vh;
                ${isRotated ? 'top' : 'left'}: ${-scrollValue}px
              `,
            )}
          >
            <div />
            <MonthsHeader as="div" days={days} formatter={formatMonth} rotated={isRotated} className={tableHeader} />
            <div />
            <Header as="div" days={days} formatter={formatDay} className={tableHeader} />
            <div />
            <Header as="div" days={days} formatter={formatWeek} className={tableHeader} />

            {timesOfDay.map(timeOfDay => (
              <React.Fragment key={timeOfDay}>
                <div className={tableHeader}>{timeOfDay}</div>
                {days.map((day, idx) => (
                  <AvailabilityCell
                    as="div"
                    key={day.valueOf()}
                    day={day}
                    availability={getAvailablity(day, timeOfDay)}
                    timeOfDay={timeOfDay}
                    disabled={getAvailablity(day, timeOfDay) !== 'available'}
                    {...(cellProps && (typeof cellProps === 'function' ? cellProps(day, timeOfDay) : cellProps))}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          className={css`
            margin: 2rem auto 1rem;
          `}
        >
          <Button disabled={nextDisabled} onClick={next}>
            Next Slots
          </Button>
        </div>
      </div>
    </>
  );
};
