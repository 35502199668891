import Card from '@admin/components/Card';
import { cx } from '@emotion/css';
import { textStyles, widthStyles } from '@admin/styles/utils';
import ButtonLink from '@admin/components/ButtonLink';
import React from 'react';
import { extraCard, hubText, hubTitle } from '@admin/components/public/nearbyHubsList';

export const RecommendAHub = ({ className = extraCard }) => {
  return (
    <Card className={className}>
      <h4 className={cx([hubTitle, textStyles.center])}>Help us improve our service for you?</h4>
      <p className={cx([hubText, textStyles.center])}>
        Share your idea for a new hub location, and give us some feedback
      </p>
      <ButtonLink to="https://docs.google.com/forms/d/e/1FAIpQLScE4WpnnYoRxCIwf_iq_zr1FYaRs6TUWtS7gn4T7vH-G2rKgA/viewform" target="_blank" className={cx(widthStyles.full)}>
        Feedback
      </ButtonLink>
    </Card>
  );
};
