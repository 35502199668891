import { css } from '@emotion/css';
import React, { ReactNode } from 'react';
import { FaToilet, FaShoppingBasket, FaMugHot, FaPrint } from 'react-icons/fa';

const facilitiesList = css`
  padding: 0;
  margin: 1rem 0;

  li {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
  }
  svg {
    flex: 0 0 60px;
  }
  p {
    flex: 0 1 100%;
  }
`;

const FacilitiesList = () => {
  return (
    <>
      <ul className={facilitiesList}>
        <li>
          <FaToilet size={30} />
          <p>
            <strong>Toilets</strong> - male, female and accessible
          </p>
        </li>
        <li>
          <FaShoppingBasket size={30} />{' '}
          <p>
            <strong>Shop</strong>, WH Smiths kiosk - stationery, refreshments
          </p>
        </li>
        <li>
          <FaMugHot size={30} />
          <p>
            <strong>Cafe</strong>
          </p>
        </li>
        <li>
          <FaPrint size={30} />
          <p>
            <strong>Printing services </strong>– available to the public{' '}
            <a href="https://www.shu.ac.uk/the-print-shop/ ">https://www.shu.ac.uk/the-print-shop/</a>
          </p>
        </li>
      </ul>
    </>
  );
};

export default FacilitiesList;
