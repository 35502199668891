import Hero from '@admin/components/Hero';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import { css } from '@emotion/css';
import React from 'react';

const LoginError = () => {
  return (
    <Page title="Login Error" fullWidth>
      <Hero title="Login Error" />
      <Container>
        <div className={css(`padding: 7rem 1rem; font-weight: bold; height: 50vh`)}>
          <p>
            <strong>There is an error with your login details</strong>
          </p>
          <p>
            <strong>Please logout and try again</strong>
          </p>
          <p>
            <strong>
              If the problem persists please contact <a href="mailto:info@workfromhub.co.uk">info@workfromhub.co.uk</a>
            </strong>
          </p>
        </div>
      </Container>
    </Page>
  );
};

export default LoginError;
