import React from 'react';
import { useAuth0Context } from '@admin/context/Auth0Context';
import Button from './Button';

const Login = () => {
  const authContext = useAuth0Context();

  return (
    <>
      {authContext.isAuthenticated ? (
        <>
          <Button
            id="logout"
            name="Logout"
            type="submit"
            title="logout"
            inverted
            colour="textPrimary"
            size="sm"
            onClick={async () => {
              authContext.logout();
            }}
          >
            Log out
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;
