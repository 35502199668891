import Hero from '@admin/components/Hero';
import Card from '@admin/components/Card';
import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import spacingStyles from '@admin/styles/spacings';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React from 'react';

import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

export const termsContent = css`
  margin-top: ${spacings.xl};
  margin-bottom: ${spacings.xl};
  @media (max-width: ${breakpoints.tablet}) {
    margin: ${spacings.md};
  }
`;

export const termsHeroContent = css`   
  color: ${baseColours.white};
  flex-basis: 65%;
  flex-grow: 1;
  margin-top: ${spacings.md};
  margin-bottom: ${spacings.md};


  h3{
    color: ${baseColours.white};
    font-size: ${spacings.md};
    padding-bottom: ${spacings.sm};
  }

  p{
    font-weight: 500;
    margin-bottom: 0;
    @media (max-width: ${breakpoints.small}) {
      font-size: 0.8rem;
      margin-bottom: 1rem !important;
    }
  }

  a{
    color: ${baseColours.white};
    text-decoration: underline;
    font-weight: bold;

    &:hover,
    &:focus     {
      color: ${baseColours.black};
      text-decoration: none;
    }

  }

  @media (max-width: ${breakpoints.small}) {
    h3{
      font-size: ${spacings.sm};
      margin-bottom: ${spacings.sm};
    }
  })
`;

export const termsMainContent = css`

padding: ${spacings.xl};

 @media (max-width: ${breakpoints.small}) {

  padding: ${spacings.sm};

    h1{
      font-size: ${spacings.lg};
      margin-bottom: ${spacings.sm};
    }
    h2{
      font-size: ${spacings.md};
      margin-bottom: ${spacings.sm};
    }
    h3{
      font-size: ${spacings.sm};
      margin-bottom: ${spacings.sm};
    }
  })
`;

const rowStyle = css`
  margin-top: ${spacings.sm};
  margin-bottom: calc(2 * ${spacings.xl});

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: ${spacings.xl};
  }
`;

const TermsPage = () => {
  return (
    <Page fullWidth>
      <Hero title="Terms and Conditions">
        <div className={termsHeroContent}>
          <Row>
            <Column size={8}>
              <p className={cx(spacingStyles.paddingBottom.md)}>Last updated on 3rd September 2021</p>
              <p className={cx(spacingStyles.marginRight.md)}>
                <b>
                  You must read these terms and conditions to make sure that they contain all that you want and nothing
                  that you are not happy with. If you are not sure about anything, just email us,{' '}
                  <a href="mailto:info@workfromhub.co.uk">info@workfromhub.co.uk</a>
                </b>
              </p>
            </Column>
            <Column size={4}>
              <h3>Read also</h3>
              <p>
                <Link to="/terms/privacy">Privacy Policy</Link> <br />
                <Link to="/terms/cookies">Cookie Policy</Link>
              </p>
            </Column>
          </Row>
        </div>
      </Hero>
      <Container>
        <Card className={cx(spacingStyles.marginTop.lg, termsMainContent)}>
          <h1>WORKFROMHUB</h1>
          <h2>TERMS &amp; CONDITIONS</h2>
          <p>Please read all these terms and conditions.</p>
          <p>
            You must read these terms and conditions to make sure that they contain all that you want and nothing that
            you are not happy with. If you are not sure about anything, just email us, info@workfromhub.co.uk
          </p>
        </Card>
        <Card>
          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Application</h3>
            </Column>
            <Column size={8}>
              <p>1. These Terms and Conditions will apply to the use of the services by you (the Participant).</p>
              <p>
                2. We are NOTA BENE CONSULTING LTD, a company in England and Wales under number 12621498 with its
                registered address at 19 Charnock View Road, Sheffield, South Yorkshire, S12 3HH.
              </p>
              <p>
                3. These are the terms on which we provide all Services to you. By using any of the Services, you agree
                to these Terms and Conditions.
              </p>
              <p>Interpretation</p>
              <p>
                4. Participant means an individual acting for purposes which are wholly or mainly outside their trade,
                business, craft or profession;
              </p>
              <p>5. Agreement means the agreement between you and us for the supply of the Services;</p>
              <p>
                6. Durable Medium means paper or email, or any other medium that allows information to be addressed
                personally to the participant, enables the participant to store the information in a way accessible for
                future reference for a period that is long enough for the purposes of the information, and allows the
                unchanged reproduction of the information stored;
              </p>
              <p>
                7. Hub means a private work space which can be booked using your invitation to the pilot in blocks of 4
                hours;
              </p>
              <p>
                8. Privacy Policy means the terms which set out how we will deal with confidential and personal
                information received from you via the WorkfromHub WebApp;
              </p>
              <p>9. Booking means the Participant’s Booking for the Services from the Supplier&nbsp;</p>
              <p>
                10. Services means the services, including any Goods, of the number and description set out in the
                Booking.
              </p>
              <p>11. Supplier means NOTA BENE CONSULTING LTD</p>
              <p>12. WFH WebApp means our website www.workfromhub.co.uk on which the Services are advertised.</p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Services</h3>
            </Column>
            <Column size={8}>
              <p>
                13. The Services comprise the WFH WebApp which allows users to book private, sound proof Hubs in public
                places. The service is based on the WFH WebApp, through which a user can book and access the Hubs.
              </p>
              <p>14. All Services are subject to availability.</p>
              <p>
                15. We can make changes to the Services which are necessary to comply with any applicable law or safety
                requirement. We will notify you of these changes.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>License</h3>
            </Column>
            <Column size={8}>
              <p>
                16. Subject to your compliance with these Terms, NOTA BENE CONSULTING LTD grants you a limited,
                non-exclusive, non-sublicensable, revocable, non-transferable license to: (i) access and use the
                Applications on your personal device solely in connection with your use of the Services; and (ii) access
                and use any content, information and related materials that may be made available through the Services,
                in each case solely for your personal, non-commercial use. Any rights not expressly granted herein are
                reserved by NOTA BENE CONSULTING LTD.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Cancellation</h3>
            </Column>
            <Column size={8}>
              <p>17. You can cancel your booking within 24 hours of the booking start time.</p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Door Opening</h3>
            </Column>
            <Column size={8}>
              <p>
                18. The user can open the WorkfromHub door with the use of their unique pin code. The user must enter
                the pin code.
              </p>
              <p>Participant responsibilities</p>
              <p>
                19. You must co-operate with us in all matters relating to the Services, provide us with all information
                required to perform the Services and obtain any necessary licences and consents (unless otherwise
                agreed).
              </p>
              <p>
                20. Exiting the Hub. You must leave the Hub promptly after your booking has finished and close the door
                fully. This allows the calendar of bookings to run smoothly. By closing the door fully, it ensures that
                only users who have been invited to use the Services have access to the Hub. Please do not hold the door
                open for the next person; they must open the door.
              </p>
              <p>
                21. Personal belongings. You must take personal belongings with you when you leave the Hub. If you need
                to leave the Hub during your booking, we recommend that you take your personal belongings with you. You
                must close the door and make sure you know your unique pin code as you will need this to get back into
                the hub. From the inside, the door opens without the pin code.
              </p>
              <p>
                22. Usage. Hubs are intended to be used as an office on the go, and used for meetings, calls,
                interviews, quiet work, video calls. They are not to be used for any illegal purpose. WorkfromHub
                reserves the right to ban users who break this rule and to prosecute where necessary.
              </p>
              <p>
                23. Damages. Users are responsible for any damage that occurs to a WorkfromHub Hub during their booking.
              </p>
              <p>
                24. Failure to comply with the above is a Participant default which entitles us to suspend performance
                of the Services until you remedy it or if you fail to remedy it following our request, we can terminate
                the Agreement with immediate effect on written notice to you.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Personal information and Registration</h3>
            </Column>
            <Column size={8}>
              <p>
                25. When registering to use the WFH WebApp you must set up an email and password. You remain responsible
                for all actions taken under the chosen email and password and undertake not to disclose your email and
                password to anyone else and keep them secret.
              </p>
              <p>26. We retain and use all information strictly under the Privacy Policy.</p>
              <p>
                27. We may contact you by using e-mail or other electronic communication methods and by pre-paid post
                and you expressly agree to this.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Basis of Participation</h3>
            </Column>
            <Column size={8}>
              <p>
                28. When a Booking has been made, we can reject it for any reason, although we will try to tell you the
                reason without delay.
              </p>
              <p>
                29. An Agreement will be formed for the Services Booked, only upon the Supplier sending an email to the
                Participant saying that the Booking has been confirmed.
              </p>
              <p>
                30. We intend that these Terms and Conditions apply only to an Agreement entered into by you as a
                Consumer where we, the Supplier and you the Participant, enter the Agreement at any of the Supplier’s
                business premises, and where the Agreement is not an Agreement (i) for which an offer was made by the
                Participant in the Supplier’s and the Participant’s simultaneous physical presence away from those
                premises, or (ii) made immediately after the Participant was personally and individually addressed in
                the Supplier’s and the Participant’s simultaneous physical presence away from those premises. If this is
                not the case, you must tell us, so that we can provide you with a different Agreement with terms which
                are more appropriate to you and which might, in some way, be better for you, eg by giving cancellation
                rights pursuant to consumer protection law. Business premises means immovable retail premises where we
                carry on business on a permanent basis or, in the case of movable retail premises, on a usual basis.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Privacy</h3>
            </Column>
            <Column size={8}>
              <p>
                31. Your privacy is important to us. We respect your privacy and comply with the General Data Protection
                Regulation with regard to your personal information.
              </p>
              <p>
                32. These Terms and Conditions should be read alongside, and are in addition to our policies, including
                our privacy policy and cookies policy which can be found on our WFH WebApp.
              </p>
              <p>33. For the purposes of these Terms and Conditions:</p>
              <p>
                a. ‘Data Protection Laws’ means any applicable law relating to the processing of Personal Data,
                including, but not limited to the Directive 95/46/EC (Data Protection Directive) or the GDPR.
              </p>
              <p>b. ‘GDPR’ means the General Data Protection Regulation (EU) 2016/679.</p>
              <p>c. ‘Data Controller’, ‘Personal Data’ and ‘Processing’ shall have the same meaning as in the GDPR.</p>
              <p>34. We are a Data Controller of the Personal Data we Process in providing the Services to you.</p>
              <p>
                35. Where you supply Personal Data to us so we can provide Services to you, and we Process that Personal
                Data in the course of providing the Services to you, we will comply with our obligations imposed by the
                Data Protection Laws:
              </p>
              <p>
                a. before or at the time of collecting Personal Data, we will identify the purposes for which
                information is being collected;
              </p>
              <p>b. we will only Process Personal Data for the purposes identified;</p>
              <p>c. we will respect your rights in relation to your Personal Data; and</p>
              <p>d. we will implement technical and organisational measures to ensure your Personal Data is secure.</p>
              <p>
                36. For any enquiries or complaints regarding data privacy, you can contact our Data Protection Officer
                at the following e-mail address: info@WorkfromHub.co.uk.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Circumstances beyond the control of either party</h3>
            </Column>
            <Column size={8}>
              <p>37. In the event of any failure by a party because of something beyond its reasonable control:</p>
              <p>a. the party will advise the other party as soon as reasonably practicable; and</p>
              <p>
                b. the party’s obligations will be suspended so far as is reasonable, provided that that party will act
                reasonably, and the party will not be liable for any failure which it could not reasonably avoid, but
                this will not affect the Participant’s above rights relating to delivery.
              </p>
            </Column>
          </Row>

          <Row className={rowStyle}>
            <Column size={4}>
              <h3>Excluding liability</h3>
            </Column>
            <Column size={8}>
              <p>
                38. We do not exclude liability for: (i) any fraudulent act or omission; or (ii) death or personal
                injury caused by negligence or breach of the Supplier’s other legal obligations. Subject to this, we are
                not liable for (i) loss which was not reasonably foreseeable to both parties at the time when the
                Agreement was made, or (ii) loss (eg loss of profit) to your business, trade, craft or profession which
                would not be suffered by a Consumer – because we believe you are not buying the Services and Goods
                wholly or mainly for your business, trade, craft or profession.
              </p>
              <p>Governing law, jurisdiction and complaints</p>
              <p>
                39. The Agreement (including any non-Agreementual matters) is governed by the law of England and Wales.
              </p>
              <p>
                40. Disputes can be submitted to the jurisdiction of the courts of England and Wales or, where the
                Participant lives in Scotland or Northern Ireland, in the courts of Scotland or Northern Ireland
                respectively.
              </p>
            </Column>
          </Row>
        </Card>
      </Container>
    </Page>
  );
};

export default TermsPage;
