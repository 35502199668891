import { css, cx } from '@emotion/css';
import React, { ChangeEventHandler, ComponentType, ElementType, FocusEventHandler, ReactNode } from 'react';

type Props<P> = {
  days: Date[];
  formatter: Intl.DateTimeFormat;
  rotated?: boolean;
  className?: string;
  as?: ComponentType<{ colSpan?: string | number }> | keyof JSX.IntrinsicElements;
};

export const MonthsHeader = <P extends {}>({
  days,
  formatter,
  rotated = false,
  as: Component = 'th',
  className,
}: Props<P>) => (
  <>
    {days
      .map(day => formatter.format(day))
      .reduce<{ month: string; count: number }[]>(
        ([current, ...rest], month) =>
          current?.month === month
            ? [{ ...current, count: current.count + 1 }, ...rest] // same month, increase count by one
            : [{ month, count: 1 }, current, ...rest], // different month, insert with count of one
        [],
      )
      .filter(_ => !!_) // remove empty data
      .reverse()
      .map(({ month, count }) => (
        <Component
          key={month}
          colSpan={count}
          className={cx(className, css(rotated ? `grid-row: span ${count}` : `grid-column: span ${count}`))}
        >
          {month}
        </Component>
      ))}
  </>
);
