import Button from '@admin/components/Button';
import InlineFormField from '@admin/components/form/field/InlineFormField';
import FormGroup from '@admin/components/form/FormGroup';
import FormInput from '@admin/components/form/FormInput';
import FormSelect from '@admin/components/form/FormSelect';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import { useServiceContext } from '@admin/context/ServiceContext';
import { useUserContext } from '@admin/context/UserContext';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import userService from '@admin/services/userService';
import { AccountResource } from '@common/types/apiResource';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormFieldCheckbox from '@admin/components/form/field/FormFieldCheckbox';
import FormFieldToggle from '@admin/components/form/field/FormFieldToggle';
import FormLabel from '@admin/components/form/FormLabel';
import { css, cx } from '@emotion/css';
import flexStyles from '@admin/styles/flex';
import { breakpoints } from '@admin/styles/variables';

const inputCheckboxLabel = css`
  width: 25%;
  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  })
`;

interface FormFields {
  title: string;
  firstName: string;
  surname: string;
  receiveEmails: boolean;
}

const FormSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstName: Yup.string().required('First name is required'),
  surname: Yup.string().required('Surname is required'),
});
export const PersonalDetails = () => {
  const user = useUserContext();

  const { accountService } = useServiceContext();

  const [isNewAccount, setIsNewAccount] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const {
    value: account,
    isLoading,
    setValue,
  } = useAsyncRetry<Partial<AccountResource>>(async () => {
    const result = (await accountService.find({
      query: { userId: user.user?.id },
    })) as AccountResource[];

    if (result.length === 0) {
      setIsNewAccount(true);
      setIsEditing(true);

      return {
        id: undefined,
        userId: user.user?.id,
        title: '',
        firstName: '',
        surname: '',
        userType: 'user',
      };
    }
    return (result as AccountResource[])[0];
  });

  const submitForm = React.useCallback(
    async (values: FormFields) => {
      let newAccount: AccountResource;
      if (account?.id) {
        const { title, firstName, surname } = values;
        newAccount = await accountService.patch(account.id, {
          title,
          firstName,
          surname,
        });
      } else {
        const userId = user.user?.id as string;
        const { title, firstName, surname } = values;

        newAccount = await accountService.create({
          userId,
          title,
          firstName,
          surname,
        });
      }

      if (user.user && user.user.receiveEmails !== values.receiveEmails) {
        await userService.patch(user.user.id, {
          receiveEmails: values.receiveEmails,
        });
      }

      await user.reload();

      setValue(newAccount);
    },
    [account?.id, accountService, setValue, user],
  );
  return (
    <LoadingSpinner loading={isLoading} center>
      <Formik<FormFields>
        onSubmit={submitForm}
        initialValues={{ ...account, receiveEmails: user.user?.receiveEmails } as FormFields}
        validateOnMount
        enableReinitialize
        validationSchema={FormSchema}
      >
        {form => (
          <Form>
            {isNewAccount && <h4>Please fill in your account details before continuing</h4>}

            <FormGroup>
              <p>
                <strong>All fields are required</strong>
              </p>

              <InlineFormField as={FormInput} name="title" id="title" label="Title" />
              <InlineFormField as={FormInput} name="firstName" id="firstName" label="First name" />
              <InlineFormField as={FormInput} name="surname" id="surname" label="Surname" />

              <div
                className={cx(
                  flexStyles.flex,
                  flexStyles.wrap,
                  css`
                    align-items: baseline;
                  `,
                )}
              >
                <FormLabel id="userType_label" className={inputCheckboxLabel}>
                  Marketing opt-in?
                </FormLabel>
                <FormFieldCheckbox name="receiveEmails" id="receiveEmails" label="Marketing opt-in" showLabel={false} />
                <p>
                  Please tick this box if you are happy for us to email you with future promotions, offers and communications
                </p>
              </div>
            </FormGroup>

            <FormGroup>
              <Button type="submit" disabled={!form.isValid}>
                Update details
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </LoadingSpinner>
  );
};
