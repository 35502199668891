import { useAuth0Context } from '@admin/context/Auth0Context';
import React, { useEffect, useMemo } from 'react';
import { Route, RouteProps, useLocation, useHistory } from 'react-router-dom';
import { useUserContext } from '@admin/context/UserContext';

interface ProtectedRouteProps extends RouteProps {
  requiresAdmin?: boolean;
}

const ProtectedRoute = ({
  component: Component,
  children,
  render,
  requiresAdmin = false,
  ...props
}: ProtectedRouteProps) => {
  const location = useLocation();
  const history = useHistory();

  const { isAuthenticated, isError, login, logout } = useAuth0Context();
  const { user } = useUserContext();

  const isValidUser = useMemo(() => user?.accounts && user?.accounts.length > 0, [user]);

  const isAuthorised = useMemo(() => {
    if (!isAuthenticated) return false;

    if (requiresAdmin && user?.type !== 'admin') return false;

    return true;
  }, [isAuthenticated, requiresAdmin, user]);

  useEffect(() => {
    if (isError) {
      logout({
        returnTo: window.location.origin,
      });
    } else if (!isAuthenticated) {
      login({
        appState: {
          targetUrl: `/home`,
        },
      });
    } else if (!isValidUser) {
      history.push('/loginError');
    }
  }, [history, isAuthenticated, isError, isValidUser, location.pathname, login, logout]);

  return (
    <Route
      {...props}
      render={routeProps => {
        if (Component) {
          return isAuthorised && isValidUser ? <Component {...routeProps} /> : null;
        }

        if (render) {
          return isAuthorised && isValidUser ? render(routeProps) : null;
        }

        return isAuthorised && isValidUser ? children : null;
      }}
    />
  );
};

export default ProtectedRoute;
