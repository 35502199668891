import { css } from '@emotion/css';
import { linearGradient } from 'polished';
import { Theme } from '@emotion/react';
import { baseColours } from '@admin/styles/variables';

// eslint-disable-next-line import/prefer-default-export
export const inputGradientStyle = (theme: Theme) => css`
  ${linearGradient({
    colorStops: [`${baseColours.white} 0%`, `${baseColours.greyLight} 100%`],
    fallback: theme.secondaryHighlight,
    toDirection: '100deg',
  })};
`;
