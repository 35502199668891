import Button from '@admin/components/Button';
import ButtonLink from '@admin/components/ButtonLink';
import Card from '@admin/components/Card';
import FormField from '@admin/components/form/field/FormField';
import FormGroup from '@admin/components/form/FormGroup';
import FormInput from '@admin/components/form/FormInput';
import FormTextArea from '@admin/components/form/FormTextArea';
import Column from '@admin/components/layout/Column';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import hubService from '@admin/services/hubService';
import headingStyles from '@admin/styles/headings';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { SiteSelect } from '../../bookings/components/SiteSelect';

interface FormFields {
  name: string;
  description: string;
  siteId: string;
}

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  siteId: Yup.string().required('Please select a location'),
});

interface HubData {
  siteId?: string;
}

const CreateHubPage = () => {
  const handleSubmit = async (values: FormFields) => {
    const hub = await hubService.create({
      name: values.name,
      description: values.description,
      siteId: values.siteId,
    });

    // TODO: Redirect
  };

  return (
    <Page title="Add new Hub">
      <Formik<FormFields>
        onSubmit={handleSubmit}
        initialValues={
          {
            name: '',
            description: '',
          } as FormFields
        }
        validateOnMount
        enableReinitialize
        validationSchema={FormSchema}
      >
        {form => (
          <Form>
            <Row>
              <Column size={12}>
                <FormGroup>
                  <ButtonLink to={{ pathname: '/admin/sites/' }} inverted>
                    Discard
                  </ButtonLink>
                  <Button type="submit" disabled={!form.isValid} colour="success">
                    Add
                  </Button>
                </FormGroup>
              </Column>
            </Row>
            <Row>
              <Column size={8}>
                <Card>
                  <h3>Basic Info</h3>
                  <FormGroup>
                    <FormField as={FormInput} name="name" id="name" label="Name" />
                  </FormGroup>
                  <FormGroup>
                    <SiteSelect />
                  </FormGroup>
                  <FormGroup>
                    <FormField as={FormTextArea} name="description" id="description" label="Description" />
                  </FormGroup>
                </Card>
              </Column>
              <Column size={4}>
                <Card>
                  <h3 className={headingStyles.xs}>Media</h3>
                  <p>Drag/Drop image upload</p>
                </Card>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateHubPage;
