import { Availability } from '@common/utils/bookings';
import { TooltipReference, useTooltipState } from 'reakit';
import React, { ComponentType } from 'react';
import { css, cx } from '@emotion/css';
import Button from '@admin/components/Button';
import Tooltip from '@admin/components/Tooltip';
import { formatDate } from '@admin/styles/formats';
import { baseColours } from '@admin/styles/variables';

const availableCell = css`
  padding: 0;
  width: 2rem;
  height: 2rem;

  cursor: not-allowed;
  outline: none;

  &:focus {
    outline: none;
    outline-offset: 0;
  }
`;

const isSelectedClass = css`
  border: solid ${baseColours.black} 4px !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 11px;
    left: 11px;
    width: 18px;
    height: 12px;
    transform: rotate(-45deg);
    border: solid white;
    border-width: 0 0 5px 5px;
  }
`;

type T = { [key in Availability]: string };

const availabilityClasses: T = {
  unavailable: css`
    background: ${baseColours.grey};

    &:hover {
      background: ${baseColours.grey};
    }
  `,

  booked: css`
    background: ${baseColours.red};
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 6px;
      left: 20px;
      content: '';
      height: 33px;
      width: 5px;
      background-color: ${baseColours.white};
      color: white;
    }

    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      background: red;
    }
  `,

  available: css`
    background: ${baseColours.green};
    cursor: pointer;

    &:hover {
      background: green;
    }
  `,

  currentBooking: css`
    background: ${baseColours.green};
    cursor: default;
    border: solid black 4px !important;

    &::after {
      content: '';
      position: absolute;
      top: 11px;
      left: 11px;
      width: 18px;
      height: 12px;
      transform: rotate(-45deg);
      border: solid ${baseColours.white};
      border-width: 0 0 5px 5px;
      // Fix bug in IE11 caused by transform rotate (-45deg).
      // See: alphagov/govuk_elements/issues/518
      border-top-color: transparent;
      opacity: 0;
      background: transparent;
    }

    &:hover {
      background: ${baseColours.greenLight};
    }
  `,
};

export interface AvailabilityCellProps {
  day: Date;
  availability: Availability;
  timeOfDay: string;
  isSelected?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onCellToggle?: (day: Date, timeOfDay: string, isSelected: boolean) => Promise<void>;
  tooltipText?: (availability: Availability) => React.ReactNode;
  className?: string;
  as?: ComponentType<{ colSpan?: string | number }> | keyof JSX.IntrinsicElements;
}

export const AvailabilityCell = ({
  day,
  availability,
  timeOfDay,
  isSelected = false,
  disabled = false,
  onCellToggle,
  tooltipText = undefined,
  className = '',
  as: Container = 'td',
}: AvailabilityCellProps) => {
  const tooltip = useTooltipState();

  const text = (
    <>
      <div>{formatDate.format(day)}</div>
      <div>{timeOfDay}</div>
      <div>{availability}</div>
      {tooltipText?.(availability) ?? ''}
    </>
  );

  return (
    <Container key={day.getDate()}>
      <TooltipReference
        {...tooltip}
        as="div"
        className={cx(availableCell, availabilityClasses[availability], isSelected && isSelectedClass, className)}
        onClick={() => {
          if (disabled) return;
          const newSelectedValue = !isSelected;
          if (onCellToggle) onCellToggle(day, timeOfDay, newSelectedValue);
        }}
      >
        &nbsp;
      </TooltipReference>

      <Tooltip {...tooltip}>{text}</Tooltip>
    </Container>
  );
};
