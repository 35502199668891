import React from 'react';
import FullPage from '@admin/components/layout/FullPage';
import Hero from '@admin/components/Hero';
import PublicNearbyHubsWithMap from '@admin/components/public/nearbyHubsWithMap';
import Page from '@admin/components/layout/Page';

const FindAHubPage = () => (
  <Page title="Find a Hub 🔍" fullWidth>
    <Hero title="Find a Hub" subtitle="Our hubs" />
    <PublicNearbyHubsWithMap />
  </Page>
);

export default FindAHubPage;
