import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import ErrorMessage from '@admin/components/ErrorMessage';
import FormHint from '@admin/components/form/FormHint';
import FormLabel from '@admin/components/form/FormLabel';
import flexStyles from '@admin/styles/flex';
import { inputGradientStyle } from '@admin/styles/gradients';
import { fonts, headingSizes, spacings } from '@admin/styles/variables';
import React, { ChangeEventHandler, ComponentType, FocusEventHandler, ReactNode, TextareaHTMLAttributes } from 'react';

const textAreaStyle = css`
  border-radius: 8px;
  font-family: ${fonts.body};
  line-height: 1.6;
  margin-bottom: ${spacings.md};
  outline: none;
  padding: 1.4rem;
  width: 100%;

  &::placeholder {
    font-family: ${fonts.heading};
  }
`;

const errorStyle = css`
  margin-bottom: ${spacings.sm};
`;

export interface FormTextAreaProps {
  className?: string;
  component?: ComponentType<TextareaHTMLAttributes<HTMLTextAreaElement>> | 'textarea';
  disabled?: boolean;
  error?: string;
  hint?: string;
  id: string;
  label: string;
  labelAfter?: ReactNode;
  labelSize?: keyof typeof headingSizes;
  name: string;
  placeholder?: string;
  rows?: number;
  showLabel?: boolean;
  value: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

const FormTextArea = ({
  className,
  component: Component = 'textarea',
  disabled = false,
  error,
  hint,
  id,
  label,
  labelAfter,
  labelSize,
  name,
  onBlur,
  onChange,
  placeholder,
  rows = 6,
  showLabel = true,
  value = '',
}: FormTextAreaProps) => {
  const theme = useTheme();
  const themedTextAreaStyle = css`
    ${textAreaStyle};
    background-color: ${theme.background1};
    border: 1px solid ${theme.secondary};
    color: ${theme.inputColor};

    &:not(:empty) {
      ${inputGradientStyle(theme)};
    }

    &::placeholder {
      color: ${theme.text2};
    }

    &:active,
    &:focus {
      border: 1px solid ${theme.primary};
    }
  `;

  const themedErrorStyle = css`
    ${errorStyle}
    &:not(:focus) {
      border: 1px solid ${theme.error};
    }
  `;

  return (
    <>
      <div className={cx(flexStyles.flex, flexStyles.justifySpaceBetween)}>
        <FormLabel id={id} show={showLabel} size={labelSize}>
          {label}
        </FormLabel>

        {labelAfter}
      </div>

      {hint && <FormHint id={`${id}-hint`}>{hint}</FormHint>}

      <Component
        aria-describedby={cx(error && `${id}-error`, hint && `${id}-hint`)}
        rows={rows}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        className={cx(themedTextAreaStyle, className, !!error && themedErrorStyle)}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormTextArea;
