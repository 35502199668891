import React from 'react';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { HubResource, SiteResource } from '@common/types/apiResource';
import headingStyles from '@admin/styles/headings';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import { Link, useParams } from 'react-router-dom';
import { useServiceContext } from '@admin/context/ServiceContext';
import Map from '@admin/components/Map';
import { LatLngExpression } from 'leaflet';
import {Marker, Popup} from "react-leaflet";
import { widthStyles } from '@admin/styles/utils';
import { css, cx } from '@emotion/css';
import { breakpoints, spacings } from '@admin/styles/variables';
import Card from '../../../../components/Card';
import ButtonLink from '../../../../components/ButtonLink';
import Button from '../../../../components/Button';
import FormGroup from '../../../../components/form/FormGroup';
import StyledTable from '../../../../components/StyledTable';
import { DeleteModalButton } from '@admin/components/DeleteModalButton';
import FormToggle from '@admin/components/form/FormToggle';
import spacingStyles from '@admin/styles/spacings';

const gridClass = css`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, minmax(70px, 1fr));
grid-gap: 0.6rem;
margin: 1rem 0 2rem;
max-height: 60vh;

@media (max-device-width: ${breakpoints.small}) and (orientation: portrait) {
  max-height: 30vh;
`;

const image = css`
width: 100%;
height: 100%;
object-fit: cover;


:first-child {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
`;

interface Props {
  siteId: string;
}
interface RouteParams {
  siteId: string;
}

const Site = ({ siteId }: Props) => {
  const { siteService } = useServiceContext();

  const params = useParams<RouteParams>();
  siteId = siteId || params.siteId;

  const { value: site, isLoading } = useAsyncRetry<SiteResource>(async () => {
    const result = await siteService.get(siteId, {
      query: {
        $eager: '[hubs,address]',
      },
    });

    return result as SiteResource;
  });

  return (
    <>
      {!isLoading && site !== undefined && (
        <>
          {/* <Row>
        <Column size={12} >
          <FormGroup>
            <Button>Edit</Button>
          </FormGroup>
        </Column>
      </Row> */}
          <Row>
        <Column size={4} className={cx(spacingStyles.marginRight.md)}>
              <Card>
                <h2 className={headingStyles.sm}>{site.name}</h2>
                <p>{site.description}</p>

            <dl>
              <dt>Site ID</dt>
              <dd>ID-{site?.id}</dd>

              <dt>Status</dt>
              <dd>{site.active ? 'Active' : 'Disabled'}</dd>
            </dl>
              </Card>

              <Card>
                <h3 className={headingStyles.xs}>Media</h3>
            <div className={gridClass}>
              <img
                className={image}
                src={`/media/sites/${site?.id}/image_0.jpg`}
                alt={site?.name} />
              <img
                className={image}
                src={`/media/sites/${site?.id}/image_1.jpg`}
                alt={site?.name} />
              <img
                className={image}
                src={`/media/sites/${site?.id}/image_2.jpg`}
                alt={site?.name} />
            </div>
              </Card>

              <Card>
                <h3 className={headingStyles.xs}>Location</h3>
            {(site.address?.latitude && site.address?.longitude) &&
              <Map center={[site.address.latitude, site.address.longitude]} height={200} zoomLevel={15} >
                <Marker position={[site.address.latitude, site.address.longitude]}>
                  <Popup>
                    <p><b>{site.name}</b></p>
                  </Popup>
                </Marker>
              </Map>
            }
                <p>
                  <address>
                    {site.address?.lines} <br />
                    {site.address?.postcode} <br />
                    {site.address?.county} <br />
                    {site.address?.country}
                  </address>
                </p>
              </Card>

          {/* <Card>
                <DeleteModalButton
                  onConfirm={() => {}}
                  onCancel={() => {}}
                  buttonText="Delete Site"
                  bodyText={
                    <>
                      <p>Are you sure you want to delete the site?</p>
                    </>
                  }
                />
          </Card> */}
        </Column>
        <Column>
          <Card>
            <h3 className={headingStyles.xs}>Hubs</h3>

            {(site.hubs !== undefined && site.hubs.length > 0) ?
              <StyledTable<HubResource>
                titles={
                  {
                    "name": "Name",
                    "status": "Status",
                    "actions": "Actions",
                  }
                }

                mapper={
                  {
                    name: (hub) => (<Link to={{ pathname: `/admin/hubs/${hub.id}` }}>
                      {hub.name}
                    </Link>),
                    status: (hub) => hub.active ? 'Active' : 'Disabled',
                    actions: (hub) => (<></>),
                  }
                }

                rows={site.hubs}
                idKey="id"
              />
              :
              <p>The site currently has no hubs.</p>
            }

            {/* <ButtonLink to={{ pathname: "/admin/hubs/create" }} colour='primaryAlternate' className={cx(widthStyles.full)}>
              Add Hub
            </ButtonLink> */}
              </Card>


            </Column>
          </Row>
        </>
      )}
    </>
  );
};
export default Site;
