import Page from '@admin/components/layout/Page';
import Site from '@admin/pages/admin/sites/components/Site';
import useQuery from '@admin/hooks/useQuery';
import React from 'react';

interface Props {}

interface Params {
  siteId: string;
}

const SitePage = (_: Props) => {
  const query = useQuery();

  const siteId = query.site;

  return <Page title="Site Details">{siteId !== null && <Site siteId={siteId} />}</Page>;
};

export default SitePage;
