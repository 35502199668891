import { Route, Switch } from 'react-router-dom';
import HomePage from '@admin/pages/homepage/homepage';
import AccountsPage from '@admin/pages/admin/accounts/accountsPage';
import BookingsPage from '@admin/pages/bookings/bookingsPage';
import LandingPage from '@admin/pages/LandingPage';
import ProtectedRoute from '@admin/components/ProtectedRoute';
import React from 'react';
import MyBookingPage from '@admin/pages/public/bookings/myBookingPage';
import HubsPage from './pages/admin/hubs/hubs';
import HubPage from './pages/admin/hubs/hub';
import SitesPage from '@admin/pages/admin/sites/sites';
import SitePage from '@admin/pages/admin/sites/site';
import CreateSitePage from './pages/admin/sites/create';
import CreateHubPage from './pages/admin/hubs/create';
import FindAHubPage from './pages/public/find-a-hub/findAHubPage';
import HelpPage from './pages/public/help/help';
import AccountSettingsPage from './pages/public/account/account';
import MyBookingsPage from './pages/public/bookings/myBookingsPage';
import FindAHubDetailsPage from './pages/public/find-a-hub/details';
import AdminPage from './pages/admin/admin';
import UsersPage from './pages/admin/users/users';
import BookingPage from './pages/bookings/bookingPage';
import PublicHubPage from '@admin/pages/public/hub/hub';
import ViewBookingPage from './pages/public/view-booking/viewBooking';
import CookiesPage from './pages/terms/cookie';
import TermsPage from './pages/terms/terms';
import PrivacyPage from './pages/terms/privacy';
import NotFound from './pages/error/notFound';
import FindAHubFacilitiesPage from './pages/public/find-a-hub/facilities';
import LoginError from '@admin/pages/error/loginError';
import AccountPage from './pages/admin/accounts/accountPage';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import SettingsPage from './pages/admin/settings/settingsPage';

const Routes = () => {
  useGoogleAnalytics();

  return (<Switch>

    <Route exact path="/" component={LandingPage} />
    <Route exact path="/terms/privacy" component={PrivacyPage} />
    <Route exact path="/terms/cookies" component={CookiesPage} />
    <Route exact path="/terms" component={TermsPage} />

    <Route exact path="/loginError" component={LoginError} />

    <ProtectedRoute path="/home" component={HomePage} />

    <ProtectedRoute path="/public/find-a-hub/:id/facilities" component={FindAHubFacilitiesPage} exact />
    <ProtectedRoute path="/public/find-a-hub/:id" component={FindAHubDetailsPage} exact />
    <ProtectedRoute path="/public/find-a-hub" component={FindAHubPage} />
    <ProtectedRoute path="/public/bookings" component={MyBookingsPage} exact />
    <ProtectedRoute path="/public/bookings/:bookingId" component={MyBookingPage} exact />
    <ProtectedRoute path="/public/account" component={AccountSettingsPage} />
    <ProtectedRoute path="/public/help" component={HelpPage} />
    <ProtectedRoute path="/public/hub/:id" component={PublicHubPage} />
    <ProtectedRoute path="/public/view-booking/:id" component={ViewBookingPage} exact />

    <ProtectedRoute requiresAdmin path="/admin/accounts/:accountId/bookings" component={BookingsPage} />
    <ProtectedRoute requiresAdmin path="/admin/accounts/:accountId" component={AccountPage} />
    <ProtectedRoute requiresAdmin path="/admin/accounts" component={AccountsPage} />

    <ProtectedRoute requiresAdmin path="/admin/bookings/:bookingId" component={BookingPage} />

    <ProtectedRoute requiresAdmin path="/admin/sites/create" component={CreateSitePage} />
    <ProtectedRoute requiresAdmin path="/admin/sites/:siteId" component={SitePage} />
    <ProtectedRoute requiresAdmin path="/admin/sites" component={SitesPage} />

    <ProtectedRoute requiresAdmin path="/admin/hubs/create" component={CreateHubPage} />
    <ProtectedRoute requiresAdmin path="/admin/hubs/:hubId" component={HubPage} />
    <ProtectedRoute requiresAdmin path="/admin/hubs" component={HubsPage} />

    <ProtectedRoute requiresAdmin path="/admin/users" component={UsersPage} />

    <ProtectedRoute requiresAdmin path="/admin/settings" component={SettingsPage} />

    <ProtectedRoute requiresAdmin path="/admin" component={AdminPage} />

    <Route path="*" component={NotFound} />

  </Switch>)
};

export default Routes;
