import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { AccountResource, BookedSlotResource, BookingResource } from '@common/types/apiResource';
import { Paginated } from '@feathersjs/feathers';
import usePaginationQuery from '@admin/hooks/usePaginationQuery';
import ButtonLink from '@admin/components/ButtonLink';
import EditableBookingList from '@admin/components/bookings/EditableBookingList';
import { useServiceContext } from '@admin/context/ServiceContext';
import { useUserContext } from '@admin/context/UserContext';
import Card from '@admin/components/Card';
import BookingList from '@admin/components/bookings/BookingList';
import StyledTable from '@admin/components/StyledTable';
import { DateTime } from '@admin/components/DateTime';
import { formatDate, formatTime } from '@common/formats';
import { parseDateString } from '@common/utils/dateFunctions';

interface Props { }

const MyPastBookings = (_: Props) => {
  const user = useUserContext();
  const accountId = user.user?.accounts?.[0]?.id;

  const { accountService, bookedSlotService } = useServiceContext();

  const { $limit, $skip } = usePaginationQuery({
    limit: 5,
  });

  const accountResource = useAsyncRetry<AccountResource | undefined>(async () => {
    return user.user?.accounts?.[0];
  }, [user]);

  const bookings = useAsyncRetry<BookedSlotResource[] | undefined>(async () => {
    const result = await bookedSlotService.find({
      query: {
        end: { $lte: new Date() },
        $sort: { start: -1 },
        $eager: '[booking, hub, hub.site, hub.images]',
        // @ts-ignore
        'booking.accountId': accountId,
        $joinRelation: 'booking',
      },
    });

    return result as BookedSlotResource[];
  }, [$limit, $skip, accountId]);

  return (
    <>
      {bookings.value && bookings.value.length > 0 && (
        <Card>
          <h3>Past Bookings</h3>
          <p>SESSIONS BOOKED {bookings.value?.length}</p>
          <StyledTable<BookedSlotResource>
            titles={{
              id: 'Booking Reference',
              time: 'Session Time',
              hub: 'Hub',
              status: 'Status',
              view: '',
            }}
            mapper={{
              time: slot => <>{formatDate.format(slot.start)} <b>{formatTime.format(slot.start)}-{formatTime.format(slot.end)}</b></>,
              hub: slot => <Link to={{ pathname: `/public/hub/${slot?.hub?.id}/` }}>
                {slot?.hub?.name}
              </Link>,
              status:  slot => <>{slot.status}</>,
              view: slot => <ButtonLink to={{ pathname: `/public/view-booking/${slot.id}` }}>View booking</ButtonLink>,
            }}
            rows={bookings.value}
            idKey="id"
          />
        </Card>
      )}
    </>
  );
};

export default MyPastBookings;
