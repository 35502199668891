import React from 'react';
import { css } from '@emotion/css';
import Login from '@admin/components/Login';
import { useUserContext } from '@admin/context/UserContext';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { useMaxWidthMedia } from '@admin/hooks/useMedia';

const pageStyle = css`
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
  display: flex;
  padding: 0.4rem 0.8rem;

  background-color: ${baseColours.white};
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    justify-content: start;
    background: ${baseColours.greyLight};
    padding: 0.2rem 1rem 0.2rem 0.5rem;
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  })

`;

export const navLoginLink = css`
  padding-right: 1rem;
  line-height: 1.2rem;

  @media (max-width: ${breakpoints.small}) {
    order: 2;
    font-size: calc(2*(${spacings.xs}));
    padding-right: 0;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  })
`;

export const navAvatarContainer = css`
  padding: 0 0.5rem;

  @media (max-width: ${breakpoints.small}) {
    order: 1;
  })
`;

export const navLoginContainer = css`
  @media (max-width: ${breakpoints.small}) {
    order: 3;
    font-size: calc(2*(${spacings.xs}));
    margin-left: auto;
  })
`;

const UserHeader = () => {
  const user = useUserContext();

  return (
    <div className={pageStyle}>
      <div className={navAvatarContainer}>
        <Avatar email={user.user?.emailAddress} round size="35px" />
      </div>
      <Link to="/public/account" className={navLoginLink}>
        {user.user?.accounts && user.user?.accounts.length > 0 && (
          <>
            <p>
              <strong>
                {user.user?.accounts[0].firstName} {user.user?.accounts[0].surname}
              </strong>
            </p>
          </>
        )}
        {user.user?.emailAddress}
      </Link>
      <div className={navLoginContainer}>
        <Login />
      </div>
    </div>
  );
};

export default UserHeader;
