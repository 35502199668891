import React from 'react';
import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { BookedSlotResource } from "@common/types/apiResource";
import LoadingSpinner from "@admin/components/LoadingSpinner";
import StyledTable from "@admin/components/StyledTable";
import { useServiceContext } from "@admin/context/ServiceContext";
import {formatDate, formatTime} from '@common/formats';
import { parseDateString } from '@common/utils/dateFunctions';
import ButtonLink from '@admin/components/ButtonLink';
import Link from '@admin/components/Link';

interface Props {
    hubId: string;

}


const HubUpcomingBookingsTable = ({ hubId }: Props) => {

    const { bookedSlotService } = useServiceContext();

    const { value: bookedSlots, isLoading } = useAsyncRetry<BookedSlotResource[]>(async () => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const result = await bookedSlotService.find({
            query: {
                $sort: { start: -1 },
                $eager: "[booking.account, hub.site]",
                // @ts-ignore
                'hubId': hubId,
                $joinRelation: 'booking',
            }
        });
        return result as BookedSlotResource[];
    })


    return <LoadingSpinner loading={isLoading}>

        {bookedSlots && bookedSlots?.length > 0 ?
            <>
                <StyledTable<BookedSlotResource>
                    titles={
                        {
                            "id": "Id",
                            "time": "Session Time",
                            "account": "Account",
                            "status": "Status",
                            "date": "Booked On",
                            "passcode": "Passcode",
                            "actions": "Actions"
                        }
                    }

                    mapper={
                        {
                            "id": slot => <>{slot.id}</>,
                            "passcode": slot => <>{slot.passcodeId ? "🔐" : ""}</>,
                            "time": slot => <>{formatDate.format(slot.start)} <b>{formatTime.format(slot.start)}-{formatTime.format(slot.end)}</b></>,
                            "date": slot => `${formatDate.format(parseDateString(slot.booking?.createdAt || ''))}`,
                            "account": slot =>  <Link to={{ pathname: `/admin/accounts/${slot?.booking?.account?.id}/` }}>
                            {slot?.booking?.account?.firstName} {slot?.booking?.account?.surname}
                          </Link>,
                            actions: slot => <></>
                        }
                    }

                    rows={bookedSlots}
                    idKey="id"
                />
            </>
            :
            <>
                {!isLoading ? <p>This hub has no bookings</p> : null}
            </>
        }
    </LoadingSpinner>

        ;
};

export default HubUpcomingBookingsTable;
