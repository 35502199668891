"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var maximumFileSize = {
  image: 3000000,
  video: 25000000,
  document: 3000000
};
var _default = maximumFileSize;
exports.default = _default;