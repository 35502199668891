import { OmitStrict } from '@common/types/util';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import FormCheckbox, { FormCheckboxProps } from '../FormCheckbox';

type Props = OmitStrict<FormCheckboxProps, 'checked' | 'value' | 'onChange'>;

const FormFieldCheckbox = <Values extends { [K in keyof Values]: boolean | string[] }>({
  name,
  value = 'true',
  ...props
}: Props & { value?: string; name: keyof Values }) => {
  const form = useFormikContext<Values>();

  const helper = form.getFieldHelpers(name);

  const handleChange: FormCheckboxProps['onChange'] = useCallback(
    checked => {
      if (Array.isArray(form.values[name])) {
        const formikValue = (form.values[name] as string[]) || [];

        const index = formikValue.indexOf(value);
        let values: string[];

        if (index === -1) {
          values = [...formikValue, value];
        } else {
          values = [...formikValue];
          values.splice(index, 1);
        }

        form.setFieldValue(name, values);
      } else {
        form.setFieldValue(name, checked);
      }
    },
    [form, name, value],
  );

  let errorMessage = '';

  if (form.touched[name]) {
    const errors = form.errors[name];

    errorMessage = Array.isArray(errors) ? (errors[0] as string) : (errors as string);
  }

  return (
    <>
      <FormCheckbox
        {...props}
        checked={value !== 'true' ? (form.values[name] as string[]).includes(value) : (form.values[name] as boolean)}
        value={value}
        name={name}
        error={errorMessage}
        onChange={handleChange}
      />
    </>
  );
};

export default FormFieldCheckbox;
