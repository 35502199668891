import { Form, Formik } from 'formik';
import React from 'react';
import FormField from '@admin/components/form/field/FormField';
import FormInput from '@admin/components/form/FormInput';
import FormGroup from '@admin/components/form/FormGroup';
import Button from '@admin/components/Button';
import headingStyles from '@admin/styles/headings';

interface Props {
  onSubmit: (userData: FormFields) => void;
}

interface FormFields {
  emailAddress: string;
}

const UserForm = ({ onSubmit }: Props) => {
  return (
    <>
      <Formik<FormFields>
        initialValues={{
          emailAddress: '',
        }}
        onSubmit={async (values, formikHelpers) => {
          await onSubmit(values);
          formikHelpers.resetForm();
        }}
      >
        <Form>
          <h3 className={headingStyles.md}>Create User</h3>
          <p>Note: Creating a new user will automatically email and invite them to use the platform</p>
          <p>You resend the confirmation when you edit the user</p>
          <FormGroup>
            <FormField as={FormInput} type="email" id="emailAddress" name="emailAddress" label="Email Address" />
          </FormGroup>
          <Button type="submit">Add user</Button>
        </Form>
      </Formik>
    </>
  );
};

export default UserForm;
