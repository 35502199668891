import Page from '@admin/components/layout/Page';
import Column from '@admin/components/layout/Column';
import Row from '@admin/components/layout/Row';
import {useUserContext} from '@admin/context/UserContext';
import Card from '@admin/components/Card';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import AccountUsageStats from '@admin/pages/admin/stats/AccountUsageStats';
import {useServiceContext} from "@admin/context/ServiceContext";
import Button from '@admin/components/Button';

const AdminPage = () => {
  const user = useUserContext();

  const {authorisationService} = useServiceContext();

  return (
    <Page title="Stats">
      <Row>
        <Column size={12}>
          <Card>
            <AccountUsageStats />
          </Card>
        </Column>
      </Row>
    </Page>
  );
};

export default AdminPage;
