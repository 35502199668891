import { darken, lighten, modularScale } from 'polished';

export const breakpoints = {
  tablet: '440px',
  small: '768px',
  desktop: '1024px',
};

export const baseColours = {
  greyWhite: '#FAFAFA',
  greyLight: '#DEDEDE',
  grey: '#BEBEBE',
  greyDark: '#707070',
  white: '#FFF',
  black: '#242425',
  green: '#4DA167',
  greenLight: lighten(0.05, '#4DA167'),
  blueLight: lighten(0.1, '#5680BA'),
  blue: '#5680BA',
  blueDark: '#233567',
  pinkLight: '#FFDFDF',
  pink: '#FBC1BC',
  red: '#EB4D4D',
  orange: '#E59B5B',
  teal: darken(0.12, '#0DB9C3'),
};

export const shadow = '#00000044';

export const fonts = {
  heading: 'League Spartan, sans-serif',
  body: 'Open Sans, sans-serif',
};

export const fontWeights = {
  bold: 700,
  semiBold: 600,
  regular: 400,
  light: 300,
};

export const headingSizes = {
  xl: modularScale(5, '1rem', 1.25),
  lg: modularScale(4, '1rem', 1.25),
  md: modularScale(3, '1rem', 1.25),
  sm: modularScale(2, '1rem', 1.25),
  xs: modularScale(1, '1rem', 1.25),
  xxs: '1rem',
};

export const spacings = {
  xl: '2.8rem',
  lg: '2.2rem',
  md: '1.6rem',
  sm: '1.0rem',
  xs: '0.4rem',
};
