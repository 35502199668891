import { HubResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import Button from '@admin/components/Button';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import { widthStyles } from '@admin/styles/utils';
import { css, cx } from '@emotion/css';
import spacingStyles from '@admin/styles/spacings';
import React from 'react';
import { formatWeekShort } from '@admin/styles/formats';
import { HubAvailabilityAsync } from '@admin/pages/bookings/components/HubAvailabilityAsync';
import ButtonLink from '@admin/components/ButtonLink';
import Card from '@admin/components/Card';
import Link from '@admin/components/Link';
import { breakpoints } from '@admin/styles/variables';

interface Props {
  siteId: string;
  hubList?: HubResource[];
}

const cellClass = css`
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
`;

const hubContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const hubImage = css`
  flex-basis: 30%;
  flex-shrink: 1;
  flex-grow: 1;

  img {
    width: 100%;
    border-radius: 10px;

    @media (max-device-width: ${breakpoints.small}) and (orientation: portrait) {
      margin: -1rem;
      width: calc(100% + 2rem);
      border-radius: 10px 10px 0 0;
    }

    @media (min-device-width: ${breakpoints.small}) and (orientation: portrait) {
      margin: 0;
      border-radius: 10px;
      width: 100%;
    }
  }
`;

const hubDetails = css`
  flex-basis: 70%;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 1rem;
  min-width: 270px;
  @media (max-device-width: ${breakpoints.small}) and (orientation: portrait) {
    padding: 2rem 0 0 0;
  }
  @media (max-device-width: ${breakpoints.small}) and (orientation: landscape) {
    padding: 0 0 0 1rem;
  }
  @media (min-device-width: ${breakpoints.small}) and (orientation: portrait) {
    padding: 0 0 0 1rem;
  }
`;

const HubsList = ({ siteId, hubList }: Props) => (
  <LoadingSpinner loading={hubList === undefined}>
    {hubList !== undefined &&
      hubList?.map(hub => (
        <Card key={`${hub.id}`}>
          <div className={hubContainer}>
            <div className={hubImage}>
              <Link to={`/public/hub/${hub.id}`}>
                <img src={hub.images?.[0]?.url} alt={hub.images?.[0]?.title} />
              </Link>
            </div>
            <div className={hubDetails}>
              <h4>{hub.name}</h4>
              <p>{hub.overview}</p>
              <ButtonLink colour="primaryAlternate" to={`/public/hub/${hub.id}`}>
                View and Book Now
              </ButtonLink>
            </div>
            {/* <div>
            <HubAvailabilityAsync hubId={hub.id} cellProps={{className: cellClass}} formatWeek={formatWeekShort} />
            <HubAvailability hubId={hub.id}/>
          </div> */}
          </div>
        </Card>
      ))}
  </LoadingSpinner>
);
export default HubsList;
