import { baseColours, breakpoints, fontWeights, headingSizes, spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';
import Container from './layout/Container';

interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}
const heroStyle = css`
  background-color: ${baseColours.teal};
  padding: ${spacings.xl} ${spacings.sm} ${spacings.xl};
  margin-top: 3rem;

  @media (max-width: ${breakpoints.small}) {
    margin-top: 5.2rem;
  }
`;

const titleStyle = css`
  color: ${baseColours.white};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px ${baseColours.greyDark};
  line-height: 1.2;
  @media (max-width: ${breakpoints.small}) {
    font-size: 1.8rem;
  }
`;

const subtitleStyle = css`
  color: ${baseColours.white};
  font-size: ${headingSizes.sm};
  line-height: 1.2;
  @media (max-width: ${breakpoints.small}) {
    font-size: 1.2rem;
  }
`;

const Hero = ({ title, subtitle, children }: Props) => (
  <div className={heroStyle}>
    <Container>
      {title && <h2 className={titleStyle}>{title}</h2>}
      {subtitle && <p className={subtitleStyle}>{subtitle}</p>}
      {children}
    </Container>
  </div>
);

export default Hero;
