import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { HubResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import { Link } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import { breakpoints, spacings } from '@admin/styles/variables';
import { textStyles, widthStyles } from '@admin/styles/utils';
import React from 'react';
import { useServiceContext } from '@admin/context/ServiceContext';
import Card from '../Card';
import ButtonLink from '../ButtonLink';
import { RecommendAHub } from '@admin/components/public/recommendAHub';

export const hubGrid = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 1rem;
  flex: 1 1 100%;
  justify-items: stretch;

  @media (max-width: ${breakpoints.small}) {
    margin: 0;
  }
`;

export const hubTitle = css`

    margin-top:  ${spacings.sm};

    @media (max-width: ${breakpoints.small}) {
        font-size: calc(${spacings.sm} * 0.8);
    })
`;

export const hubText = css`
    @media (max-width: ${breakpoints.small}) {
        font-size: calc(${spacings.sm} * 0.8);
    })
`;

export const cardImg = css`

    width: 100%;

    @media (max-width: ${breakpoints.small}) {
        margin: -${spacings.sm} -${spacings.sm} 0 -${spacings.sm};
        width: calc(100% + 2*(${spacings.sm}));
        border-radius: 10px 10px 0 0;
    })
`;

export const cardLink = css`
  color: black;

  &:hover,
  &:focus {
    color: black;
  }
`;

export const extraCard = css`

    margin-top: ${spacings.md};

    @media (max-width: ${breakpoints.small}) {
        grid-column: span 2;
        margin-top: ${spacings.sm};
    })
`;

const PublicNearbyHubs = () => {
  const { hubService } = useServiceContext();

  const { value: hubs, isLoading } = useAsyncRetry<HubResource[]>(async () => {
    const result = await hubService.find({
      query: {
        $eager: '[site, images]',
      },
    });

    return result as HubResource[];
  });

  return (
    <>
      <LoadingSpinner loading={isLoading}>
        {hubs !== undefined && (
          <div className={hubGrid}>
            {hubs?.map(hub => (
              <Card key={`${hub.id}`} className={css(`margin-bottom: 0;`)}>
                <Link className={cardLink} to={{ pathname: `/public/find-a-hub/${hub.siteId}` }} key={`${hub.id}`}>
                  <img className={cardImg} src={hub.images?.[0]?.url} alt={hub.images?.[0]?.title} />
                  <h4 className={hubTitle}>
                    {hub.site?.name} - {hub.name}
                  </h4>
                  {/* <p className={hubText}>{hub.overview}</p> */}
                  {/* <p className={hubText}>
                    {hub.site?.address?.lines} {hub.site?.address?.postcode}
                  </p> */}
                </Link>
              </Card>
            ))}

            <Card className={extraCard}>
              <h4 className={cx([hubTitle, textStyles.center])}>Can't find a hub?</h4>
              <p className={cx([hubText, textStyles.center])}>Explore our locations and hubs</p>
              <ButtonLink to={{ pathname: `/public/find-a-hub/` }} className={cx(widthStyles.full)}>
                Find a Hub
              </ButtonLink>
            </Card>
            <RecommendAHub />
          </div>
        )}
      </LoadingSpinner>
    </>
  );
};

export default PublicNearbyHubs;
function componentDidMount() {
  throw new Error('Function not implemented.');
}
