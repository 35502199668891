import { HelpResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import spacingStyles from '@admin/styles/spacings';
import React from 'react';
import helpService from '@admin/services/helpService';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { cx } from '@emotion/css';
import Card from '@admin/components/Card';
import ReactMarkdown from 'react-markdown';
import { noUpcomingContainer } from '../../bookings/components/myUpcomingSlots';

const ArticleList = () => {
  const { value: articleList, isLoading } = useAsyncRetry<HelpResource[]>(async () => {
    const result = await helpService.find();

    return result as HelpResource[];
  });

  return (
    <div className={cx(spacingStyles.marginTop.lg)}>
      <LoadingSpinner loading={isLoading}>
        {articleList !== undefined && articleList.length > 0 ? (
          articleList?.map(article => (
            <Row key={`${article.id}`}>
              <Column size={12}>
                <Card>
                  <h3>{article.title}</h3>
                  <ReactMarkdown>{article.content}</ReactMarkdown>
                </Card>
              </Column>
            </Row>
          ))
        ) : (
          <div className={noUpcomingContainer}>
            <h3>Coming soon</h3>
            <p>Our help and support articles are coming soon.</p>
            <p>
              Please email <a href="mailto:info@workfromhub.co.uk">info@workfromhub.co.uk</a> if you have any issues.
            </p>
          </div>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default ArticleList;
