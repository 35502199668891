import React from 'react';
import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { BookedSlotResource } from "@common/types/apiResource";
import LoadingSpinner from "@admin/components/LoadingSpinner";
import StyledTable from "@admin/components/StyledTable";
import { useServiceContext } from "@admin/context/ServiceContext";
import { formatDate, formatTime } from '@common/formats';
import { parseDateString } from '@common/utils/dateFunctions';
import ButtonLink from '@admin/components/ButtonLink';
import Link from '@admin/components/Link';

interface Props {
    accountId: string;

}


const AccountBookingsTable = ({ accountId }: Props) => {

    const { bookedSlotService } = useServiceContext();

    const { value: bookedSlots, isLoading } = useAsyncRetry<BookedSlotResource[]>(async () => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const result = await bookedSlotService.find({
            query: {
                $sort: { start: -1 },
                $eager: "[booking, hub.site]",
                // @ts-ignore
                'booking.accountId': accountId,
                $joinRelation: 'booking',
            }
        });
        return result as BookedSlotResource[];
    })


    return <LoadingSpinner loading={isLoading}>

        {bookedSlots && bookedSlots?.length > 0 ?
            <>
                <StyledTable<BookedSlotResource>
                    titles={
                        {
                            "id": "Id",
                            "time": "Session Time",
                            "hub": "Hub",
                            "status": "Status",
                            "date": "Booked On",
                            "passcode": "Passcode",
                            "actions": "Actions"
                        }
                    }

                    mapper={
                        {
                            "id": slot => <>{slot.id}</>,
                            "passcode": slot => <>{slot.passcodeId ? "🔐" : ""}</>,
                            "time": slot => <>{formatDate.format(slot.start)} <b>{formatTime.format(slot.start)}-{formatTime.format(slot.end)}</b></>,
                            "date": slot => `${formatDate.format(parseDateString(slot.booking?.createdAt || ''))}`,
                            "hub": slot => <Link to={{ pathname: `/admin/hubs/${slot?.hub?.id}/` }}>
                                {slot?.hub?.name}
                            </Link>,
                            actions: slot => <></>
                        }
                    }

                    rows={bookedSlots}
                    idKey="id"
                />
                <p>Total sessions booked <b>{bookedSlots.length}</b></p>
            </>
            :
            <>
                {!isLoading ? <p>This account has no bookings</p> : null}
            </>
        }
    </LoadingSpinner>

        ;
};

export default AccountBookingsTable;
