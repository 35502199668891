import Page from "@admin/components/layout/Page";
import React from 'react';
import Card from "@admin/components/Card";
import Column from "@admin/components/layout/Column";
import Row from "@admin/components/layout/Row";
import Avatar from "react-avatar";
import Button from "@admin/components/Button";
import { useParams } from "react-router";
import { useServiceContext } from "@admin/context/ServiceContext";
import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { AccountResource } from "@common/types/apiResource";
import Link from "@admin/components/Link";
import AccountBookingsTable from "./components/accountBookingsTable";
import { cx } from "@emotion/css";
import spacingStyles from "@admin/styles/spacings";
import headingStyles from "@admin/styles/headings";

interface Props {
    accountId: string;
}

interface RouteParams {
    accountId: string
}

const AccountPage = ({ accountId }: Props) => {

    const { accountService } = useServiceContext();

    const params = useParams<RouteParams>();
    accountId = accountId || params.accountId;

    const { value: account, isLoading } = useAsyncRetry<AccountResource>(async () => {
        const result = await accountService.get(accountId, {
            query: {
                $eager: "[user]"
            }
        });

        return result as AccountResource;
    })


    return <Page
        title="Account Details"
    >
        <Row>
            <Column size={4} className={cx(spacingStyles.marginRight.md)}>
                <Card>
                    <div>
                        <Avatar email={account?.user?.emailAddress} round />
                        <h2 className={headingStyles.sm}>{account?.firstName} {account?.surname}</h2>
                    </div>

                    <dl>
                        <dt>Account ID</dt>
                        <dd>ID-{account?.id}</dd>

                        <dt>Email</dt>
                        <dd>
                            <a href={`mailto:${account?.user?.emailAddress}`}>{account?.user?.emailAddress}</a>
                        </dd>

                        <dt>Account Type</dt>
                        <dd>{account?.user?.type}</dd>

                        <dt>Auth0 ID</dt>
                        <dd>{account?.user?.auth0Id}</dd>
                        {/* <dt>Address</dt>
                    <dd>101 Collin Street,
                        Melbourne 3000 VIC
                        Australia</dd>

                    <dt>Last Login</dt>
                    <dd>22 Sep 2021, 11:30 am</dd> */}

                    </dl>
                </Card>
                <Card>
                    <h3 className={headingStyles.xs}>Communication Preferences</h3>
                    <p>Marketing opt-in <b>{account?.user?.receiveEmails ? `Yes` : `No`}</b></p>
                </Card>
                <Card>
                    <h3 className={headingStyles.xs}>Credit Balance</h3>
                    <p><b>{account?.creditsRemaining}</b> Credits</p>
                    <p><i>Credits are refunded if a booking is cancelled.</i></p>
                    {/* <Button>Add Credits</Button> */}
                </Card>

            </Column>
            <Column>
                <Card>
                    <h3 className={headingStyles.xs}>Bookings</h3>
                    {account &&
                        <AccountBookingsTable accountId={account.id} />
                    }
                </Card>
            </Column>
        </Row>
    </Page>
};


export default AccountPage;
