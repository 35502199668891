import Page from '@admin/components/layout/Page';
import Column from '@admin/components/layout/Column';
import Row from '@admin/components/layout/Row';
import { useUserContext } from '@admin/context/UserContext';
import Button from '@admin/components/Button';
import Card from '@admin/components/Card';
import { widthStyles } from '@admin/styles/utils';
import { css, cx } from '@emotion/css';
import React from 'react';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import ButtonLink from '@admin/components/ButtonLink';
import PublicNearbyHubs from '../../components/public/nearbyHubsList';
import { UpcomingBookings } from '@admin/pages/homepage/components/upcomingBookings';
import PublicNearbyHubsWithMap from '@admin/components/public/nearbyHubsWithMap';
import Container from '@admin/components/layout/Container';
import Hero from '@admin/components/Hero';
import spacingStyles from '@admin/styles/spacings';
import headings from '@admin/styles/headings';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import { AccountResource } from '@common/types/apiResource';

export const homeHeroContent = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const homeHeroIntro = css`   
  color: ${baseColours.white};
  flex-basis: 65%;
  flex-grow: 1;
  padding-right: 1rem;

  h2{
    color: ${baseColours.white};
    text-shadow: 2px 2px ${baseColours.greyDark};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  p{
    font-weight: 500;
    margin-bottom: 1rem !important;
    
    @media (max-width: ${breakpoints.small}) {
      font-size: 0.8rem;
    }
  }

  a{
    color: ${baseColours.white};
    text-decoration: underline;
    font-weight: bold;

    &:hover,
    &:focus     {
      color: ${baseColours.black};
      text-decoration: none;
    }

  }

  @media (max-width: ${breakpoints.small}) {
    h2{
      font-size: ${spacings.sm};
      margin-bottom: ${spacings.sm};
    }
  })
`;

export const homeHeroBookings = css`
  background: ${baseColours.white};
  color: ${baseColours.black};
  flex-basis: 35%;
  flex-grow: 1;
  padding: ${spacings.sm};
  border-radius: 5px;
  min-width: 270px;
`;

export const homeHubsTitle = css`   
  
margin: ${spacings.md} 0 0 0;

  @media (max-width: ${breakpoints.small}) {
    font-size: ${spacings.sm};
    margin-bottom: ${spacings.sm};
`;

const HomePage = () => {
  const user = useUserContext();
  const account = user.user?.accounts?.[0] as AccountResource;

  return (
    <Page fullWidth>
      <Hero>
        <div className={homeHeroContent}>
          <div className={homeHeroIntro}>
            <h2>Welcome {`${account.firstName} ${account.surname}`}</h2>
            <p>
            Let's get you started using our hubs. First let's{' '}
              <Link to="/public/find-a-hub">Find a Hub</Link> that suits you.
            </p>
            <p>
            We want you to have a worry-free time with our hubs so check out the <Link to="/public/help">FAQs</Link>, or contact us directly at <a href="mailto:info@workfromhub.co.uk">info@workfromhub.co.uk</a>
            </p>
          </div>
          <div className={homeHeroBookings}>
            <UpcomingBookings />
          </div>
        </div>
      </Hero>

      <Container>
        <h2 className={homeHubsTitle}>Our Hubs</h2>
        <PublicNearbyHubs />
      </Container>
    </Page>
  );
};

export default HomePage;
