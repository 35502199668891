import { MediaReferenceResource, MediaReferenceType, UploadUrlResource } from '@common/types/apiResource';

import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import s3, { S3Service } from '@admin/services/s3Service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BadRequest } from '@feathersjs/errors';
import maximumFileSize from '@common/validations/maximumFileSize';
import apiClient from '@admin/services/apiClient';

export interface MediaReferenceService {
  find(userId: string): Promise<MediaReferenceResource[]>;

  create(data: Partial<MediaReferenceResource>): Promise<MediaReferenceResource>;

  remove(id: string): Promise<void>;
}

const mediaReferenceService: MediaReferenceService = apiClient.service('/api/mediaReference');

export const uploadReferencedFile = async <T extends UploadUrlResource>(
  file: File,
  type: MediaReferenceType,
  userId: string | undefined,
  s3Service: S3Service<T>,
  subType: 'upload' = 'upload',
) => {
  if (userId === undefined) throw new Error('userId cannot be undefined when uploading files');

  const s3Document = await s3Service.create({
    type: subType,
    fileType: file.type,
    name: file.name,
  });

  const data = new FormData();

  Object.entries(s3Document.fields).forEach(([key, value]) => data.append(key, value));

  data.append('file', file);

  try {
    await axios.post(s3Document.url, data);
  } catch (err) {
    if (err.response.status === 400) {
      const body = err.response.data as string;
      if (body.includes('<Code>EntityTooLarge</Code>')) {
        const maxmimumFileSizeBytes = maximumFileSize[type];
        const maximumFileSizeMb = Math.floor(maxmimumFileSizeBytes / 1000000);
        throw new BadRequest(`The selected file exceeds the maximum allowed ${maximumFileSizeMb}MB`);
      }
    }

    throw err;
  }

  return mediaReferenceService.create({
    type,
    url: `${s3Document.url}/${s3Document.fields.key}`,
    userId,
    title: file.name,
  });
};

export default mediaReferenceService;
