import { css } from '@emotion/css';
import { fontWeights } from '@admin/styles/variables';

export const displayStyles = {
  inlineBlock: css`
    display: inline-block;
  `,
  none: css`
    display: none;
  `,
};

export const widthStyles = {
  full: css`
    width: 100%;
  `,
  half: css`
    width: 50%;
  `,
  quarter: css`
    width: 25%;
  `,
};

export const heightStyles = {
  full: css`
    height: 100%;
  `,
  half: css`
    height: 50%;
  `,
  quarter: css`
    height: 25%;
  `,
};

export const floatStyles = {
  right: css`
    float: right;
  `,
};

export const textStyles = {
  center: css`
    text-align: center;
  `,
  lowercase: css`
    text-transform: lowercase;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  underline: css`
    text-decoration: underline;
  `,
  bold: css`
    font-weight: ${fontWeights.bold};
  `,
};

export const srOnlyStyle = css`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
