import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Hero from '@admin/components/Hero';
import React from 'react';
import { css, cx } from '@emotion/css';

const NotFound = () => {
  return (
    <Page title="Page not found" fullWidth>
      <Hero title="Page not found" />
      <Container>
        <p className={css(`padding: 7rem 1rem; font-weight: bold; height: 50vh`)}>
          We can't find the page you’re looking for.
        </p>
      </Container>
    </Page>
  );
};

export default NotFound;
