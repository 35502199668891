import { FieldValidator, useField } from 'formik';
import React, { ChangeEventHandler, ComponentType, FocusEventHandler } from 'react';

interface RequiredProps {
  name: string;
  error?: string;
  value: unknown;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
}

type Props<P> = {
  name: string;
  type?: string;
  validate?: FieldValidator;
  as?: ComponentType<P & RequiredProps>;
} & Omit<P, 'name' | 'value' | 'type' | 'onChange' | 'onBlur' | 'checked'>;

const FormField = <P extends {}>({ name, as, ...props }: Props<P>) => {
  const Component = as as ComponentType<RequiredProps>;
  const [field, meta] = useField({ name });

  let errorMessage = '';

  if (meta.touched) {
    errorMessage = Array.isArray(meta.error) ? meta.error[0] : meta.error;
  }

  return <Component {...props} {...field} name={name} error={errorMessage} />;
};

export default FormField;
