import Card from '@admin/components/Card';
import Hero from '@admin/components/Hero';
import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import { useServiceContext } from '@admin/context/ServiceContext';
import { useUserContext } from '@admin/context/UserContext';
import { PersonalDetails } from '@admin/pages/public/account/personalDetails';
import { css } from '@emotion/css';
import React from 'react';
import ProfileImage from './profileImage';
import flexStyles from '@admin/styles/flex';
import { breakpoints } from '@admin/styles/variables';

const formContainer = css`
  margin: 1rem;

  form{
    max-width: 900px;
  }

  @media (max-width: ${breakpoints.small}) {
    width: calc(100% - 2rem);
  })
`;

const AccountSettingsPage = () => {
  const user = useUserContext();

  const { accountService } = useServiceContext();

  return (
    <Page title="Account Settings" fullWidth>
      <Hero title="Your account" />
      <Container>
        <div className={formContainer}>
          <Card>
            <h3>Account Details</h3>
            <Row>
              <Column size={12}>
                <PersonalDetails />
              </Column>
            </Row>
          </Card>
        </div>
      </Container>
    </Page>
  );
};

export default AccountSettingsPage;
