import React, { ChangeEventHandler, ComponentType, FocusEventHandler } from 'react';
import { FieldValidator, useField } from 'formik';
import { css, cx } from '@emotion/css';
import flexStyles from '@admin/styles/flex';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';

const labelCss = css`
  width: 100%;
`;

const inputCss = css`
  width: 100%;
  margin-bottom: 2rem;
`;

interface RequiredProps {
  name: string;
  error?: string;
  value: unknown;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  labelClassName?: string;
  className?: string;
}

type Props<P> = {
  name: string;
  type?: string;
  validate?: FieldValidator;
  as?: ComponentType<P & RequiredProps>;
} & Omit<P, 'name' | 'value' | 'type' | 'onChange' | 'onBlur' | 'checked'>;

const InlineFormField = <P extends {}>({ name, as, ...props }: Props<P>) => {
  const Component = as as ComponentType<RequiredProps>;
  const [field, meta] = useField({ name });

  let errorMessage = '';

  if (meta.touched) {
    errorMessage = Array.isArray(meta.error) ? meta.error[0] : meta.error;
  }

  return (
    <div
      className={cx(
        flexStyles.flex,
        flexStyles.wrap,
        css`
          align-items: baseline;
        `,
      )}
    >
      <Component
        {...props}
        {...field}
        name={name}
        error={errorMessage}
        labelClassName={labelCss}
        className={inputCss}
      />
    </div>
  );
};

export default InlineFormField;
