import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { headingSizes } from '@admin/styles/variables';
import { ThemeBase } from '@common/types/emotion';
import { css } from '@emotion/css';
import { LatLng, Map } from 'leaflet';
import FormLabel from './FormLabel';

const center = {
  lat: 51.505,
  lng: -0.09,
};

export interface FormMapProps {
  children?: (input: ReactNode) => void;
  className?: string;
  disabled?: boolean;
  error?: string;
  hint?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingSizes;
  labelColor?: keyof ThemeBase;
  name: string;
  showLabel?: boolean;
  value: string | number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}

const FormMap = ({
  children,
  className,
  disabled = false,
  error,
  hint,
  id,
  label,
  labelSize,
  labelColor,
  name,
  showLabel = true,
  value,
  onBlur,
  onChange,
  onKeyUp,
  ...props
}: FormMapProps) => {
  const [position, setPosition] = useState(center);

  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;

        if (marker != null) {
          console.log(marker);
          // setPosition(marker)
        }
      },
    }),
    [],
  );

  return (
    <>
      <FormLabel id={id} show={showLabel} size={labelSize} color={labelColor}>
        {label}
      </FormLabel>
      <div
        className={css`
          width: 100%;
          height: 400px;
          display: block;
        `}
      >
        <MapContainer
          // whenCreated={setMap}
          center={center}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker draggable eventHandlers={eventHandlers} position={position} ref={markerRef} />
        </MapContainer>
      </div>
    </>
  );
};

export default FormMap;
