import accountService from '@admin/services/accountService';
import addressService from '@admin/services/addressService';
import bookedSlotService from '@admin/services/bookedSlotService';
import bookingService from '@admin/services/bookingService';
import clientService from '@admin/services/clientService';
import hubService from '@admin/services/hubService';
import siteService from '@admin/services/siteService';
import userService from '@admin/services/userService';
import { ServiceList } from '@admin/context/ServiceContext';
import mediaReferenceService from '@admin/services/mediaReferenceService';
import mediaService from '@admin/services/mediaService';
import auditService from '@admin/services/auditService';
import authorisationService from "@admin/services/authorisationService";
import slotAvailabilityService from "@admin/services/slotAvailabilityService";

export const privateServices: ServiceList = {
  accountService,
  addressService,
  bookedSlotService,
  bookingService,
  clientService,
  hubService,
  userService,
  siteService,
  mediaService,
  mediaReferenceService,
  auditService,
  authorisationService,
  slotAvailabilityService
};
