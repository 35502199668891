import { CSSInterpolation, serializeStyles } from '@emotion/serialize';
import { StyleSheet } from '@emotion/sheet';
import { compile, middleware, rulesheet, serialize, stringify } from 'stylis';

function injectGlobal(...args: Array<CSSInterpolation>) {
  // @ts-ignore
  const { name, styles } = serializeStyles(...args);
  const sheet = new StyleSheet({
    key: `global-${name}`,
    container: document.head,
  });

  const stylis = (someStyles: string) =>
    serialize(
      compile(someStyles),
      middleware([
        stringify,
        rulesheet((rule: string) => {
          sheet.insert(rule);
        }),
      ]),
    );

  stylis(styles);
  return () => sheet.flush();
}

export default injectGlobal;
