import React from "react";
import {ARBITRARY_MAXIMUM_DATE, dateCompare, dateWithoutTime} from "@common/utils/dateFunctions";
import {css, cx} from "@emotion/css";
import {FaCaretLeft, FaCaretRight} from "react-icons/fa";
import {BookedSlot} from "@admin/pages/bookings/components/BookingForm";
import {FormatDateSpan} from "./FormatDateSpan";

const dateSelection = css`
  display: grid;
  grid-gap: 0.5em;
  grid-template: 3.5em / 1em repeat(4, 3.5em) 1em;

  align-items: center;

  text-align: center;
  margin-top: 1rem;


`;

export const CalendarSelector = ({
  selectedSlots,
  numberOfDaysToShow,
  selectedDate,
  onSelectedDateChange,
  fromDate,
  toDate
}: {
  selectedSlots: BookedSlot[],
  selectedDate?: Date,
  numberOfDaysToShow: number,
  onSelectedDateChange: (date: Date) => void,
  fromDate?: Date,
  toDate?: Date,
}) => {
  type StartEndType = { start: Date, end: Date };

  const numberOfDatesToShow = 4;

  const selectedStartDate = React.useMemo(() => dateWithoutTime(selectedDate || new Date()), [selectedDate]);

  const currentDate = React.useMemo(() => dateWithoutTime(new Date()), []);
  const maxDate = React.useMemo(() => dateWithoutTime(toDate || ARBITRARY_MAXIMUM_DATE),
    [toDate]
  );

  const minDate = React.useMemo(() => dateWithoutTime(fromDate === undefined || currentDate > fromDate ? currentDate : fromDate),
    [currentDate, fromDate]
  );


  const [dateListStartDate, setDateListStartDate] = React.useState(minDate);

  const dateListEndDate = React.useMemo(() => {
    return new Date(new Date(dateListStartDate).setDate(dateListStartDate.getDate() + 4));
  }, [dateListStartDate]);


  React.useEffect(() => {

    const selectedStartDateUTC = Date.UTC(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), selectedStartDate.getDate());
    const minDateUTC = Date.UTC(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());

    const daysOffset = (selectedStartDateUTC - minDateUTC) / 86400000;

    const newSelectedDateListOffset = Math.floor((daysOffset / (numberOfDaysToShow * 4))) * numberOfDaysToShow * 4;
    const newSelectedStartDateOffset = Math.floor((daysOffset / numberOfDaysToShow)) * numberOfDaysToShow;

    setDateListStartDate(new Date(minDate.getTime() + newSelectedDateListOffset * 86400000));

    const newSelectedStartDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()  + newSelectedStartDateOffset);// * 86400000);


    // only invoke if changed, to prevent endless calls.
    if (dateCompare(selectedStartDate, newSelectedStartDate) !== 0) {
      onSelectedDateChange(newSelectedStartDate);
    }

  }, [minDate, numberOfDaysToShow, onSelectedDateChange, selectedStartDate]);


  const dateList = React.useMemo(() => {
    return [...new Array(numberOfDatesToShow)].map((_, idx) => {
      const start = new Date(dateListStartDate);
      start.setDate(start.getDate() + idx * numberOfDaysToShow);
      const end = new Date(new Date(start).setDate(start.getDate() + numberOfDaysToShow - 1));

      if (start < minDate || start > maxDate) return undefined;
      return {start, end};
    }).filter(Boolean) as StartEndType[];
  }, [dateListStartDate, maxDate, minDate, numberOfDaysToShow]);

  return <div
    className={cx(dateSelection)}>
    <FaCaretLeft
      size="1em"
      className={css`${dateCompare(dateListStartDate, minDate) <= 0 ? 'visibility: hidden' : ''}`}
      onClick={() => {
        setDateListStartDate(new Date(dateListStartDate.setDate(dateListStartDate.getDate() - numberOfDaysToShow * 4)));
      }}
    />
    {dateList.map(({start, end}) => (
      <FormatDateSpan
        key={start.toString()}
        date={start}
        onClick={() => onSelectedDateChange(start)}
        selected={dateCompare(start, selectedStartDate) === 0}
        containsSlots={ selectedSlots.find(_ => dateCompare(_.date, start) >= 0 && dateCompare(_.date, end) <= 0) !== undefined }
      />
    ))}
    <FaCaretRight
      size="1em"
      className={css`${dateCompare(dateListEndDate, maxDate) >= 0 ? 'visibility: hidden' : ''}`}
      onClick={() => {
        setDateListStartDate(new Date(dateListStartDate.setDate(dateListStartDate.getDate() + numberOfDaysToShow * 4)));
      }}
    />
  </div>

};
