import { Theme } from '@emotion/react';
import { lighten } from 'polished';
import { baseColours } from '../styles/variables';

const defaultTheme: Theme = {
  background: baseColours.white,
  background1: baseColours.greyWhite,
  background2: baseColours.greyLight,
  border: baseColours.grey,
  border1: baseColours.greyLight,
  noticeBackground: baseColours.pinkLight,
  noticeLinkColor: baseColours.blueDark,
  disabledButtonBackground: baseColours.greyLight,
  disabledButtonBorder: baseColours.grey,
  disabledButtonColor: baseColours.grey,
  error: baseColours.red,
  feedbackBubbleColor: baseColours.pinkLight,
  inputColor: baseColours.blue,
  link: baseColours.teal,
  linkHover: baseColours.teal,
  linkDisabled: baseColours.grey,
  modalDismissBackground: baseColours.greyDark,
  modalDismissColor: baseColours.white,
  primary: baseColours.black,
  primaryHighlight: lighten(0.1, baseColours.black),
  primaryAlternate: baseColours.teal,
  secondary: baseColours.pink,
  secondaryHighlight: baseColours.pinkLight,
  success: baseColours.green,
  tertiary: baseColours.blueDark,
  text: baseColours.black,
  text1: baseColours.greyDark,
  text2: baseColours.grey,
  textContrast: baseColours.white,
  textPrimary: baseColours.black,
  warning: baseColours.orange,
};
export default defaultTheme;
