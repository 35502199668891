import React, { ReactNode } from 'react';
import BookingList from '@admin/components/bookings/BookingList';
import { AsyncRetryState } from '@admin/hooks/useAsyncRetry';
import { AccountResource, BookingResource } from '@common/types/apiResource';
import { Paginated } from '@feathersjs/feathers';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import ButtonLink from '@admin/components/ButtonLink';
import Column from '@admin/components/layout/Column';
import BookingForm from '@admin/pages/bookings/components/BookingForm';
import Row from '@admin/components/layout/Row';
import Pagination from '@admin/components/Pagination';
import flexStyles from '@admin/styles/flex';
import { useServiceContext } from '@admin/context/ServiceContext';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { useTheme } from '@emotion/react';
import { buttonStyle } from '../Button';
import { Form, Formik } from 'formik';
import FormFieldCheckbox from '../form/field/FormFieldCheckbox';
import { DeleteModalButton } from '@admin/components/DeleteModalButton';
import ErrorMessage from '../ErrorMessage';

interface AbstractProps {
  accountResource: AsyncRetryState<AccountResource | undefined>;
  bookingsResource: AsyncRetryState<Paginated<BookingResource> | undefined>;
  viewLink?: (booking: BookingResource) => ReactNode;
  allowMassDelete?: boolean;
}

interface FormValues {
  bookingId: string[];
}

const EditableBookingsList = ({
  accountResource,
  bookingsResource,
  viewLink,
  allowMassDelete = false,
}: AbstractProps) => {
  const { bookingService } = useServiceContext();

  const { value: account, isLoading: accountIsLoading } = accountResource;
  const { value: bookings, isLoading, retry, error, setError } = bookingsResource;

  const createBooking = React.useCallback(
    async (newBooking: Partial<BookingResource>) => {
      try {
        const result = await bookingService.create(newBooking, {
          query: { $eager: '[bookedSlots]' },
        });

        retry();
      } catch (e) {
        setError(e);
      }
    },
    [bookingService, retry, setError],
  );

  const deleteBookings = React.useCallback(
    async (bookingIds: string[]) => {
      try {
        const promises = await Promise.all(
          bookingIds.map(id => bookingService.remove(id, { query: { $cascade: true } })),
        );

        retry();
      } catch (e) {
        setError(e);
      }
    },
    [bookingService, retry, setError],
  );

  const createBookingDisclosure = useDisclosureState();
  const theme = useTheme();
  const disclosureStyle = buttonStyle({
    colour: 'primary',
    size: 'sm',
    theme,
    inverted: false,
  });

  return (
    <>
      {account && bookings && (
        <>
          <LoadingSpinner loading={accountIsLoading || isLoading} center>
            <Formik<FormValues>
              initialValues={{
                bookingId: [],
              }}
              onSubmit={values => {
                deleteBookings(values.bookingId);
              }}
            >
              {formik => (
                <Form>
                  {error && <ErrorMessage id="error">{error.message}</ErrorMessage>}

                  <BookingList
                    account={account}
                    bookings={bookings.data}
                    pre={
                      (allowMassDelete &&
                        (booking => (
                          <>
                            <FormFieldCheckbox
                              id={`booking_${booking.id}`}
                              name="bookingId"
                              value={booking.id}
                              label=""
                              showLabel={false}
                            />
                          </>
                        ))) ||
                      undefined
                    }
                    post={
                      viewLink ||
                      (booking => (
                        <ButtonLink to={{ pathname: `/admin/bookings/${booking.id}` }}>View booking</ButtonLink>
                      ))
                    }
                  />

                  {bookings.total > bookings.limit && (
                    <Row>
                      <Column>
                        <Pagination aria-label="Bookings" {...bookings} />
                      </Column>
                    </Row>
                  )}

                  {bookings.total === 0 && (
                    <Row>
                      <Column size={12} className={flexStyles.flexCenterAll}>
                        <strong>There are currently no bookings listed.</strong>
                      </Column>
                    </Row>
                  )}
                </Form>
              )}
            </Formik>
          </LoadingSpinner>
        </>
      )}
    </>
  );
};

export default EditableBookingsList;
