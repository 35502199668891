import Hero from '@admin/components/Hero';
import Card from '@admin/components/Card';
import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import spacingStyles from '@admin/styles/spacings';
import { css, cx } from '@emotion/css';
import Link from '@admin/components/Link';
import React from 'react';
import { termsContent, termsHeroContent } from './terms';

const CookiesPage = () => {
  return (
    <Page title="Cookie Policy" fullWidth>
      <Hero title="Cookie Policy">
        <div className={termsHeroContent}>
          <Row>
            <Column size={8}>
              <p>Last updated on 3rd September 2021</p>
            </Column>
            <Column size={4}>
              <h3>Read also</h3>
              <p>
                <Link to="/terms">Terms &amp; Conditions</Link> <br />
                <Link to="/terms/privacy">Privacy Policy</Link>
              </p>
            </Column>
          </Row>
        </div>
      </Hero>
      <Container>
        <Card className={cx(spacingStyles.marginTop.lg)}>
          <h3>INFORMATION ABOUT OUR USE OF COOKIES</h3>
          <p>
            Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
            with a good experience when you browse our website and also allows us to improve our site. A cookie is a
            small file of letters and numbers that we store on your browser or the hard drive of your computer if you
            agree. Cookies contain information that is transferred to your computer's hard drive.
          </p>
          <p>We use the following cookies:</p>

          <ul>
            <li>
              <p>
                <b>Essential cookies</b>
                <br />
                These are cookies that are required for the operation of our website. They include, for example, cookies
                that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing
                services.
              </p>
            </li>
          </ul>
          <p>
            You block cookies by activating the setting on your browser that allows you to refuse the setting of all or
            some cookies. However, if you use your browser settings to block all cookies (including essential cookies)
            you may not be able to access all or parts of our site.&nbsp;
          </p>
          <p>Except for essential cookies, all cookies will expire after&nbsp;30 days.</p>
        </Card>
      </Container>
    </Page>
  );
};

export default CookiesPage;
