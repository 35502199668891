"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ARBITRARY_MAXIMUM_DATE = exports.calculateLengthInHours = exports.dateAtMidnightToIsoStringWithTimezone = exports.dateCompare = exports.datesAreEqual = exports.parseDateString = exports.dateWithoutTime = void 0;

var dateWithoutTime = function dateWithoutTime(d) {
  var newDate = new Date(d);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

exports.dateWithoutTime = dateWithoutTime;

var parseDateString = function parseDateString(s) {
  return new Date(Date.parse(s));
};

exports.parseDateString = parseDateString;

var datesAreEqual = function datesAreEqual(A, B) {
  return A.valueOf() - B.valueOf() === 0;
};

exports.datesAreEqual = datesAreEqual;

var dateCompare = function dateCompare(A, B) {
  return A.valueOf() - B.valueOf();
};

exports.dateCompare = dateCompare;
/**
 * creates a date with "midnight" UTC being 00:00, but based on the current timezone
 * E.g. In BST, a date will return something like "2021-11-04T01:00:00+01:00"
 *
 * When sent to the server, this will translate back to a proper UTC time of midnight on that date
 * So that timezones taken into account when sending dates
 *
 * @param wantedDate
 * @return ISO formatted string of the date at midnight with timezone offset
 */

var dateAtMidnightToIsoStringWithTimezone = function dateAtMidnightToIsoStringWithTimezone(wantedDate) {
  var tzo = -wantedDate.getTimezoneOffset();
  var dif = tzo >= 0 ? '+' : '-';

  var pad = function pad(num) {
    var norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  var date = new Date(wantedDate);
  date.setHours(tzo / 60, tzo % 60, 0);
  return "".concat(date.getFullYear(), "-").concat(pad(date.getMonth() + 1), "-").concat(pad(date.getDate()), "T").concat(pad(date.getHours()), ":").concat(pad(date.getMinutes()), ":").concat(pad(date.getSeconds())).concat(dif).concat(pad(tzo / 60), ":").concat(pad(tzo % 60));
};

exports.dateAtMidnightToIsoStringWithTimezone = dateAtMidnightToIsoStringWithTimezone;

var calculateLengthInHours = function calculateLengthInHours(start, end) {
  var startHours = start.getHours() + start.getMinutes() / 60;
  var endHours = end.getHours() + end.getMinutes() / 60;
  return endHours - startHours;
};

exports.calculateLengthInHours = calculateLengthInHours;
var ARBITRARY_MAXIMUM_DATE = new Date("9999-12-01");
exports.ARBITRARY_MAXIMUM_DATE = ARBITRARY_MAXIMUM_DATE;