import Page from '@admin/components/layout/Page';
import Card from '@admin/components/Card';
import Button from '@admin/components/Button';
import { css, cx } from '@emotion/css';
import { widthStyles } from '@admin/styles/utils';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import spacingStyles from '@admin/styles/spacings';
import { Link, useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { SiteResource } from '@common/types/apiResource';
import React from 'react';
import Map from '@admin/components/Map';
import HubsList from './components.tsx/hubsList';
import { Marker, Popup } from 'react-leaflet';
import { breakpoints, fontWeights, spacings } from '@admin/styles/variables';
import { useServiceContext } from '@admin/context/ServiceContext';
import { BsWifi } from 'react-icons/bs';
import { FaToilet } from 'react-icons/fa';
import Hero from '@admin/components/Hero';
import Container from '@admin/components/layout/Container';

interface RouteParams {
  id: string;
}

const FindAHubFacilitiesPage = () => {
  const params = useParams<RouteParams>();

  const { siteService } = useServiceContext();

  const { value: site, isLoading } = useAsyncRetry<SiteResource>(async () => {
    const result = await siteService.get(params.id, {
      query: {
        $eager: '[hubs.images, address]',
      },
    });

    return result as SiteResource;
  });

  const gridClass = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, minmax(70px, 1fr));
    grid-gap: 0.6rem;
    margin: 1rem 0 2rem;
  `;

  const image = css`
    width: 100%;
    height: 100%;
    object-fit: cover;

    :first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  `;

  const detailsHeader = css`
  @media (max-width: ${breakpoints.small}) {
    font-size: ${spacings.sm};
  })
`;

  const detailsExtra = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap:  ${spacings.md};

  @media (max-width: ${breakpoints.small}) {
    grid-template-columns: 1fr;
  })

`;

  return (
    <Page title="Facilities Page" fullWidth>
      <Hero title="Facilities" subtitle="WorkfromHub location facilities" />
      <Container className={cx(spacingStyles.marginTop.lg, spacingStyles.marginBottom.lg)}>
        <Card>

          <h3>Onsite Facilities</h3>
          <ul>
            <li>Toilets &ndash;  male, female and accessible</li>
            <li>Cycle rail</li>
            <li>WH Smiths kiosk &ndash; stationery, refreshments</li>
            <li>DJs Caf&eacute;</li>
            <li>Cash Machine</li>

          </ul>
        </Card>

        <Card>
          <h3>Transport Links</h3>
          <ul>
            <li>
              <a href="https://goo.gl/maps/sJxrB6HH9BupcPXZ9" target="_blank" rel="noreferrer noopener">Sheffield Train Station</a> - 2-minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/sJxrB6HH9BupcPXZ9" target="_blank" rel="noreferrer noopener">Taxi Rank</a> – Sheffield Train Station
            </li>
            <li>
              Secure cycle storage: <a href="https://goo.gl/maps/Tpkt5EKbD9j2KDrV8" target="_blank" rel="noreferrer noopener">Russell’s Bike Shed</a> located in Sheffield Train Station 
            </li>
            <li>
              <a href="https://goo.gl/maps/EhtciimxJ3MM1Zh6A" target="_blank" rel="noreferrer noopener">Fitzalan Square Tram Stop</a> (Yellow/Blue and TramTrain lines) – 5-minute walk 
            </li>
            <li>
              <a href="https://www.zap-map.com/" target="_blank" rel="noreferrer noopener">EV Charging points</a>
            </li>
          </ul>
        </Card>


        <Card>
          <h3>Food and Drink</h3>
          <ul>
            <li>
              <a href="https://goo.gl/maps/otunrKC8k1HQUAT4A" target="_blank" rel="noreferrer noopener">Hygge Cafe</a> (with open workspace) - 3 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/yY53t7ckarSxo71TA" target="_blank" rel="noreferrer noopener">Subway</a> - 10 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/dToJ8BzZvtWCx2TX9" target="_blank" rel="noreferrer noopener">KFC </a> - 5 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/W48iE36eKG8Voaxc7" target="_blank" rel="noreferrer noopener">Costa Coffee</a> - Pinstone Street - 10 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/mBV6t1zNxJV9enka6" target="_blank" rel="noreferrer noopener">Costa Coffee</a> - Orchard Square - 9 minute walk 
            </li>

            <li>
              <a href="https://goo.gl/maps/KecxQcy4TsMqmCqv9" target="_blank" rel="noreferrer noopener">Starbucks </a> - Fargate - 7 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/GtGWGuZ6nLgJMYqt5" target="_blank" rel="noreferrer noopener">Starbucks </a> - Tudor Square - 7 minute walk  
            </li>
          </ul>
        </Card>

        <Card>
          <h3>Business Services</h3>
          <ul>
            <li>
              <a href="https://goo.gl/maps/LYRjyTAnLXNUXKdF9" target="_blank" rel="noreferrer noopener">Post-Office (Wilkinsons)</a> - 5 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/arjWmGSWs4HSmwdy5" target="_blank" rel="noreferrer noopener">The Print Shop</a> - located on Pond Street, available to the public - 2 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/dX8cpGiX4MAdTZgP8" target="_blank" rel="noreferrer noopener">Art Supplies Store</a> - 3 minute walk
            </li>
            <li>
              <a href="https://goo.gl/maps/zMfJbNRX5x7zYauq8" target="_blank" rel="noreferrer noopener">Fitzalan Square open space</a> - 4 minute walk 
            </li>
          </ul>
        </Card>

        <Card>
          <h3>Banks</h3>
          <ul>
            <li>
              <a href="https://goo.gl/maps/ppuUZu4W5jjzBMMf7" target="_blank" rel="noreferrer noopener">Virgin Money Lounge</a> - 8 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/WpuMV89GW7r4tXNm8" target="_blank" rel="noreferrer noopener">Metro Bank</a> - 8 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/DzS1EjzZN2i2CgPn6" target="_blank" rel="noreferrer noopener">Barclays</a> - 8 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/GkxFWTR9LJjBGQRK9" target="_blank" rel="noreferrer noopener">Santander</a> - 7 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/iYsCiudb4k7QNanZ7" target="_blank" rel="noreferrer noopener">Nationwide</a> - 9 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/cyADugUZfZMkAoFk8" target="_blank" rel="noreferrer noopener">NatWest</a> - 5 minute walk 
            </li>
            <li>
              <a href="https://goo.gl/maps/PR4VdbzvwnUbnTs86" target="_blank" rel="noreferrer noopener">Lloyds</a> - 7 minute walk 
            </li>
          </ul>
        </Card>

        <Card>
          <h3>Leisure</h3>
          <ul>
            <li>
              <a href="https://goo.gl/maps/PPh28CNsDen8Aw3R6" target="_blank" rel="noreferrer noopener">Crucible Theatre</a> - 5 minute walk 

            </li>
            <li>
              <a href="https://goo.gl/maps/XAirQVUVPuz6aZR79" target="_blank" rel="noreferrer noopener">Lyceum Theatre</a> - 4 minute walk 

            </li>
            <li>
              <a href="https://goo.gl/maps/uA9iSM7sb1yQPFQs8" target="_blank" rel="noreferrer noopener">Central Library</a> - 5 minute walk 

            </li>
            <li>
              <a href="https://goo.gl/maps/w7NwiMmeQLNh4mr78" target="_blank" rel="noreferrer noopener">Winter Gardens</a> - 7 minute walk 

            </li>
            <li>
              <a href="https://goo.gl/maps/4CE3YurNK4SGQBTB9" target="_blank" rel="noreferrer noopener">Millenium Gallery</a> - 6 minute walk 

            </li>
            <li>
              <a href="https://goo.gl/maps/h8Dd6JZXMdEqNP2m7" target="_blank" rel="noreferrer noopener">Sheffield Cathedral</a> - 8 minute walk  

            </li>
            <li>
              <a href="https://goo.gl/maps/zBWXkRCHPL8hzWvQ9" target="_blank" rel="noreferrer noopener">Ponds Forge Leisure Centre and Gym</a> - 5 minute walk 
            </li>
          </ul>
        </Card>








      </Container>
    </Page>
  );
};

export default FindAHubFacilitiesPage;
