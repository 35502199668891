import React, { ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import Column from '@admin/components/layout/Column';

import headings from '@admin/styles/headings';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import UserHeader from './UserHeader';
import Row from './Row';
import { Link } from 'react-router-dom';
import Header from './Header';
import Container from './Container';

const pageStyle = css`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  width: 100%;
  min-height: 100%;
  margin: auto;

  background-color: #f4f4f2;
`;

const pageContainerStyle = css`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacings.lg};
  margin-bottom: ${spacings.lg};
  padding: ${spacings.md};
  width: 100%;
  @media (max-width: ${breakpoints.small}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const pageTitleStyle = css`
  font-size: ${spacings.lg};
  @media (max-width: ${breakpoints.small}) {
    font-size: ${spacings.md};
  }
`;

const footerStyle = css`
  background: ${baseColours.black};
  padding: ${spacings.xl};
  color: ${baseColours.white};
`;

const footerLinks = css`
  a {
    color: ${baseColours.white};
    margin-right: ${spacings.md};
    text-decoration: underline;
  }

  a:hover,
  a:focus {
    color: ${baseColours.white};
    text-decoration: none;
  }
`;
interface Props {
  title?: string;
  children: ReactNode;
  fullWidth?: boolean;
}

const Page = ({ title, children, fullWidth }: Props) => (
  <div className={pageStyle}>
    <Header />

    {fullWidth === true ? (
      <>{children}</>
    ) : (
      <div className={pageContainerStyle}>
        {title && <h1 className={pageTitleStyle}>{title || ''}</h1>}
        {children}
      </div>
    )}

    <div className={footerStyle}>
      <Container>
        <p>WorkfromHub &copy;2021</p>
        <p className={footerLinks}>
          <Link to="/terms" className={footerLinks}>
            Terms &amp; Conditions{' '}
          </Link>
          <Link to="/terms/privacy" className={footerLinks}>
            Privacy Policy
          </Link>
          <Link to="/terms/cookies" className={footerLinks}>
            Cookie Policy
          </Link>
        </p>
      </Container>
    </div>
  </div>
);

export default Page;
