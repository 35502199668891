/* eslint-disable */
import {css, cx} from "@emotion/css";
import React, {ChangeEventHandler, DragEvent, UIEvent} from "react";
import {formatDate, formatDay, formatMonthShort, formatTime, formatWeek, formatYear} from "@common/formats";
import {RiReactjsFill} from "react-icons/all";
import {baseColours, breakpoints, spacings} from "@admin/styles/variables";
import {ARBITRARY_MAXIMUM_DATE, dateCompare, dateWithoutTime} from "@common/utils/dateFunctions";
import {BookedSlot, buttonPrimary, buttonSecondary} from "@admin/pages/bookings/components/BookingForm";
import Button from "@admin/components/Button";
import FormSelect from "@admin/components/form/FormSelect";
import {useMaxWidthMedia, useMinWidthMedia} from "@admin/hooks/useMedia";
import {HubResource, SlotAvailabilityResource} from "@common/types/apiResource";
import ErrorMessage from "@admin/components/ErrorMessage";
import {daysToWeeks} from "date-fns";
import {useTheme} from "@emotion/react";
import {Theme} from "@common/types/emotion";
import { FaExclamationTriangle } from 'react-icons/fa';

const dragElement = document.createElement("span");
dragElement.setAttribute("style", 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
document.body.appendChild(dragElement);


// region CSS Region

const fieldCss = (theme: Theme) => {

  const grid = (numberOfCells: number, numberOfDays: number) => css`
    margin-top: 1rem;
    display: grid;
    grid-template:  4em repeat(${numberOfCells}, 1em) / 3em repeat(${numberOfDays}, 5em);
    grid-auto-flow: row;
    grid-gap: 0; //.1rem;

    @media (max-width: ${breakpoints.small}) {
      grid-template: 2em repeat(${numberOfCells}, 1em) / 0.25fr repeat(${numberOfDays}, 1fr);
    }

  `;

  const cell = css`
    background-color: ${theme.background2};
    padding: 0;
    margin: 0;
    border: 0;

    user-drag: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    width: 100%;
    display: block;
    position: relative;
  `;

  const cellAlt = css`
    background-color: ${theme.background1};
  `;

  const cellHasBooking = css`
    content: "\\00a0";
    position: absolute;
    border: 4px solid;
    padding: 0px;
    line-height: 1em;
    height: 100%;
    width: 0px;
  `;


  const cellOnTop = css`
    z-index: 1000;
    position: relative;
    background: ${theme.success};
    display: block;
    font-size: 0.9rem;
    color: white;
    padding-left: 0.2rem;
  `;

  const cellTime = css`
  `;

  const cellDateHeading = css`
    font-size: 76%;
    border-right: 1px solid ${theme.modalDismissBackground};
    border-bottom: 1px solid ${theme.modalDismissBackground};
    padding-bottom: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;


    @media (max-width: ${breakpoints.small}) {
      border-right: none;
      display: block;
      & :not(:last-child) {
        padding-right: 2em;
      }
    }
  `;


  const cellBooking = css`
    background: ${theme.error};

    &.first {
      ::before {
        border-radius: 4px 4px 0 0;
      }
      z-index: 1000;
      text-align: center;
      color: ${theme.textContrast};
      font-size: 85%;
    }
  ;

    &.last {
      ::before {
        border-radius: 0 0 4px 4px;
      }
    }

    ::before {
      ${cellHasBooking};
      border-color: transparent;
      left: 4px;
    }
  `;

  const borderRadiusAfter = css`
    &.first {
      ::after {
        border-radius: 4px 4px 0 0;
      }
    }
  ;

    &.last {
      ::after {
        border-radius: 0 0 4px 4px;
      }
    }
  `;

  const cellAdditional = css`
    ${borderRadiusAfter};

    ::after {
      ${cellHasBooking};
      border-color: ${theme.link}; //darkcyan;
      right: 4px;
    }
  `;

  const cellHighlight = css`
    ${borderRadiusAfter};

    ::after {
      ${cellHasBooking};
      border-color: ${theme.success};
      right: 4px;
    }
  `;

  const cellSelectionError = css`
    ${cellHighlight};

    ::after {
      border-color: ${theme.error};
    }
  `


  const cellClassTypes = {
    'none': '',
    'unavailable': cellBooking,
    'new': cellAdditional
  }

  const slotDetails = css`
    z-index: 1999;
    display: block;
    position: absolute;
    background-color: white;
    width: 350px;
    left: 4em;
    top: -1em;
    padding: 1rem;
    border: 2px solid;
    margin: .25em;
    border-radius: 1rem;

    @media (max-width: ${breakpoints.small}) {
      top: 2.5rem;
      left: -5rem;
      width: 92vw;
    }

  `

  const orderSummary = css`
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: bold;
      flex: 0 0 35%;
    }

    dd {
      flex: 0 0 65%;

      label {
        margin-top: 1rem;
        font-size: 0.8rem;
      }

      select {
        border: 2px solid ${baseColours.greyDark} !important;
        background: none !important;

        &::hover {
          cursor: pointer !important;
        }
      }
    }
  `
  
  const ctaPositioning = css`
    margin: 0 0.8rem 0 35%;
    @media (max-width: ${breakpoints.small}) {
      margin: 0 0.4em 0 35%;
    }
  `

  const errorStyling = css`
    display: flex;
    line-height: 1.2rem;
    justify-content: space-between;
    align-items: center;
  `


  return {
    grid,
    cell,
    cellAlt,
    cellHasBooking,
    cellOnTop,
    cellTime,
    cellDateHeading,
    cellBooking,
    ctaPositioning,
    borderRadiusAfter,
    cellAdditional,
    cellHighlight,
    cellSelectionError,
    cellClassTypes,
    slotDetails,
    orderSummary,
    errorStyling,
  };
};

// endregion

// region Helper Components
const SelectedSlotPopup = ({
  date,
  onChange,
  selectedSlot,
  endOfDay,
  addSlot,
  clearSelection,
  validSelection = true,
  timeSlots
}: {
  date: Date,
  onChange: ChangeEventHandler<HTMLSelectElement>,
  selectedSlot: SelectedRange,
  endOfDay: number,
  addSlot: () => void,
  clearSelection: () => void,
  validSelection: boolean,
  timeSlots: SlotAvailabilityResource[]
}) => {

  const theme = useTheme();

  const {slotDetails, orderSummary, ctaPositioning, errorStyling} = fieldCss(theme);

  return <div
    className={slotDetails}
  >
    <h4>This slot</h4>
    {!validSelection &&
    <ErrorMessage
      id="invalidSelection"
      className={errorStyling}
    >
      <FaExclamationTriangle size={20} />
      <strong>Bookings cannot overlap, <br/>please choose an alernative time slot</strong></ErrorMessage>
    }
    <dl className={orderSummary}>
      <dt>Date:</dt>
      <dd>{formatDate.format(date)}</dd>
      <dt>Start time:</dt>
      <dd>{formatTime.formatHours(selectedSlot.start)}</dd>
      <dt>Time period:</dt>
      <dd>
        {selectedSlot.end - selectedSlot.start} hours
        <FormSelect
          id="length"
          name="length"
          label="Change time period (hours)"
          value={`${selectedSlot.end - selectedSlot.start}`}
          onChange={onChange}

          options={
            timeSlots
              .filter(({length}) => selectedSlot.start + length <= endOfDay)
              .map(({length, cost}) => ({
                label: `${length}`,
                value: `${length}`
              }))
          }
        />
      </dd>
      <dt>End time:</dt>
      <dd>{formatTime.formatHours(selectedSlot.end)}</dd>
      <dt>Cost:</dt>
      <dd><strong>{selectedSlot.cost}</strong> credits</dd>
    </dl>

    <Button
      title="Add"
      colour="primaryAlternate"
      type="submit"
      onClick={addSlot}
      disabled={!validSelection}
      className={ctaPositioning}
    >+ Add</Button>
    <Button
      title="Cancel"
      type="reset"
      colour="primaryAlternate"
      inverted
      onClick={clearSelection}
    >Cancel</Button>
  </div>

}

// endregion

interface SelectedRange {
  start: number;
  end: number;
  cost: number;
}

interface Props {
  startDate?: Date
  numberOfDays?: number
  startTime?: number
  numberOfHours?: number
  bookedSlots?: BookedSlot[],
  timeSlots: SlotAvailabilityResource[],
  addBookingSlot?: (slot: BookedSlot) => Promise<void>,
  fromDate?: Date,
  toDate?: Date,
}

const NewHubAvailabilityField = ({
  startDate = new Date(),
  numberOfDays = 7,
  startTime = 7,
  numberOfHours = 14,
  bookedSlots = [],
  addBookingSlot,
  timeSlots,
  fromDate = undefined,
  toDate = undefined
}: Props) => {

  const theme = useTheme();

  const {
    grid,
    cell,
    cellAlt,
    cellHasBooking,
    cellOnTop,
    cellTime,
    cellDateHeading,
    cellBooking,
    borderRadiusAfter,
    cellAdditional,
    cellHighlight,
    cellSelectionError,
    cellClassTypes,
    slotDetails,
    orderSummary
  } = fieldCss(theme);


  const useMobileView = useMaxWidthMedia('desktop');

  const cellsPerHour = 2;

  const numberOfCells = numberOfHours * cellsPerHour;

  const cellToTimeMultiplier = 60 * (60 / cellsPerHour) * 1000;

  const minimumDateToShow = React.useMemo(() => dateWithoutTime(fromDate || startDate), [fromDate, startDate]);

  const maximumDateToShow = React.useMemo(() => dateWithoutTime(toDate || ARBITRARY_MAXIMUM_DATE), [toDate]);

  const days = React.useMemo(() => [...Array(numberOfDays)].map((_, dayNumber) => {
    const d = new Date();
    d.setTime(startDate.valueOf());
    d.setDate(startDate.getDate() + dayNumber);
    d.setHours(0, 0, 0, 0);
    return d;
  }).filter(date => date >= minimumDateToShow && date <= maximumDateToShow), [maximumDateToShow, minimumDateToShow, numberOfDays, startDate]);

  const times = React.useMemo(() => [...Array(numberOfCells + 1)].map((_, cellNumber) => {
    return startTime + cellNumber / cellsPerHour;
  }), [numberOfCells, startTime]);

  const endOfDay = React.useMemo(() => startTime + numberOfHours, [numberOfHours, startTime]);

  const [isDragging, setIsDragging] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
  const [initialCell, setInitialCell] = React.useState<number>(-1);
  const [selection, setSelection] = React.useState<SelectedRange | undefined>(undefined);

  const [selectedSlot, setSelectedSlot] = React.useState<BookedSlot | undefined>(undefined);

  const gridRef = React.useRef<HTMLDivElement>(null);
  const cellRef = React.useRef<HTMLElement>(null);

  const getCellElementInfo = () => {
    if (cellRef.current === null) return undefined;
    const el = cellRef.current;
    return {
      width: el.offsetWidth,
      height: el.offsetHeight,
      top: el.offsetTop,
      left: el.offsetLeft,
    };
  }

  const calculateQuantisedTime = React.useCallback((selectedStart: number, selectedEnd: number) => {
    const start = times[selectedStart];
    const endTime = times[selectedEnd];
    const length = (endTime - start);
    const endOfDay = (startTime + numberOfHours)

    const quantisedOptions = timeSlots.filter(({length: qLength}) => qLength <= length && (qLength + start) <= endOfDay).reverse();

    const quantisedLength = quantisedOptions.length > 0 ? quantisedOptions[0] : timeSlots[0];

    const end = start + quantisedLength.length;
    return {start, end, cost: quantisedLength.cost};
  }, [numberOfHours, startTime, timeSlots, times]);

  const calculateEventDateTime = React.useCallback((
    e: MouseEvent | React.MouseEvent | PointerEvent,
    allowOutside: boolean
  ) => {
    const gridEl = gridRef.current;
    const cellEl = getCellElementInfo();
    if (gridEl === null || cellEl === undefined) return undefined;

    const cellWidth = cellEl.width;
    const cellHeight = cellEl.height;

    const x = e.pageX - cellEl.left;
    const y = e.pageY - cellEl.top;

    const cx = Math.floor(x / cellWidth);
    let cy = Math.floor(y / cellHeight);

    const outside = cx < 0 || cx >= days.length || cy < 0 || cy > numberOfCells;

    if (outside && !allowOutside) return undefined;

    if (cy < 0) cy = 0;
    if (cy > numberOfCells) cy = numberOfCells;

    if (outside) return {cx, cy};

    const date = days[cx];
    const time = times[cy];

    const dateTime = new Date(date.valueOf());
    dateTime.setHours(time);

    return {date, time, dateTime, cx, cy};

  }, [days, numberOfCells, times]);

  const calculateSelectionFromEvent = React.useCallback((
    e: React.MouseEvent | MouseEvent | PointerEvent,
    allowOutside = true
  ) => {
    const cell = calculateEventDateTime(e, allowOutside);
    if (cell === undefined) return undefined;

    const {cx, cy, date} = cell;
    return {...cell, selection: calculateQuantisedTime(Math.min(cy, initialCell), Math.max(cy, initialCell))};
  }, [calculateEventDateTime, calculateQuantisedTime, initialCell]);

  const hasOverlappingBooking = React.useCallback(() => {

    if (selectedDate === undefined || selection === undefined) return false;

    const overlappingTimes =
      bookedSlots
        .filter(booking => dateCompare(selectedDate, booking.date) === 0
          // pad bookings so you can't book within 30 minutes of it starting or ending
          && (selection.start <= (booking.end + 0.5) && selection.end >= (booking.start - 0.5))
        );

    return overlappingTimes.length !== 0;


  }, [selectedDate, selection, bookedSlots]);

  const pointerDownEvent = React.useCallback((e: React.MouseEvent | PointerEvent) => {

    const el = e.target as Element;

    if (el.classList.contains(slotDetails) || el.closest(`.${slotDetails}`)) return;

    if (selectedSlot !== undefined) {
      clearSelection();
      //return;
    }

    const cell = calculateEventDateTime(e, false);
    if (cell === undefined) return;
    const {cy, date} = cell;

    e.preventDefault();

    setSelectedDate(date);
    setInitialCell(cy);
    setSelection(calculateQuantisedTime(cy, cy))
    setIsDragging(true);

  }, [calculateEventDateTime, calculateQuantisedTime, selectedSlot]);

  const pointerMoveEvent = React.useCallback((e: MouseEvent | PointerEvent) => {
    if (!isDragging) return;
    const newSelection = calculateSelectionFromEvent(e)?.selection;

    if (newSelection) setSelection(newSelection);
  }, [isDragging, calculateSelectionFromEvent]);


  const pointerUpEvent = React.useCallback((e: MouseEvent | PointerEvent) => {
    if (!isDragging) return;

    setIsDragging(false);

    const result = calculateSelectionFromEvent(e);

    if (result) {
      setSelection(result.selection);
      setSelectedSlot({
        date: selectedDate as Date,
        ...result.selection
      })
    }


  }, [isDragging, calculateSelectionFromEvent, selectedDate]);

  const clearSelection = () => {
    setSelectedSlot(undefined);
    setSelection(undefined);
    setSelectedDate(undefined);
    setIsDragging(false);
  }

  const addSlot = async () => {

    if (selectedSlot !== undefined) {
      await addBookingSlot?.(selectedSlot);
    }

    clearSelection();
  };

  const touchMove = React.useCallback((e: TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
  }, [isDragging])

  React.useEffect(() => {

    document.addEventListener("mouseup", pointerUpEvent);
    document.addEventListener("mousemove", pointerMoveEvent);
    document.addEventListener("pointermove", pointerMoveEvent);
    document.addEventListener("pointerup", pointerUpEvent);
    document.addEventListener("touchmove", touchMove, {passive: false});

    return () => {
      document.removeEventListener("touchmove", touchMove);
      document.removeEventListener("pointerup", pointerUpEvent);
      document.removeEventListener("pointermove", pointerMoveEvent);
      document.removeEventListener("mousemove", pointerMoveEvent);
      document.removeEventListener("mouseup", pointerUpEvent);
    }
  }, [pointerMoveEvent, pointerUpEvent, touchMove])

  const getDateOffset = (offset: number) => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    return date;
  };

  const getCurrentBookingForDateAndTime = React.useCallback((date: Date, time: number) => {

    return bookedSlots.find(booking => dateCompare(booking.date, date) === 0
      && (
        // pad unavailable slots so that they appear to have a 30 after them for cleaning
        time >= (booking.start - (booking.type === 'unavailable' ? 0 : 0))
        && time <= (booking.end + (booking.type === 'unavailable' ? 0.5 : 0))
      )
    );

  }, [bookedSlots]);

  const isCellSelected = React.useCallback((date: Date, time: number) => {
    return selectedDate
      && date.getDate() === selectedDate.getDate()
      && selection !== undefined
      && time >= selection.start && time <= selection.end
  }, [selectedDate, selection]);


  return <div
    className={grid(numberOfCells, days.length)}
    ref={gridRef}
    onMouseDown={e => pointerDownEvent(e)}
    onPointerDown={e => pointerDownEvent(e)}
  >
    <span />

    {days.map((date) => (
      <span key={date.toDateString()} className={cellDateHeading}>
        <strong>{formatWeek.format(date)}</strong>
        <span>{formatDay.format(date)}&nbsp;{formatMonthShort.format(date)}</span>
        <span>{formatYear.format(date)}</span>
      </span>
    ))}


    {times.map((time, cellId) => (
      <React.Fragment key={`${time}`}>

          <span className={cx(cellTime)}>
            {cellId % 2 === 0 ? formatTime.formatHours(time) : ""}
          </span>

        {days.map((date, rowId) =>
          ({date, rowId, currentBooking: getCurrentBookingForDateAndTime(date, time)})
        )
          .map(({date, rowId, currentBooking}) => (
            <span
              ref={rowId === 0 && cellId === 0 ? cellRef : undefined}
              className={
                cx(
                  cell,
                  (rowId % 2) === 0 ? cellAlt : "",
                  cx(
                    cellClassTypes[currentBooking?.type || "none"],
                    currentBooking?.start === time ? "first" : undefined
                  ),

                  isCellSelected(date, time) ?
                    hasOverlappingBooking() ? cellSelectionError : cellHighlight
                    : "",
                )
              }
              key={`${date}_${time}`}
            >
              {currentBooking?.type === 'unavailable' && currentBooking?.start === time ? "Unavailable" : ""}
              {selection !== undefined && selectedDate && date.getDate() === selectedDate.getDate() && (
                <>
                  {time === selection.start && (
                    <span className={cellOnTop}>{formatTime.formatHours(selection.start)}</span>
                  )}
                  {time === selection.end && (
                    <span className={cx(cellOnTop, css`bottom: 1em`)}>{selection.end - selection.start} hrs</span>
                  )}
                </>
              )}
              {selectedSlot !== undefined
              && (
                (!useMobileView && dateCompare(date, selectedSlot.date) === 0 && time === selectedSlot.start)
                ||
                (useMobileView && cellId === 0 && rowId === 0)
              ) && (
                <SelectedSlotPopup
                  onChange={(e) => {
                    const {length, cost} = timeSlots[e.target.selectedIndex];
                    const newSelection =
                      {
                        ...selectedSlot,
                        end: selectedSlot.start + length,
                        cost,
                      };
                    setSelectedSlot(newSelection);
                    setSelection(newSelection);
                  }}

                  addSlot={addSlot}
                  date={date}
                  selectedSlot={selectedSlot}
                  endOfDay={endOfDay}
                  clearSelection={clearSelection}

                  validSelection={!hasOverlappingBooking()}

                  timeSlots={timeSlots}

                />

              )}
          </span>
          ))}
      </React.Fragment>

    ))}


  </div>

};

export default NewHubAvailabilityField;
