/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, ElementType, ReactNode } from 'react';
import headingStyles from '@admin/styles/headings';
import { css } from '@emotion/css';
import { baseColours, spacings } from '@admin/styles/variables';
import { FaCaretDown, FaCaretUp, FaFileCsv } from 'react-icons/all';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import Button from './Button';

type FnString<T> = (row: T) => string;
type Fn<T> = (row: T) => string | number | ReactNode;

type RowMapper<T> = { [k in keyof T | string]: Fn<T> };

type HeaderFunction = ({ csvButton }: { csvButton: JSX.Element }) => ReactNode;

interface Props<T> {
  header?: HeaderFunction;
  caption?: string;
  dataKeys?: (keyof T | string)[];
  titles: { [k in keyof T | string]: string };
  mapper?: RowMapper<T>;
  rows?: T[];
  footer?: { [k in keyof T | string]: () => string | ReactNode };
  idKey: keyof T;
  paginate?: boolean;
  numberToShow?: number;
  allowColumnSorting?: boolean;
  allowCSVDownload?: boolean;
  types?: { [k in keyof T | string]: SortingAlgorithmType | FnString<T> };
}

const tableStyle = css`
  width: 100%;
  border-spacing: 0;
  margin-bottom: ${spacings.md};

  & th {
    border-bottom: 1px solid ${baseColours.greyLight};
    padding: ${spacings.sm};
    text-align: left;
  }

  & td {
    border-bottom: 1px solid ${baseColours.greyLight};
    padding: ${spacings.sm};
  }
`;

const tableOverflow = css`
  overflow: auto;
  wodth: 100%;
`;

type SortingAlgorithmType = 'object' | 'number' | 'date';
const SortingAlgorithms = {
  object: (direction: number, a: any, b: any) => {
    console.log(a.toString(), b.toString());
    const v = a.toString().localeCompare(b.toString()) * direction;
    return v;
  },
  number: (direction: number, a: any, b: any) => (a - b) * direction,
  date: (direction: number, a: any, b: any) => (a.getTime() - b.getTime()) * direction,
};

function downloadFile(blobPart: BlobPart) {
  const blob = new Blob([blobPart], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', 'test.csv');
  tempLink.click();
}

const escapeCsvField = (field: unknown, type: SortingAlgorithmType = 'object') => {
  if (type === 'number') return field as number;
  if (type === 'date') return (field as Date).toISOString();

  return `"${(field as string).replaceAll('"', '\\"')}"`;
};

const StyledTable = <T extends unknown>({
  header,
  caption,
  dataKeys,
  titles,
  rows,
  mapper,
  idKey,
  footer = undefined,
  paginate = false,
  allowColumnSorting = false,
  allowCSVDownload = false,
  numberToShow: initialNumberToShow = undefined,
  types = undefined,
}: Props<T>) => {
  const keys = dataKeys || Object.keys(titles);

  const [currentPage, setCurrentPage] = React.useState(0);

  const numberToShow = React.useMemo(
    () => (paginate ? initialNumberToShow ?? 5 : initialNumberToShow),
    [initialNumberToShow, paginate],
  );

  const [currentSortColumn, setCurrentSortColumn] = React.useState<keyof T | string>(keys[0]);
  const [currentSortDirection, setCurrentSortDirection] = React.useState(1);

  const getTypeOfRow = React.useCallback(
    (row: T, key: keyof T | string): SortingAlgorithmType => {
      if (types === undefined || types[key] === undefined) return 'object';

      if (typeof types[key] === 'function') return (types[key] as FnString<T>)(row) as SortingAlgorithmType;

      return types[key] as SortingAlgorithmType;
    },
    [types],
  );

  const getValueOfRow = React.useCallback(
    (row: T, key: keyof T | string) => {
      return mapper?.[key]?.(row) ?? row[key as keyof T];
    },
    [mapper],
  );

  const visibleRows = React.useMemo(() => {
    if (rows === undefined) return undefined;

    if (allowColumnSorting === false && paginate === false) return rows;

    let rowsCopy: T[] = [...rows];

    if (allowColumnSorting) {
      const type = types && types[currentSortColumn];

      if (typeof type === 'function') {
        const sortFunction = type as FnString<T>;
        rowsCopy.sort(
          (a: T, b: T) => sortFunction(a).toString().localeCompare(sortFunction(b).toString()) * currentSortDirection,
        );
      } else {
        const sortingAlgorithm =
          type === undefined ? SortingAlgorithms.object : SortingAlgorithms[type as SortingAlgorithmType];

        rowsCopy.sort((a: T, b: T) =>
          sortingAlgorithm(
            currentSortDirection,
            getValueOfRow(a, currentSortColumn),
            getValueOfRow(b, currentSortColumn),
          ),
        );
      }
    }

    if (numberToShow !== undefined) {
      rowsCopy = rowsCopy.slice(currentPage * numberToShow, Math.min(currentPage * numberToShow + numberToShow));
    }

    return rowsCopy;
  }, [
    allowColumnSorting,
    currentPage,
    currentSortColumn,
    currentSortDirection,
    getValueOfRow,
    numberToShow,
    paginate,
    rows,
    types,
  ]);

  const generateCsv = React.useCallback(() => {
    const csvData = [
      keys.map(key => titles[key as keyof T]).map(title => escapeCsvField(title)),
      ...(rows?.map(row => keys.map(key => escapeCsvField(getValueOfRow(row, key), getTypeOfRow(row, key)))) || []),
    ]
      .map(row => row.join(','))
      .join('\n');

    return csvData;
  }, [keys, rows, titles, getValueOfRow, getTypeOfRow]);

  const csvButton = React.useMemo(() => {
    return <FaFileCsv size="1.5rem" title="Download all results" onClick={e => downloadFile(generateCsv())} />;
  }, [generateCsv]);

  return (
    <div className={tableOverflow}>
      {header && header({ csvButton })}
      <table className={tableStyle}>
        <caption className={headingStyles.md}>{caption}</caption>
        <thead>
          <tr>
            {keys.map(key => (
              <th
                key={`${key}`}
                onClick={e => {
                  if (allowColumnSorting) {
                    if (currentSortColumn === key) {
                      setCurrentSortDirection(-currentSortDirection);
                    } else {
                      setCurrentSortColumn(key);
                      setCurrentSortDirection(1);
                    }
                  }
                }}
              >
                {`${titles[key as keyof T]}`}
                {allowColumnSorting && currentSortColumn === key ? (
                  currentSortDirection === 1 ? (
                    <FaCaretUp />
                  ) : (
                    <FaCaretDown />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {visibleRows?.map(row => (
            <tr key={`${row[idKey]}`}>
              {keys.map(key => (
                <td key={`${key}`}>{getValueOfRow(row, key)}</td>
              ))}
            </tr>
          ))}
        </tbody>

        {footer && (
          <tfoot>
            <tr>
              {keys.map(key => (
                <td key={`${key}`}>{mapper && mapper[key] && footer[key]()}</td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default StyledTable;
