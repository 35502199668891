"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = exports.formatTimeIntl = exports.formatWeekShort = exports.formatMonthShort = exports.formatDateInd = exports.formatYear = exports.formatDate = exports.formatDateShort = exports.formatWeek = exports.formatMonth = exports.formatDay = void 0;
var formatDay = new Intl.DateTimeFormat('en-GB', {
  day: 'numeric'
});
exports.formatDay = formatDay;
var formatMonth = new Intl.DateTimeFormat('en-GB', {
  month: 'long'
});
exports.formatMonth = formatMonth;
var formatWeek = new Intl.DateTimeFormat('en-GB', {
  weekday: 'short'
});
exports.formatWeek = formatWeek;
var formatDateShort = new Intl.DateTimeFormat('en-GB', {
  // @ts-ignore
  dateStyle: 'short'
});
exports.formatDateShort = formatDateShort;
var formatDate = new Intl.DateTimeFormat('en-GB', {
  // @ts-ignore
  dateStyle: 'medium'
});
exports.formatDate = formatDate;
var formatYear = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric'
});
exports.formatYear = formatYear;
var formatDateInd = new Intl.DateTimeFormat('en-GB', {
  // @ts-ignore
  dateStyle: 'long'
});
exports.formatDateInd = formatDateInd;
var formatMonthShort = new Intl.DateTimeFormat('en-GB', {
  month: 'short'
});
exports.formatMonthShort = formatMonthShort;
var formatWeekShort = new Intl.DateTimeFormat('en-GB', {
  weekday: 'narrow'
});
exports.formatWeekShort = formatWeekShort;
var formatTimeIntl = new Intl.DateTimeFormat('en-GB', {
  hour: "2-digit",
  minute: "2-digit"
});
exports.formatTimeIntl = formatTimeIntl;
var formatTime = {
  format: formatTimeIntl.format,
  formatHours: function formatHours(hours) {
    return formatTimeIntl.format(new Date(new Date().setHours(hours, hours * 60 % 60)));
  }
};
exports.formatTime = formatTime;