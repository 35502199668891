import accountServicePublic from '@admin/services/public/accountService.public';
import addressServicePublic from '@admin/services/public/addressService.public';
import bookedSlotServicePublic from '@admin/services/public/bookedSlotService.public';
import bookingServicePublic from '@admin/services/public/bookingService.public';
import clientServicePublic from '@admin/services/public/clientService.public';
import hubServicePublic from '@admin/services/public/hubService.public';
import siteServicePublic from '@admin/services/public/siteService.public';
import userService from '@admin/services/userService';
import { ServiceList } from '@admin/context/ServiceContext';
import mediaService from '@admin/services/mediaService';
import mediaReferenceService from '@admin/services/mediaReferenceService';
import slotAvailabilityServicePublic from '@admin/services/public/slotAvailabilityService.public';

export const publicServices: ServiceList = {
  accountService: accountServicePublic,
  addressService: addressServicePublic,
  bookedSlotService: bookedSlotServicePublic,
  bookingService: bookingServicePublic,
  clientService: clientServicePublic,
  hubService: hubServicePublic,
  siteService: siteServicePublic,
  userService,
  mediaService,
  mediaReferenceService,
  auditService: undefined,
  authorisationService: undefined,
  slotAvailabilityService: slotAvailabilityServicePublic
};
