import { breakpoints } from '@admin/styles/variables';
import { useEffect, useState } from 'react';

type Media = keyof typeof breakpoints;

export default function useMedia(query: string): boolean {
  const [state, setState] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addListener(onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return state;
}

export function useMaxWidthMedia(media: Media | string) {
  return useMedia(`(max-width: ${breakpoints[media as Media] ?? media})`);
}

export function useMinWidthMedia(media: Media | string) {
  return useMedia(`(min-width: ${breakpoints[media as Media] ?? media})`);
}
