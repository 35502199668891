import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}
const cardStyle = css`
  background-color: ${baseColours.white};
  padding: ${spacings.sm};
  //margin-right: ${spacings.sm};
  margin-bottom: ${spacings.md};
  border-radius: 10px;
  box-shadow: 0 3px 8px ${baseColours.greyLight};

  p,
  li {
    max-width: 800px;
  }
`;

const Card = ({ className, children }: Props) => <div className={cx(cardStyle, className)}>{children}</div>;

export default Card;
