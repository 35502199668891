import { css } from '@emotion/css';
import { CSSProperties } from 'react';
import { spacings } from './variables';

const createSpacings = (style: keyof CSSProperties) => ({
  xl: css({
    /* stylelint-disable property-no-unknown */
    [style]: spacings.xl,
  }),
  lg: css({
    [style]: spacings.lg,
  }),
  md: css({
    [style]: spacings.md,
  }),
  sm: css({
    [style]: spacings.sm,
  }),
  xs: css({
    [style]: spacings.xs,
  }),
  none: css({
    [style]: 0,
  }),
});

const spacingStyles = {
  padding: createSpacings('padding'),
  paddingBottom: createSpacings('paddingBottom'),
  paddingLeft: createSpacings('paddingLeft'),
  paddingRight: createSpacings('paddingRight'),
  paddingTop: createSpacings('paddingTop'),
  margin: createSpacings('margin'),
  marginBottom: createSpacings('marginBottom'),
  marginLeft: createSpacings('marginLeft'),
  marginRight: createSpacings('marginRight'),
  marginTop: createSpacings('marginTop'),
};

export default spacingStyles;
