import React, {MouseEventHandler} from "react";
import {formatDay, formatMonthShort, formatWeek, formatYear} from "@common/formats";
import {css, cx} from "@emotion/css";
import {baseColours} from "@admin/styles/variables";
import {useTheme} from "@emotion/react";


export const dateIsSelected = css`
  // color: black;
  outline: 1px solid grey;
`;
export const dateHasSlots = css`
  background-color: darkcyan;
  color: white;
`;

export const FormatDateSpan = ({date, onClick, className, selected, containsSlots}: {
  date: Date,
  onClick?: MouseEventHandler<HTMLElement>
  className?: string
  selected?: boolean,
  containsSlots?: boolean
}) => {

  const theme = useTheme();

  const dateSpan = css`
    display: inline-block;
    padding: 0.25em;
    font-size: 66%;
    color: grey;
    // border: 2px solid grey;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      background-color: ${theme.background2};
      color: black;
    }
  `;

  const cn = cx(
    dateSpan,
    selected && dateIsSelected,
    containsSlots && dateHasSlots
  );

  return <span onClick={onClick} className={cn}>
    <strong>{formatWeek.format(date)}</strong><br />{formatDay.format(date)}&nbsp;{formatMonthShort.format(date)}<br />{formatYear.format(date)}
  </span>;
};
