import Hub from '@admin/pages/admin/hubs/components/Hub';
import Page from '@admin/components/layout/Page';
import React from 'react';
import { useParams } from 'react-router-dom';

interface RouteParams {
  hubId: string;
}

const HubPage = () => {
  const params = useParams<RouteParams>();

  const { hubId } = params;

  return <Page title="Hub Details">{hubId !== null && <Hub hubId={hubId} />}</Page>;
};

export default HubPage;
