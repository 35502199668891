import { breakpoints, spacings } from '@admin/styles/variables';
import { css, cx } from '@emotion/css';
import React, { ComponentType, ReactNode } from 'react';

const columnStyle = css`
  flex-grow: 1;
  margin-top: ${spacings.md};
  max-width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
  }
`;

const columnSize = 100 / 12;

type Props<P> = {
  as?: ComponentType<{ className: string; children: ReactNode }> | keyof JSX.IntrinsicElements;
  children: ReactNode;
  className?: string;
  grow?: boolean;
  size?: number;
  //  tabletSize?: number;
} & P;

const Column = <P extends {}>({ as: Component = 'div', children, className, grow = false, size }: Props<P>) => (
  <Component
    className={cx(
      columnStyle,
      size
        ? css`
            @media (min-width: ${breakpoints.desktop}) {
              flex-basis: ${grow ? 0 : columnSize * size}%;
              flex-grow: ${grow ? size : 1};
              max-width: ${grow ? 100 : columnSize * size}%;
            }
          `
        : undefined,
      className,
    )}
  >
    {children}
  </Component>
);

export default Column;
