import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { SiteResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import StyledTable from '@admin/components/StyledTable';
import { Link } from 'react-router-dom';
import React from 'react';
import { useServiceContext } from '@admin/context/ServiceContext';
import ButtonLink from "@admin/components/ButtonLink";

const SiteList = () => {
  const { siteService } = useServiceContext();

  const { value: sites, isLoading } = useAsyncRetry<SiteResource[]>(async () => {
    const result = await siteService.find({
      query: {
        $eager: '[hubs,address]',
      },
    });

    return result as SiteResource[];
  });

  return (
    <LoadingSpinner loading={isLoading}>
      {sites !== undefined && (
        <StyledTable<SiteResource>
        titles={
          {
            "name": "Name",
            "city": "City",
            "postcode": "Postcode",
            "hubs": "Hubs",
            "status": "Status",
            "actions": "Actions",
          }
        }

        mapper={
          {
            hubs: (site) => (
              <span>{site.hubs?.length}</span>
            ),
            city: (site) => (
              site.address?.city
            ),
            postcode: (site) => (
              site.address?.postcode
            ),
            status: (site) => (site.active ? 'Active' : 'Disabled'),
            name: (site) => (<Link to={{ pathname: `/admin/sites/${site.id}` }}>
              {site.name}
            </Link>),
            actions: (site) =>
              <ButtonLink colour="primary" to={{ pathname: `/admin/sites/${site.id}/` }}>
                View site
              </ButtonLink>
          }
        }

          rows={sites}
          idKey="id"
        />
      )}
    </LoadingSpinner>
  );
};

export default SiteList;
