import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '@admin/routes';
import { injectGlobal } from '@emotion/css';
import globalTypographyStyles from '@admin/styles/typography';
import globalResetStyles from '@admin/styles/reset';
import ThemeContextWrapper from '@admin/context/ThemeContext';
import { Auth0ContextProvider, Auth0ContextProviderProps } from '@admin/context/Auth0Context';
import { authorizeApiClient } from '@admin/services/apiClient';
import { UserContextProvider } from '@admin/context/UserContext';
import AccountCreationRequired from '@admin/components/AccountCreationRequired';
import { ServiceContextProvider } from '@admin/context/ServiceContext';
import ScrollToTop from './components/scrollToTop';

// eslint-disable-next-line no-unused-expressions
injectGlobal`

  ${globalResetStyles}

  ;
  ${globalTypographyStyles}

  ;

  body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
  }

  html {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

function App() {
  const handleAuthenticated: Auth0ContextProviderProps['onAuthenticated'] = React.useCallback((_, token) => {
    authorizeApiClient(token);
  }, []);

  const options = React.useMemo(
    () => ({
      redirectUri: `${window.location.origin}/home`,
    }),
    [],
  );

  return (
    <ThemeContextWrapper theme="default">
      <BrowserRouter>
        <ScrollToTop />
        <Auth0ContextProvider options={options} onAuthenticated={handleAuthenticated}>
          <UserContextProvider>
            <ServiceContextProvider>
              <AccountCreationRequired>
                <Routes />
              </AccountCreationRequired>
            </ServiceContextProvider>
          </UserContextProvider>
        </Auth0ContextProvider>
      </BrowserRouter>
    </ThemeContextWrapper>
  );
}

export default App;
