import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { inputStyle } from '@admin/components/form/FormInput';
import FormLabel from '@admin/components/form/FormLabel';
import headingStyles from '@admin/styles/headings';
import { fonts } from '@admin/styles/variables';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';

const selectStyle = css`
  ${inputStyle};
  appearance: none;

  option {
    font-family: ${fonts.body};
  }

  &:not([value='']) {
    background-position: right 0.7em top 50%;
    background-repeat: no-repeat;
    background-size: 0.65em auto;
  }
`;

const selectedStyle = css`
  &:not([value='']) {
    background-position: right 0.7em top 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 0.65em auto, 100%;
  }
`;

export interface SelectOption {
  label: string;
  value: string;
}

export interface FormSelectProps {
  className?: string;
  id: string;
  label: string;
  labelSize?: keyof typeof headingStyles;
  labelClassName?: string;
  name: string;
  options: SelectOption[];
  showLabel?: boolean;
  placeholder?: string;
  value: string;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
}

const FormSelect = ({
  className,
  id,
  label,
  labelSize,
  labelClassName = '',
  name,
  options = [],
  showLabel,
  placeholder,
  value,
  onBlur,
  onChange,
}: FormSelectProps) => {
  const theme = useTheme();
  const themedSelectStyle = css`
    ${selectStyle}
    option {
      color: ${theme.text};
    }

    &:not([value='']) {
      background: ${theme.background1};
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${theme.primary}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
      border: 1px solid ${theme.border};
      color: ${theme.text2};
    }

    &:focus {
      border: 1px solid ${theme.primary};
    }
  `;

  const themedSelectedStyle = css`
    ${selectedStyle}
    &:not([value='']) {
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${theme.primary}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(100deg, ${theme.secondaryHighlight} 0%, ${theme.secondary} 100%);
      background-position: right 0.7em top 50%, 0 0;
      background-repeat: no-repeat, repeat;
      background-size: 0.65em auto, 100%;
      border: 1px solid ${theme.secondary};
      color: ${theme.primary};
    }

    &:focus {
      border: 1px solid ${theme.primary};
    }
  `;

  return (
    <>
      <FormLabel id={id} size={labelSize} show={showLabel} className={labelClassName}>
        {label}
      </FormLabel>

      <select
        className={cx(themedSelectStyle, value && themedSelectedStyle, className)}
        id={id}
        name={name}
        placeholder={placeholder}
        defaultValue={onChange === undefined ? value : undefined}
        value={onChange !== undefined ? value : undefined}
        onBlur={onBlur}
        onChange={onChange}
      >
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default FormSelect;
