import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { SiteResource } from '@common/types/apiResource';
import React from 'react';
import FormSelect from '@admin/components/form/FormSelect';
import { useFormikContext } from 'formik';
import FormField from '@admin/components/form/field/FormField';
import { useServiceContext } from '@admin/context/ServiceContext';

export const SiteSelect = () => {
  const form = useFormikContext<{ siteId: string }>();

  const { siteService } = useServiceContext();

  const { value: sites, isLoading } = useAsyncRetry<SiteResource[]>(async () => {
    const result = await siteService.find();

    return result as SiteResource[];
  });

  return (
    <>
      {sites && (
        <FormField
          as={FormSelect}
          id="siteId"
          name="siteId"
          options={sites.map(site => ({
            label: `${site.name} ${site.address?.lines || ''}`,
            value: site.id,
          }))}
          label="Select a location"
          placeholder="Select site"
        />
      )}
    </>
  );
};
