import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { UserResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import StyledTable from '@admin/components/StyledTable';
import React from 'react';
import { useServiceContext } from '@admin/context/ServiceContext';
import { DeleteModalButton } from '@admin/components/DeleteModalButton';
import ErrorMessage from '@admin/components/ErrorMessage';
import ModalButton from '@admin/components/ModalButton';
import { useUserContext } from '@admin/context/UserContext';
import FormSelect from '@admin/components/form/FormSelect';
import FormInput from '@admin/components/form/FormInput';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';

interface Props {
  onDelete: (userId: string) => Promise<void>;
  onChangeUserType?: (user: { id: string; type: UserResource['type'] }) => Promise<void>;
  users?: UserResource[];
  isLoading: boolean;
}

const UsersList = ({ onDelete, onChangeUserType, users, isLoading }: Props) => {
  const user = useUserContext();
  const theme = useTheme();

  return (
    <LoadingSpinner loading={isLoading} center>
      {users !== undefined && (
        <StyledTable<UserResource>
          caption="List of all Users"
          titles={{
            id: 'User Id',
            emailAddress: 'Email',
            auth0Id: 'Auth0 Id',
            accounts: 'Status',
            emails: 'Receive Emails',
            type: 'Type',
            delete: '',
          }}
          mapper={{
            accounts: row => {
              return <>{row.accounts && row.accounts.length > 0 ? 'Active' : 'Invited'}</>;
            },
            emails: row => {
              return <>{row.receiveEmails ? 'Yes' : 'No'}</>;
            },
            delete: row => {
              return (
                <DeleteModalButton
                  onConfirm={() => onDelete(row.id)}
                  onCancel={() => {}}
                  buttonText="Delete User"
                  bodyText={
                    <>
                      <p>
                        Are you sure you want to delete the user, they will be unable to use the system unless they are
                        re-invited
                      </p>
                      <p>Their accounts and all bookings with them will also be removed</p>
                    </>
                  }
                />
              );
            },
            type: row => {
              if (row.id === user.user?.id)
                return (
                  <FormInput
                    disabled
                    id={`type_${row.id}`}
                    label="type"
                    name={`type_${row.id}`}
                    value={row.type === 'admin' ? 'Admin' : 'User'}
                    className={css`
                      color: ${theme.text};
                      background-color: ${theme.disabledButtonBackground};
                    `}
                    showLabel={false}
                  />
                );

              return (
                <FormSelect
                  id={`type_${row.id}`}
                  label="type"
                  name={`type_${row.id}`}
                  value={row.type}
                  options={[
                    { value: 'user', label: 'User' },
                    { value: 'admin', label: 'Admin' },
                  ]}
                  onChange={e => {
                    onChangeUserType?.({
                      ...row,
                      type: e.target.value as UserResource['type'],
                    });
                  }}
                  showLabel={false}
                />
              );
            },
          }}
          rows={users}
          idKey="id"
        />
      )}
    </LoadingSpinner>
  );
};

export default UsersList;
