import { css } from '@emotion/css';
import { useUserContext } from '@admin/context/UserContext';
import { NavLink } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { linkStyle, navSectionStyle } from '@admin/components/layout/Header';
import { useMinWidthMedia } from '@admin/hooks/useMedia';

const navStyle = css`
  //margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const Navigation = ({ children }: { children: ReactNode }) => {
  const user = useUserContext();

  const isDesktop = useMinWidthMedia('small');

  return (
    <nav className={navStyle}>
      <ul className={navSectionStyle}>
        <li>
          <NavLink className={linkStyle} to="/public/find-a-hub">
            Find a Hub
          </NavLink>
        </li>
        <li>
          <NavLink className={linkStyle} to="/public/bookings">
            My Bookings
          </NavLink>
        </li>
        <li>
          <NavLink className={linkStyle} to="/public/help">
            FAQs
          </NavLink>
        </li>
      </ul>

      {user.user?.type === 'admin' && isDesktop && (
        <ul className={navSectionStyle}>
          <li>
            <NavLink className={linkStyle} to="/admin">
              Stats
            </NavLink>
          </li>
          <li>
            <NavLink className={linkStyle} to="/admin/sites">
              Sites
            </NavLink>
          </li>
          <li>
            <NavLink className={linkStyle} to="/admin/hubs">
              Hubs
            </NavLink>
          </li>
          <li>
            <NavLink className={linkStyle} to="/admin/accounts">
              Accounts
            </NavLink>
          </li>
          <li>
            <NavLink className={linkStyle} to="/admin/users">
              Users
            </NavLink>
          </li>
          <li>
            <NavLink className={linkStyle} to="/admin/settings">
              Settings
            </NavLink>
          </li>
        </ul>
      )}

      {children}
    </nav>
  );
};
