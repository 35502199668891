import Page from '@admin/components/layout/Page';
import Site from '@admin/pages/admin/sites/components/Site';
import useQuery from '@admin/hooks/useQuery';
import React from 'react';
import Card from '@admin/components/Card';
import Row from '@admin/components/layout/Row';
import Hero from '@admin/components/Hero';
import Column from '@admin/components/layout/Column';
import { useHistory, useParams } from 'react-router';
import { BookedSlotResource } from '@common/types/apiResource';
import bookedSlotServicePublic from '@admin/services/public/bookedSlotService.public';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import Button from '@admin/components/Button';
import Container from '@admin/components/layout/Container';
import Map from '@admin/components/Map';
import { Marker, Popup } from 'react-leaflet';
import { css, cx } from '@emotion/css';
import { widthStyles } from '@admin/styles/utils';
import spacingStyles from '@admin/styles/spacings';
import { DeleteModalButton } from '@admin/components/DeleteModalButton';
import bookingService from '@admin/services/bookingService';
import ErrorMessage from '@admin/components/ErrorMessage';
import { baseColours, breakpoints, spacings } from '@admin/styles/variables';
import flexStyles from '@admin/styles/flex';
import Modal from '@admin/components/Modal';
import { Dialog, useDialog, useDialogState } from 'reakit';
import FormInput from '@admin/components/form/FormInput';
import { FaUnlock, FaLock, FaWifi, FaLaptop, FaRegClock } from 'react-icons/fa';
import FacilitiesList from '@admin/components/facilitiesList';
import { formatDate, formatTime } from '@common/formats';
import { calculateLengthInHours, parseDateString } from '@common/utils/dateFunctions';

export const heroContent = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const heroDetails = css`
  color: ${baseColours.white};
  flex-basis: 65%;
  flex-grow: 1;

  h2 {
    color: ${baseColours.white};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    text-shadow: 2px 2px ${baseColours.greyDark};
    line-height: 1.2;
    @media (max-width: ${breakpoints.small}) {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
`;

export const heroBookings = css`
  background: ${baseColours.white};
  color: ${baseColours.black};
  flex-basis: 35%;
  flex-grow: 1;
  padding: ${spacings.sm};
  border-radius: 5px;
  min-width: 270px;

  p{
    margin-bottom: 1rem;
  }

  h3{
    font-size: ${spacings.md};
  }

  h3{
    @media (max-width: ${breakpoints.small}) {
      font-size: ${spacings.sm};
      margin-bottom: ${spacings.sm};
    })
  }

  dl{
    display: flex;
    background: ${baseColours.greyWhite};
    border: 1px solid ${baseColours.grey};
    padding: 0.6rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
  }

  dt{
    margin-right: 0.6rem;
  }

  dd{
    font-weight: bold;
  }

`;

export const bookingContainer = css`
  margin-top: ${spacings.md};
`;

export const flex50 = css`
  flex: 1 1 50%;
  min-width: 200px;
`;

export const bookingCard = css`
  padding: 1rem;

  h4 {
    margin-top: 2rem;
    color: ${baseColours.teal};
    display: flex;
    align-items: baseline;
    svg {
      margin-right: 0.5rem;
    }
    span {
      display: block;
    }
  }

  ul {
    margin-bottom: 3rem;
    width: 100%;
  }

  li {
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0.6rem;

    h3 {
      font-size: ${spacings.sm};
    }
    h4 {
      font-size: ${spacings.sm};
    }
  }
`;

interface RouteParams {
  id: string;
}

const ViewBookingPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const params = useParams<RouteParams>();

  const {
    value: slot,
    isLoading,
    error,
    setError,
  } = useAsyncRetry<BookedSlotResource>(async () => {
    const result = await bookedSlotServicePublic.get(id, {
      query: {
        $eager: '[hub, hub.site, hub.site.address]',
      },
    });

    console.log("start", result.start)
    console.log("now", new (Date))

    return result as BookedSlotResource;
  });

  const deleteSlot = async (reason: string) => {
    try {
      // @ts-ignore
      const result = await bookedSlotServicePublic.remove(id, {
        query: {
          reason,
        },
      });
    } catch (e) {
      setError(e);
    }
  };

  const [reasonForDeleting, setReasonForDeleting] = React.useState('');

  return (
    <Page title="Your Booking" fullWidth>
      {slot !== undefined && (
        <>
          <Hero>
            <div className={heroContent}>
              <div className={heroDetails}>
                <h2>Your booking</h2>
              </div>

              <div className={heroBookings}>
                <h3>{`${slot.hub?.site?.name} - ${slot.hub?.name}`}</h3>
                <p>
                  <b>{formatDate.format(slot.start)}</b>  - {slot.status}
                  <br />
                  <b>{formatTime.format(slot.start)}-{formatTime.format(slot.end)} {calculateLengthInHours(slot.start, slot.end)} hrs</b>
                </p>

                <dl>
                  <dt>Booking Reference: </dt>
                  <dd>{slot.id}</dd>
                </dl>

                {slot.hub?.doorPin && (
                  <dl>
                    <dt>Door Pin: </dt>
                    <dd>{slot.hub?.doorPin}</dd>
                  </dl>
                )}


                {error && <ErrorMessage id="error">An error occurred : {error.message}</ErrorMessage>}
                {slot.start > new (Date) &&
                  <>
                    <DeleteModalButton
                      buttonText="Cancel this Booking"
                      bodyText="Are you sure you want to cancel this booking?"
                      headingText="Cancel this Booking"
                      confirmText="Cancel Booking"
                      cancelText="Back"
                      onCancel={() => { }}
                      onConfirm={async () => {
                        await deleteSlot(reasonForDeleting);
                        history.push('/public/bookings');
                      }}
                    />
                  </>
                }
              </div>
            </div>
          </Hero>

          <Container className={bookingContainer}>
            <Card>
              <div className={cx(flexStyles.flex, flexStyles.wrap, bookingCard)}>
                <div className={flex50}>
                  <h3>How to find your Hub</h3>
                  <h4>Located</h4>
                  <address>
                    <dl>
                      <dt>{slot?.hub?.site?.address?.lines}</dt>
                      <dt>{slot?.hub?.site?.address?.city}</dt>
                      <dt>{slot?.hub?.site?.address?.postcode}</dt>
                      <dt>{slot?.hub?.site?.address?.county}</dt>
                      <dt>{slot?.hub?.site?.address?.country}</dt>
                    </dl>
                  </address>
                  <p>{slot?.hub?.overview}</p>
                  <p>{slot?.hub?.description}</p>

                  <h4>Onsite facilities</h4>
                  <FacilitiesList />
                </div>
                <div className={flex50}>
                  {slot?.hub?.latitude && slot.hub.longitude && (
                    <>
                      <Map center={[slot.hub.latitude, slot.hub.longitude]} zoomLevel={18}>
                        <Marker position={[slot.hub.latitude, slot.hub.longitude]}>
                          <Popup>
                            <p>
                              <b>{slot.hub.name}</b>
                            </p>
                          </Popup>
                        </Marker>
                      </Map>
                    </>
                  )}
                </div>
              </div>
            </Card>

            <Card>
              <div className={bookingCard}>
                <h3>Once you Arrive</h3>
                <h4>
                  <FaUnlock />
                  <span>Unlocking the door</span>
                </h4>
                <ul>
                  <li>
                    Once you have located your hub, at the start of your session please enter your unique code that you received in your booking confirmation email to unlock the hub door.
                  </li>
                  <li>
                    This code can be used throughout the duration of your session – so you are free to come and go as you please.
                  </li>
                </ul>

                <h4>
                  <FaWifi />
                  <span>Connecting to Wi-Fi</span>
                </h4>
                <ul>
                  <li>
                    In your booking confirmation email you will receive a unique Wi-Fi password for your session.
                  </li>
                </ul>

                <h4>
                  <FaLaptop />
                  <span>During your session</span>
                </h4>
                <ul>
                  <li>Relax, plug your laptop in and find your flow. </li>
                  <li>Have your important team meetings in a safe, secure, and soundproof pod.</li>
                  <li>Remember to take regular breaks (concentration dips after 45 minutes).</li>
                  <li>
                    You can use your pin code for the door to leave and come back throughout the duration of your session. Please do not leave the door open at any point during your session.
                  </li>
                  <li>
                    If you do leave the door locks automatically, so please ensure you know or have your pin code in
                    order to gain access to your pod again.
                  </li>
                  <li>
                    The toilets are located within the Interchange at the Pond st/ Pond Hill entrance.
                  </li>
                  <li>
                    You can find a list of facilities nearby, including local coffee shops and convenience stores if you need a snack break on the Hub page.
                  </li>
                  <li>Have fun and be productive!</li>
                </ul>
              </div>
            </Card>

            <Card>
              <div className={bookingCard}>
                <h3>When you have finished</h3>
                <h4>
                  <FaRegClock />
                  <span>At the end of your session</span>
                </h4>
                <ul>
                  <li>
                    Once your session is coming to an end, we would like to ask you to vacate the pod on time and stay no later than the end of your allocated time slot.
                  </li>
                  <li>We would like you to place all rubbish in the bin provided. </li>
                  <li>Please ensure you have taken all your belongings with you.</li>
                  <li>
                    Remember to shut the door properly when you leave the hub.
                  </li>
                  <li>We hope you enjoyed your session and look forward to you booking your next one!  </li>
                </ul>
              </div>
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
};

export default ViewBookingPage;
