import { UserResource } from '@common/types/apiResource';
import { useAuth0Context } from '@admin/context/Auth0Context';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import userService from '@admin/services/userService';
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

export interface UserContextState {
  isLoading: boolean;
  error?: Error;
  user?: UserResource;
  setUser: (user: UserResource) => void;
  reload: () => Promise<void>;
}

export const UserContext = createContext<UserContextState | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: Props) => {
  const { isAuthenticated, user } = useAuth0Context();

  const loadUser = React.useCallback(async () => {
    if (!isAuthenticated) {
      return undefined;
    }

    if (user?.sub) {
      const data = (await userService.find({
        query: {
          $limit: 1,
          auth0Id: user.sub,
          $eager: '[accounts]',
        },
      })) as UserResource[];

      if (data.length === 1) {
        return data[0];
      }
    }

    throw new Error('Current user does not exist.');
  }, [isAuthenticated, user]);

  const { value, isLoading, error, setValue } = useAsyncRetry<UserResource | undefined>(loadUser, [user]);

  const reloadUser = React.useCallback(async () => {
    setValue(await loadUser());
  }, [loadUser, setValue]);

  const state: UserContextState = useMemo(
    () => ({
      isLoading,
      error,
      user: value,
      setUser: setValue,
      reload: reloadUser,
    }),
    [error, isLoading, reloadUser, setValue, value],
  );

  return <UserContext.Provider value={state}>{!isLoading ? children : null}</UserContext.Provider>;
};

export function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('Need to have a UserContextProvider as a parent component');
  }

  return context;
}
