import Column from '@admin/components/layout/Column';
import Container from '@admin/components/layout/Container';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import {css, cx} from '@emotion/css';
import React from 'react';
import {useParams} from 'react-router-dom';
import {HubAvailability} from '@admin/pages/bookings/components/HubAvailability';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import {useServiceContext} from '@admin/context/ServiceContext';
import {BookingResource, HubResource} from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import ErrorMessage from '@admin/components/ErrorMessage';
import {margin} from 'polished';
import spacingStyles from '@admin/styles/spacings';
import {breakpoints} from '@admin/styles/variables';
import {HubAvailabilityField} from '@admin/pages/bookings/components/HubAvailabilityField';
import BookingForm from '@admin/pages/bookings/components/BookingForm';
import Link from '@admin/components/Link';
import Hero from '@admin/components/Hero';
import Card from '@admin/components/Card';
import TimeOfDay from '@admin/components/timeOfDay';
import {FaRegHandPointer} from "react-icons/all";
import flexStyles from "@admin/styles/flex";
import {useTheme} from "@emotion/react";

interface Props {
}

const gridClass = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, minmax(70px, 1fr));
  grid-gap: 0.6rem;
  margin: 1rem 0 2rem;
  max-height: 60vh;

  @media (max-device-width: ${breakpoints.small}) and (orientation: portrait) {
    max-height: 30vh;
  }
`;

const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;

  :first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
`;

const PublicHubPage = ({}: Props) => {
  const {id} = useParams<{ id: string }>();

  const theme = useTheme();

  const {hubService, bookingService} = useServiceContext(true);

  const {
    value: hub,
    isLoading,
    error,
    retry,
  } = useAsyncRetry<HubResource | undefined>(async () => {
    if (id === undefined) return undefined;

    return hubService.get(id, {
      query: {
        $eager: '[site, bookedSlots, images]',
      },
    });
  }, [id, hubService]);

  const doCreateBooking = React.useCallback(
    async (bookingResource: Partial<BookingResource>) => {
      const result = await bookingService.create(bookingResource, {
        query: {$eager: '[bookedSlots]'},
      });

      retry();
    },
    [retry, bookingService],
  );

  return (
    <Page title={hub?.name} fullWidth>
      <Hero title={hub?.name} />

      <Container>
        <Link to={`/public/find-a-hub/${hub?.site?.id}`}>
          {`<< `}Back to {hub?.site?.name}
        </Link>

        <LoadingSpinner loading={isLoading} center>
          {error && <ErrorMessage id="error">Unable to load hub : ${error?.message}</ErrorMessage>}
          {hub && (
            <>
              <div className={gridClass}>
                {hub.images?.map(i => (
                  <img key={i.id} className={image} src={i.url} alt={i.title} />
                ))}
              </div>
              <Card>
                <div className={spacingStyles.marginBottom.md}>
                  <p>{hub.description}</p>
                </div>

                <div>
                  <h3>Book&nbsp;slot</h3>
                </div>

                <BookingForm hub={hub} onCreate={doCreateBooking} />
              </Card>
            </>
          )}
        </LoadingSpinner>
      </Container>
    </Page>
  );
};

export default PublicHubPage;
