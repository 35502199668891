import { useHistory, useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { BookingResource } from '@common/types/apiResource';
import React from 'react';
import Page from '@admin/components/layout/Page';
import Card from '@admin/components/Card';
import ViewBooking from '@admin/pages/bookings/components/ViewBooking';
import { useServiceContext } from '@admin/context/ServiceContext';
import ErrorMessage from '@admin/components/ErrorMessage';
import flexStyles from '@admin/styles/flex';
import spacingStyles from '@admin/styles/spacings';
import { DeleteModalButton } from '@admin/components/DeleteModalButton';
import { Form, Formik } from 'formik';
import InlineFormField from '@admin/components/form/field/InlineFormField';
import FormField from '@admin/components/form/field/FormField';
import FormInput from '@admin/components/form/FormInput';
import { css, cx } from '@emotion/css';

interface DeleteBookingFields {
  reason: string;
}

const DeleteBookingForm = ({ onChange }: { onChange?: (fields: DeleteBookingFields) => void }) => {
  const [values, setValues] = React.useState<DeleteBookingFields>({
    reason: '',
  });

  return (
    <form>
      <div
        className={cx(
          flexStyles.flex,
          css`
            align-items: baseline;
          `,
        )}
      >
        <FormInput
          id="reason"
          type="text"
          name="reason"
          label="Reason for deleting booking?"
          value={values.reason}
          onChange={e => {
            const newValues = { ...values, reason: e.target.value };
            setValues(newValues);
            onChange?.(newValues);
          }}
        />
      </div>
    </form>
  );
};

const MyBookingPage = () => {
  const history = useHistory();

  const { bookingId } = useParams<{ bookingId: string }>();

  const { bookingService } = useServiceContext();

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const [deleteBookingFormValues, setDeleteBookingFormValues] = React.useState<DeleteBookingFields>({ reason: '' });

  const {
    value: booking,
    isLoading,
    error,
    setLoading,
    setError,
  } = useAsyncRetry<BookingResource | undefined>(async () => {
    if (bookingId === undefined) return undefined;

    const result = await bookingService.get(bookingId, {
      query: {
        $eager: '[bookedSlots.hub.[site],account,hubs]',
      },
    });

    if (result === undefined) throw new Error('no booking found');
    if (result.hubs === undefined) throw new Error('Booking has no hubs');
    // if (result.bookedSlots === undefined || result.bookedSlots.length === 0) throw new Error("Booking has no slots");

    return result as BookingResource;
  }, [bookingId]);

  const deleteBooking = async (fields: DeleteBookingFields) => {
    try {
      const result = await bookingService.remove(bookingId, {
        query: {
          $eager: '[bookedSlots]',
          $cascade: true,

          reason: fields.reason,
        },
      });
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Page title="Booking">
      <Card>
        {error ? (
          <ErrorMessage id="error">An error occurred : {error.message}</ErrorMessage>
        ) : (
          <>
            {booking && (
              <>
                <>
                  <div className={spacingStyles.marginBottom.md}>
                    <ViewBooking booking={booking} />
                  </div>
                  <div className={flexStyles.flexCenterAll}>
                    <DeleteModalButton
                      buttonText="Delete booking"
                      bodyText={<DeleteBookingForm onChange={setDeleteBookingFormValues} />}
                      onCancel={() => {}}
                      onConfirm={async () => {
                        await deleteBooking(deleteBookingFormValues);
                        history.push('/public/bookings');
                      }}
                    />
                  </div>
                </>
              </>
            )}
          </>
        )}
      </Card>
    </Page>
  );
};

export default MyBookingPage;
