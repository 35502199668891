import { useServiceContext } from '@admin/context/ServiceContext';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { HubResource } from '@common/types/apiResource';
import LoadingSpinner from '@admin/components/LoadingSpinner';
import React from 'react';
import { HubAvailabilityFieldProps } from '@admin/pages/bookings/components/HubAvailabilityField';
import { Slot } from '@common/utils/dateFunctions';
import { AvailabilityCellProps } from '@admin/pages/bookings/components/AvailabilityCell';
import { HubAvailability } from '@admin/pages/bookings/components/HubAvailability';

export interface HubAvailabilityAsyncProps extends HubAvailabilityFieldProps {
  hubId: string;
  numberOfWeeks?: number;
  timesOfDay?: string[];
  fromDate?: Date;
  initialSelection?: Slot[];
  cellProps?: Partial<AvailabilityCellProps> | ((day: Date, timeOfDay: string) => Partial<AvailabilityCellProps>);
}

/**
 * This version of the HubAvailability component will take a hubId and load the data from the API
 * @param hubId
 * @param props
 * @constructor
 */
export const HubAvailabilityAsync = ({ hubId, ...props }: HubAvailabilityAsyncProps) => {
  const { hubService } = useServiceContext();

  const { value: hub, isLoading } = useAsyncRetry<HubResource>(async () => {
    const result = await hubService.get(hubId, {
      query: {
        $eager: '[bookedSlots]',
      },
    });

    return result as HubResource;
  }, [hubId]);

  return (
    <>
      <LoadingSpinner loading={isLoading} size="md" center>
        {hub && <HubAvailability hub={hub} {...props} />}
      </LoadingSpinner>
    </>
  );
};
