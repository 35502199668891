import ButtonLink from '@admin/components/ButtonLink';
import Card from '@admin/components/Card';
import FormGroup from '@admin/components/form/FormGroup';
import Column from '@admin/components/layout/Column';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import SiteList from '@admin/pages/admin/sites/components/siteList';
import React from 'react';

const SitesPage = () => (
  <Page title="Site List">
    {/* <Row>
      <Column size={12}>
        <FormGroup>
          <ButtonLink to={{ pathname: '/admin/sites/create' }}>Create new Site</ButtonLink>
        </FormGroup>
      </Column>
  </Row> */}
    <Row>
      <Column size={12}>
        <Card>
          <SiteList />
        </Card>
      </Column>
    </Row>
  </Page>
);

export default SitesPage;
