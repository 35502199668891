import React from 'react';
import { useTheme } from '@emotion/react';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import { BookingResource } from '@common/types/apiResource';
import { HubAvailabilityAsync } from '@admin/pages/bookings/components/HubAvailabilityAsync';

interface Props {
  booking: BookingResource;
}

const ViewBooking = ({ booking }: Props) => {
  const theme = useTheme();

  return (
    <>
      <h3>Site</h3>
      {booking.bookedSlots?.[0]?.hub?.site?.name}

      <hr />

      <h3>Hub</h3>
      {booking.bookedSlots?.[0]?.hub?.name}

      <hr />

      <h3>Booked times</h3>
      {booking.bookedSlots?.[0]?.hub?.id && (
        <HubAvailabilityAsync hubId={booking.bookedSlots?.[0]?.hub?.id} currentBookingId={booking.id} />
      )}
    </>
  );
};

export default ViewBooking;
