import useAsyncRetry from "@admin/hooks/useAsyncRetry";
import { HubResource } from "@common/types/apiResource";
import headingStyles from "@admin/styles/headings";
import Row from "@admin/components/layout/Row";
import Column from "@admin/components/layout/Column";
import { Link } from 'react-router-dom';
import React from 'react';
import { useServiceContext } from "@admin/context/ServiceContext";
import { HubAvailabilityAsync } from "@admin/pages/bookings/components/HubAvailabilityAsync";
import { css, cx } from "@emotion/css";
import { formatMonth, formatMonthShort, formatWeekShort } from "@admin/styles/formats";
import Card from "../../../../components/Card";
import HubUpcomingBookingsTable from "./hubUpcomingBookingsTable";
import spacingStyles from "@admin/styles/spacings";
import { breakpoints } from "@admin/styles/variables";

interface Props {
  hubId: string;
}


const gridClass = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, minmax(70px, 1fr));
  grid-gap: 0.6rem;
  margin: 1rem 0 2rem;
  max-height: 60vh;

  @media (max-device-width: ${breakpoints.small}) and (orientation : portrait){
    max-height: 30vh;
  }
`;

const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;

  :first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
`;

const Hub = ({ hubId }: Props) => {

  const { hubService } = useServiceContext();

  const { value: hub, isLoading } = useAsyncRetry<HubResource>(async () => {
    const result = await hubService.get(hubId, {
      query: {
        $eager: "[site, images]"
      }
    });

    return result as HubResource;
  })


  return <>
    {!isLoading && hub !== undefined && <>

      <Row>

        <Column size={4} className={cx(spacingStyles.marginRight.md)}>

          <Card>
            <h2 className={headingStyles.sm}>{hub.name}</h2>
            <p>{hub.overview}</p>

            <dl>
              <dt>Hub ID</dt>
              <dd>ID-{hub?.id}</dd>

              <dt>Site</dt>
              <dd><Link to={{ pathname: `/admin/sites/${hub.siteId}/` }}>{hub?.site?.name}</Link></dd>

              <dt>Status</dt>
              <dd>{hub.active ? 'Active' : 'Disabled'}</dd>
            </dl>
          </Card>
          <Card>
            <h3 className={headingStyles.xs}>Details</h3>
            <dl>
              <dt>Wifi Network</dt>
              <dd>{hub?.wifiNetwork}</dd>

              <dt>Wifi Password</dt>
              <dd>{hub?.wifiPassword}</dd>
            </dl>
          </Card>
          <Card>
            <h3 className={headingStyles.xs}>Access Control</h3>
            <dl>
              <dt>Lock Id</dt>
              <dd>{hub?.lockId ? hub.lockId : "No lock assigned"}</dd>

              <dt>{hub?.lockId ? "Fallback Door Code" : "Door Code"}</dt>
              <dd>{hub?.doorPin}</dd>
            </dl>
          </Card>
          <Card>
            <h3 className={headingStyles.xs}>Media</h3>
            <div className={gridClass}>
              {hub.images?.map(i => <img
                key={i.id}
                className={image}
                src={i.url}
                alt={i.title}
              />
              )}
            </div>
          </Card>
        </Column>
        <Column>
          <Card>
            <h3 className={headingStyles.xs}>Bookings</h3>
            <HubUpcomingBookingsTable hubId={hubId} />
          </Card>


        </Column>
      </Row>






    </>}
  </>;

};

export default Hub;
