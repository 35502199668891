import Page from '@admin/components/layout/Page';
import React from 'react';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import Card from '@admin/components/Card';
import UsersList from './components/usersList';
import CreateUser from './createUser';
import { useServiceContext } from '@admin/context/ServiceContext';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { UserResource } from '@common/types/apiResource';
import UserForm from '@admin/pages/admin/users/components/userForm';

const UsersPage = () => {
  const { userService } = useServiceContext();

  const {
    value: users,
    isLoading,
    retry,
    error,
    setError,
  } = useAsyncRetry<UserResource[]>(async () => {
    const result = await userService.find({
      query: { $sort: { emailAddress: 1 }, $eager: '[accounts]' },
    });
    return result as UserResource[];
  });

  const createUser = React.useCallback(
    async (userData: { emailAddress: string }) => {
      try {
        const result = await userService.create(userData);

        retry();
      } catch (e) {
        setError(e);
      }
    },
    [retry, setError, userService],
  );

  const deleteUser = React.useCallback(
    async (userId: string) => {
      try {
        await userService.remove(userId);

        retry();
      } catch (e) {
        setError(e);
      }
    },
    [retry, userService, setError],
  );

  const changeUserType = React.useCallback(
    async ({ id, type }: { id: string; type: UserResource['type'] }) => {
      try {
        await userService.patch(id, { type });

        retry();
      } catch (e) {
        setError(e);
      }
    },
    [retry, setError, userService],
  );

  return (
    <Page title="Users">
      <Row>
        <Column size={12}>
          <Card>
            <UsersList users={users} isLoading={isLoading} onDelete={deleteUser} onChangeUserType={changeUserType} />
          </Card>

          <Card>
            <Row>
              <Column size={12}>
                <UserForm onSubmit={createUser} />
              </Column>
            </Row>
          </Card>
        </Column>
      </Row>
    </Page>
  );
};

export default UsersPage;
