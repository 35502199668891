import { useAuth0Context } from '@admin/context/Auth0Context';
import React from 'react';

import { Redirect } from 'react-router';

const LandingPage = () => {
  const { login, isAuthenticated } = useAuth0Context();

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }
  login({
    appState: { targetUrl: `/home` },
  });

  return <></>;
};

export default LandingPage;
