"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IsHubAvailableAtTimes = exports.GetHubAvailablility = void 0;

var _dateFunctions = require("./dateFunctions");

var GetHubAvailablility = function GetHubAvailablility(currentBookingId, hub, day, timeOfDay, now) {
  var _hub$bookedSlots;

  if (hub === undefined) return 'unavailable';
  if (day < now) return 'unavailable';
  var dayWeek = day.getDay(); // const availableAtTime = hub.availability?.find(a => a.timeOfDay === timeOfDay && +a.dayOfWeek === dayWeek);
  // if (availableAtTime === undefined) return 'unavailable';

  var hasBooking = (_hub$bookedSlots = hub.bookedSlots) === null || _hub$bookedSlots === void 0 ? void 0 : _hub$bookedSlots.find(function (booked) {
    return (0, _dateFunctions.datesAreEqual)(booked.start, day);
  });

  if (hasBooking) {
    if (currentBookingId && hasBooking.bookingId === currentBookingId) return 'currentBooking';
    return 'booked';
  }

  return 'available';
};

exports.GetHubAvailablility = GetHubAvailablility;

var IsHubAvailableAtTimes = function IsHubAvailableAtTimes(now, hub, slots) {
  return slots.every(function (_ref) {
    var start = _ref.start;
    return GetHubAvailablility(undefined, hub, start, undefined, now) === 'available';
  });
};

exports.IsHubAvailableAtTimes = IsHubAvailableAtTimes;