import React from "react";
import {css, cx} from "@emotion/css";
import {formatDate, formatDateShort, formatTime} from "@common/formats";
import {FaTrashAlt} from "react-icons/fa";
import flexStyles from "@admin/styles/flex";
import ErrorMessage from "@admin/components/ErrorMessage";
import spacingStyles from "@admin/styles/spacings";
import ModalButton from "@admin/components/ModalButton";
import Button from "@admin/components/Button";
import {
  BookedSlot,
  bookingList,
  buttonContainer,
  buttonPrimary,
  buttonSecondary
} from "@admin/pages/bookings/components/BookingForm";

const bookingTable = css`
  width: 100%;
  boder-collapse: collapse;

  th{
    text-align: left;
  }

  .cellBorderTop{
    td{
      border-top: 4px solid black;
    }
  }
`;

export const SelectedSlotsTable = ({
  selectedSlots,
  removeSlot,
  removeAllSlots,
  creditsRemaining,
  onSubmit
}: {
  selectedSlots: BookedSlot[]
  removeSlot: (slot: number) => void,
  removeAllSlots: () => void,
  creditsRemaining: number,
  onSubmit: () => Promise<void>
}) => {

  const creditsAfterPurchase = React.useMemo(() =>
      selectedSlots.reduce((total, slot) => total - slot.cost, creditsRemaining || 0)
    , [creditsRemaining, selectedSlots]);

  return <>
    <h3>Your booking</h3>
    {
      selectedSlots.length === 0 ?
        <span>No slots have been added</span>
        :
        <div>
          <table className={bookingTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Length (hours)</th>
                <th>Cost (credits)</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedSlots.map((slot, idx) => (
                <tr key={`${slot.date.toString()  }_${  slot.start}`}>
                  <td>{formatDateShort.format(slot.date)}</td>
                  <td>
                    {formatTime.formatHours(slot.start)}
                    -
                    {formatTime.formatHours(slot.end)}
                  </td>
                  <td>

                    {slot.end - slot.start}
                  </td>
                  <td>
                    {slot.cost}
                  </td>
                  <td>
                    <FaTrashAlt
                      onClick={() => removeSlot(idx)}
                    />
                  </td>
                </tr>
              ))}
              <tr className="cellBorderTop">
                <td colSpan={3} className={css`text-align: right;`}>Total</td>
                <td>{selectedSlots.reduce((total, slot) => total + slot.cost, 0)}</td>
                <td />
              </tr>
              <tr>
                <td colSpan={3} className={css`text-align: right;`}>Your credits</td>
                <td><strong>{creditsRemaining}</strong></td>
                <td />
              </tr>
              <tr>
                <td colSpan={3} className={css`text-align: right;`}>After purchase</td>
                <td>{creditsAfterPurchase}</td>
                <td />
              </tr>
            </tbody>
          </table>

          <div className={cx(flexStyles.flex, flexStyles.flexCenterAll, flexStyles.column)}>
            {creditsAfterPurchase < 0 &&
            <ErrorMessage id="notEditCredits" className={spacingStyles.marginTop.sm}>
              You do not have enough credits to book these times.
              <br />
              Please update the booking to continue
            </ErrorMessage>
            }

            <div className={spacingStyles.marginTop.sm}>
              <ModalButton
                button={
                  <Button className={buttonPrimary} disabled={creditsAfterPurchase < 0}>
                    Book at these times
                  </Button>
                }

                aria-label="Book at these times"
              >
                {({hide}) => {
                  return (
                    <>
                      <h3>Booking details</h3>
                      <p>Please confirm your booking</p>
                      <ul className={bookingList}>
                        {selectedSlots.map(slot => (
                          <React.Fragment key={slot.start}>
                            <li>
                              {formatDate.format(slot.date)}{' '}
                              {formatTime.formatHours(slot.start)}{' '} to {formatTime.formatHours(slot.start)}{' '}
                            </li>
                          </React.Fragment>
                        ))}
                      </ul>
                      <div className={buttonContainer}>
                        <Button className={buttonSecondary} type="button" inverted onClick={hide}>
                          Cancel and go back
                        </Button>

                        <Button
                          type="submit"
                          colour="primary"
                          onClick={onSubmit}
                        >
                          Confirm Booking
                        </Button>
                      </div>
                    </>
                  );
                }}
              </ModalButton>

              <Button
                type="reset"
                colour="primary"
                inverted
                style={{margin: '0 0 0 1rem'}}
                onClick={removeAllSlots}
              >
                Clear
              </Button>

            </div>
          </div>

        </div>
    }
  </>

}
