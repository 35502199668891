import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';
import ErrorMessage from '@admin/components/ErrorMessage';
import { inputGradientStyle } from '@admin/styles/gradients';
import { smallBoxShadow } from '@admin/styles/shadows';
import { fonts, spacings } from '@admin/styles/variables';
import React, { ReactNode, useCallback } from 'react';
import { Checkbox, VisuallyHidden } from 'reakit';
import { MdCheck } from 'react-icons/all';

const fieldStyle = css`
  ${smallBoxShadow()};
  align-items: center;
  border-radius: 10px;
  display: inline-flex;
  flex-grow: 0;

  margin-bottom: ${spacings.md};
  padding: 0.2rem;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-family: ${fonts.heading};
    line-height: 1.3;
    padding-right: 0.2rem;
    width: 100%;
  }
`;

export interface FormCheckboxProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string;
  id: string;
  label: string | ReactNode;
  showLabel?: boolean;
  name: string;
  onChange?: (checked: boolean, value: string, id: string) => void;
  value: string;
}

const FormCheckbox = ({
  checked,
  className,
  disabled,
  error,
  id,
  label,
  name,
  onChange,
  value,
  showLabel = true,
}: FormCheckboxProps) => {
  const theme = useTheme();
  const themedFieldStyle = css`
    ${fieldStyle};
    background: ${theme.background};
    border: 1px solid ${theme.border};

    label {
      color: ${theme.text2};
    }
  `;

  const checkedStyle = css`
    ${inputGradientStyle(theme)};
    border-color: ${theme.border};

    label {
      color: ${theme.inputColor};
    }
  `;

  const errorStyle = css`
    border: 1px solid ${theme.error};
    margin-bottom: ${spacings.sm};
  `;

  const handleChange = useCallback(
    nextChecked => {
      if (onChange) {
        onChange(nextChecked, value, id);
      }
    },
    [id, onChange, value],
  );

  return (
    <>
      <span
        className={cx(themedFieldStyle, className, checked && checkedStyle, error && errorStyle)}
        onClick={() => handleChange(!checked)}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {showLabel && <label htmlFor={id}>{label}</label>}

        {checked ? (
          <MdCheck size="2rem" />
        ) : (
          <span
            className={css`
              width: 2rem;
              height: 2rem;
            `}
          />
        )}

        <VisuallyHidden>
          <Checkbox checked={checked} disabled={disabled} id={id} name={name} value={value} onChange={handleChange} />
        </VisuallyHidden>
      </span>

      {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </>
  );
};

export default FormCheckbox;
