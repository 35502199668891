import Page from '@admin/components/layout/Page';
import React from 'react';
import Hero from '@admin/components/Hero';
import Container from '@admin/components/layout/Container';
import MyUpcomingSlots from './components/myUpcomingSlots';
import MyBookings from './components/myBookings';
import Card from '@admin/components/Card';
import MyPastBookings from './components/myPastBookings';
import { css } from '@emotion/css';

const MyBookingsPage = () => (
  <Page title="My Bookings" fullWidth>
    <Hero title="My Bookings" subtitle="Your upcoming and past bookings" />
    <Container className={css(`padding: 1rem;`)}>
      <MyUpcomingSlots />
      <MyPastBookings />
    </Container>
  </Page>
);

export default MyBookingsPage;
