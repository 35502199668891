import { UploadUrlResource } from '@common/types/apiResource';
import apiClient from '@admin/services/apiClient';

export interface S3Service<T extends UploadUrlResource> {
  create(data: { type: 'upload'; fileType: string; name: string }): Promise<T>;
  remove(id: string): Promise<void>;
}

const s3ServiceFactory = <T extends UploadUrlResource>(path: string): S3Service<T> => {
  return apiClient.service(path);
};

export default s3ServiceFactory;
