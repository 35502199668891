import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import env from '@admin/env';
import Axios from 'axios';
import { parseJson } from '@admin/services/parsers';

const axios = Axios.create();

const apiClient = feathers();

const restClient = rest(env.apiBaseUrl);

apiClient.configure(restClient.axios(axios));

export default apiClient;

axios.interceptors.response.use(async response => {
  const parsed = parseJson(response.data);

  response.data = parsed;

  return response;
});

export const authorizeApiClient = (accessToken: string) => {
  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  });
  /* eslint-enable no-param-reassign */
};
