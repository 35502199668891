import { css } from '@emotion/css';

const flexStyles = {
  alignItemsStart: css`
    align-items: flex-start;
  `,
  alignItemsCenter: css`
    align-items: center;
  `,
  alignItemsEnd: css`
    align-items: flex-end;
  `,
  column: css`
    flex-direction: column;
  `,
  flex: css`
    display: flex;
  `,
  flexCenterAll: css`
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  flexSpaceBetweenAll: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  justifyStart: css`
    justify-content: flex-start;
  `,
  justifyCenter: css`
    justify-content: center;
  `,
  justifyEnd: css`
    justify-content: flex-end;
  `,
  justifySpaceBetween: css`
    justify-content: space-between;
  `,
  row: css`
    flex-direction: row;
  `,
  wrap: css`
    flex-wrap: wrap;
  `,
};

export default flexStyles;
