import React, { ReactNode } from 'react';
import { AccountResource, BookingResource } from '@common/types/apiResource';
import { css } from '@emotion/css';
import StyledTable from '@admin/components/StyledTable';
import { DateTime } from '@admin/components/DateTime';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import { breakpoints } from '@admin/styles/variables';

export const tableContainer = css`
  @media (max-width: ${breakpoints.small}) {
    width: 80vw;
    overflow: auto;

    table caption {
      width: 80vw;
      font-size: 1.2rem;
    }
  }
`;

interface Props {
  account: AccountResource;
  bookings: BookingResource[];

  pre?: (booking: BookingResource) => ReactNode;
  post?: (booking: BookingResource) => ReactNode;
}

const BookingList = ({ account, bookings, pre = undefined, post = undefined }: Props) => (
  <>
    <div className={tableContainer}>
      <StyledTable<BookingResource>
        caption={`List of Bookings for ${account.title} ${account.firstName} ${account.surname} (#${account.id})`}
        titles={{
          _post: '',
          _pre: '',
          id: 'Booking id',
          slots: 'Number of slots',
          createdAt: 'Date created',
          updatedAt: 'Last updated',
        }}
        mapper={{
          _post: post || (() => <></>),
          _pre: pre || (() => ''),
          slots: booking => `${booking?.bookedSlots?.length}`,
          createdAt: booking => <DateTime>{booking?.createdAt}</DateTime>,
          updatedAt: booking => <DateTime>{booking?.updatedAt}</DateTime>,
        }}
        rows={bookings}
        idKey="id"
      />
    </div>
  </>
);

export default BookingList;
