import Button from '@admin/components/Button';
import ButtonLink from '@admin/components/ButtonLink';
import Card from '@admin/components/Card';
import FormField from '@admin/components/form/field/FormField';
import FormGroup from '@admin/components/form/FormGroup';
import FormInput from '@admin/components/form/FormInput';
import FormMap from '@admin/components/form/FormMap';
import FormTextArea from '@admin/components/form/FormTextArea';
import Column from '@admin/components/layout/Column';
import Page from '@admin/components/layout/Page';
import Row from '@admin/components/layout/Row';
import addressService from '@admin/services/addressService';
import siteService from '@admin/services/siteService';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface FormFields {
  sitename: string;
  description: string;
  addresslines: string;
  postcode: string;
  city: string;
  county: string;
  country: string;
  location: string;
}

const FormSchema = Yup.object().shape({
  sitename: Yup.string().required('Name is required'),
  description: Yup.string(),
  addresslines: Yup.string().required('Address Lines are required'),
  postcode: Yup.string().required('Postcode is required'),
  city: Yup.string().required('City is required'),
  county: Yup.string().required('County is required'),
  country: Yup.string(),
});

const CreateSitePage = () => {
  const handleSubmit = async (values: FormFields) => {
    const address = await addressService.create({
      lines: values.addresslines,
      postcode: values.postcode,
      city: values.city,
      county: values.county,
      country: values.country,
    });

    const site = await siteService.create({
      name: values.sitename,
      addressId: address.id,
      description: values.description,
    });

    // TODO: Redirect
  };

  return (
    <Page title="Add New Site">
      <Formik<FormFields>
        onSubmit={handleSubmit}
        initialValues={
          {
            sitename: '',
            addresslines: '',
            postcode: '',
            city: '',
            county: '',
            country: '',
            location: '',
            description: '',
          } as FormFields
        }
        validateOnMount
        enableReinitialize
        validationSchema={FormSchema}
      >
        {form => (
          <Form>
            <Row>
              <Column size={12}>
                <FormGroup>
                  <ButtonLink to={{ pathname: '/admin/sites/' }} inverted>
                    Discard
                  </ButtonLink>
                  <Button type="submit" disabled={!form.isValid} colour="success">
                    Add
                  </Button>
                </FormGroup>
              </Column>
            </Row>
            <Row>
              <Column size={8}>
                <Card>
                  <h3>Basic Info</h3>
                  <FormGroup>
                    <FormField as={FormInput} name="sitename" id="sitename" label="Site Name" />
                  </FormGroup>
                  <FormGroup>
                    <FormField as={FormTextArea} name="description" id="description" label="Description" />
                  </FormGroup>
                </Card>
                <Card>
                  <h3>Media</h3>
                  <p>Drag/Drop image upload</p>
                </Card>
                <Card>
                  <h3>Facilities</h3>
                  <p>Icons/dropdown to add facilities?</p>
                </Card>
              </Column>
              <Column size={4}>
                <Card>
                  <h3>Location</h3>
                  <FormGroup>
                    <FormField as={FormInput} name="addresslines" id="addresslines" label="Address Lines" />
                    <FormField as={FormInput} name="postcode" id="postcode" label="Postcode" />
                    <FormField as={FormInput} name="city" id="city" label="City" />
                    <FormField as={FormInput} name="county" id="county" label="County" />
                    <FormField as={FormInput} name="country" id="country" label="Country" />
                  </FormGroup>

                  <FormGroup>
                    <FormField as={FormMap} name="location" id="location" label="Location" />
                  </FormGroup>
                </Card>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateSitePage;
