import Page from '@admin/components/layout/Page';
import Card from '@admin/components/Card';
import Button from '@admin/components/Button';
import { css, cx } from '@emotion/css';
import { widthStyles } from '@admin/styles/utils';
import Row from '@admin/components/layout/Row';
import Column from '@admin/components/layout/Column';
import spacingStyles from '@admin/styles/spacings';
import { Link, useParams } from 'react-router-dom';
import useAsyncRetry from '@admin/hooks/useAsyncRetry';
import { SiteResource } from '@common/types/apiResource';
import React from 'react';
import Map from '@admin/components/Map';
import HubsList from './components.tsx/hubsList';
import { Marker, Popup } from 'react-leaflet';
import { breakpoints, fontWeights, spacings } from '@admin/styles/variables';
import { useServiceContext } from '@admin/context/ServiceContext';
import { BsWifi } from 'react-icons/bs';
import { FaToilet, FaShoppingBasket, FaMugHot, FaPrint } from 'react-icons/fa';
import ButtonLink from '@admin/components/ButtonLink';
import Hero from '@admin/components/Hero';
import Container from '@admin/components/layout/Container';
import FacilitiesList from '@admin/components/facilitiesList';
import LoadingSpinner from '@admin/components/LoadingSpinner';

interface RouteParams {
  id: string;
}

const FindAHubDetailsPage = () => {
  const params = useParams<RouteParams>();

  const { siteService } = useServiceContext();

  const { value: site, isLoading } = useAsyncRetry<SiteResource>(async () => {
    const result = await siteService.get(params.id, {
      query: {
        $eager: '[hubs.images, address]',
      },
    });

    return result as SiteResource;
  });

  const gridClass = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, minmax(70px, 1fr));
    grid-gap: 0.6rem;
    margin: 1rem 0 2rem;
    max-height: 60vh;

    @media (max-device-width: ${breakpoints.small}) and (orientation: portrait) {
      max-height: 30vh;
  `;

  const image = css`
    width: 100%;
    height: 100%;
    object-fit: cover;

    :first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
  `;

  const detailsHeader = css`
    @media (max-width: ${breakpoints.small}) {
      font-size: ${spacings.sm};
    })
  `;

  const detailsExtra = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${spacings.md};

    @media (max-width: ${breakpoints.small}) {
      grid-template-columns: 1fr;
    })

  `;

  const facilitiesList = css`
    padding: 0;
    margin: 1rem 0;

    li {
      display: flex;
      margin-bottom: 2rem;
      align-items: center;
    }

    svg {
      flex: 0 0 60px;
    }

    p {
      flex: 0 1 100%;
    }
  `;

  return (
    <Page title={site?.name || ``} fullWidth>
      <Hero title={site?.name || ``} subtitle={`${site?.address?.lines} ${site?.address?.postcode}`} />
      <Container>
        <div className={gridClass}>
          <img className={image} src={site && `/media/sites/${site?.id}/image_0.jpg`} alt={site?.name} />
          <img className={image} src={site && `/media/sites/${site?.id}/image_1.jpg`} alt={site?.name} />
          <img className={image} src={site && `/media/sites/${site?.id}/image_2.jpg`} alt={site?.name} />
        </div>

        <Card>
          <h3 className={detailsHeader}>Details</h3>
          <p>{site?.description}</p>
        </Card>

        <HubsList siteId={params.id} hubList={site?.hubs} />

        <div className={detailsExtra}>
          <Card>
            <h3 className={detailsHeader}>Facilities</h3>
            <h4 className={cx(spacingStyles.marginTop.sm)}>
              In the Hub</h4>
            <p>x2 3.0 USB, x2 3-Pin Chargers, WIFI, LED lamp, sanitation station, additional foldable office chair, soundproofed office – people outside of the hub will not be able to hear your conversations.</p>
            <h4>Onsite</h4>
            <FacilitiesList />

            <ButtonLink
              to={`/public/find-a-hub/${site?.id}/facilities`}
              className={cx(widthStyles.full, spacingStyles.marginTop.lg)}
            >
              View all facilities
            </ButtonLink>
          </Card>

          {site?.address?.latitude && site?.address?.longitude && (
            <Card>
              <h3>Location</h3>

              <Map center={[site.address.latitude, site.address.longitude]} zoomLevel={15}>
                <Marker position={[site.address.latitude, site.address.longitude]}>
                  <Popup>
                    <p>
                      <b>{site.name}</b>
                    </p>
                    <p>
                      {site.address?.lines} {site.address?.postcode}
                    </p>
                  </Popup>
                </Marker>
              </Map>

              <ButtonLink
                to={`https://www.google.com/maps/dir/Current+Location/${site.address.latitude},${site.address.longitude}`}
                target="_blank"
                className={cx(widthStyles.full, spacingStyles.marginTop.lg)}
              >
                Get Directions
              </ButtonLink>
            </Card>
          )}
        </div>
      </Container>
    </Page>
  );
};

export default FindAHubDetailsPage;
