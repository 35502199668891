import React, { ReactNode } from 'react';
import { css, cx } from '@emotion/css';
import Column from '@admin/components/layout/Column';
import headings from '@admin/styles/headings';
import { breakpoints, spacings } from '@admin/styles/variables';
import UserHeader from './UserHeader';
import Row from './Row';

const containerStyle = css`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1rem;

  @media (max-width: ${breakpoints.small}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  })
`;

interface Props {
  children: ReactNode;
  className?: string;
}

const Container = ({ children, className }: Props) => <div className={cx(containerStyle, className)}>{children}</div>;

export default Container;
